var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from 'react';
import { LazyLoadComponent, Styler, TypographyBody } from '@honeycomb/ui-core';
import { useGlobalContext } from '@honeycomb/data';
import getConfig from 'next/config';
import { pushVisitData } from '@honeycomb/tracking';
import { router } from '@honeycomb/routing';
import { Header } from './Header/Header';
import { PageHead } from '../features/head/PageHead';
import { Notification } from '../notification/Notification';
import { PlaceholderFooter } from './PlaceholderFooter';
import { useLocalStorage } from '../utils';
import { useMountEffect } from '../utils/useMountEffect';
import { getQueryString } from '../utils/getQueryString';
const isDevOrTestEnv = ['test', 'development'].some((el) => el === process.env.NODE_ENV);
const config = getConfig().publicRuntimeConfig;
const serverInfo = () => config.mockingEnabled === 'true' ? "I'm being mocked" : `Connected to: ${config.graphqlUrl}`;
function ServerMessage() {
    return (React.createElement(Styler, { bg: "system.success.base", c: "common.white", pos: "absolute", bottom: 0, left: 0, px: 2, py: 0.5 },
        React.createElement(TypographyBody, { size: "xs" }, serverInfo())));
}
export function Layout(props) {
    var _a, _b, _c, _d;
    const { brandLogo, children, masthead, footer, main } = props, pageProps = __rest(props, ["brandLogo", "children", "masthead", "footer", "main"]);
    const { resourceStrings: { DEFAULT_META_TITLE, DEFAULT_META_DESCRIPTION }, } = useGlobalContext();
    const [userId, setUserId] = useLocalStorage('userId');
    useMountEffect(() => {
        var _a;
        const params = new URLSearchParams(getQueryString(router.query()));
        const userIdParam = (_a = params
            .get('userid')) === null || _a === void 0 ? void 0 : _a.replace('{', '').replace('}', '').replace('%7B', '').replace('%7D', '');
        if (userIdParam) {
            setUserId(userIdParam);
            pushVisitData(String(userIdParam));
        }
        else if (userId)
            pushVisitData(String(userId));
    });
    return (React.createElement(Styler, { pos: "relative" },
        React.createElement(PageHead, { pageTitle: ((_a = pageProps.getPage) === null || _a === void 0 ? void 0 : _a.pageTitle) || DEFAULT_META_TITLE, pageDescription: ((_b = pageProps.getPage) === null || _b === void 0 ? void 0 : _b.pageDescription) || DEFAULT_META_DESCRIPTION, canonical: (_c = pageProps.getPage) === null || _c === void 0 ? void 0 : _c.canonical, linkTags: (_d = pageProps.getPage) === null || _d === void 0 ? void 0 : _d.linkTags }),
        React.createElement(Header, { brandLogo: brandLogo, masthead: masthead }),
        typeof main === 'function' ? main(children) : React.createElement("main", null, children),
        footer ? (React.createElement(LazyLoadComponent, { intersectionProps: { triggerOnce: true }, child: footer })) : (React.createElement(PlaceholderFooter, null)),
        isDevOrTestEnv && React.createElement(ServerMessage, null),
        React.createElement(Notification, null)));
}
