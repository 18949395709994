import { ImageOrigin } from '@honeycomb/data';
export const imageSizes = {
    portraitCard2: {
        width: 420,
        height: 260,
    },
    largeCard: {
        width: 768,
        height: 240,
    },
    hotelGallery: {
        width: 768,
        height: 480,
    },
    galleryThumbnail: {
        width: 120,
        height: 67,
    },
    mapInfoWindow: {
        width: 520,
        height: 220,
    },
    priceBlock: {
        width: 265,
        height: 125,
    },
    merchandiseCard: {
        width: 460,
        height: 400,
    },
    fullWidthMerchCard: {
        xs: {
            width: 360,
            height: 420,
        },
        s: {
            width: 540,
            height: 420,
        },
        m: {
            width: 768,
            height: 420,
        },
        l: {
            width: 1024,
            height: 420,
        },
        xl: {
            width: 1240,
            height: 420,
        },
    },
    fullWidthHero: {
        xs: {
            width: 540,
            height: 470,
        },
        s: {
            width: 768,
            height: 470,
        },
        m: {
            width: 1024,
            height: 470,
        },
        l: {
            width: 1240,
            height: 470,
        },
        xl: {
            width: 1920,
            height: 470,
        },
    },
    containedHero: {
        xs: {
            width: 540,
            height: 260,
        },
        s: {
            width: 768,
            height: 260,
        },
        m: {
            width: 1024,
            height: 320,
        },
        l: {
            width: 1240,
            height: 380,
        },
        xl: {
            width: 1240,
            height: 380,
        },
    },
    heroOverlayLogo: {
        width: 170,
        height: 52,
    },
    wideGallery: {
        xs: {
            width: 540,
            height: 200,
        },
        s: {
            width: 768,
            height: 200,
        },
        m: {
            width: 1024,
            height: 340,
        },
        l: {
            width: 1240,
            height: 480,
        },
        xl: {
            width: 1240,
            height: 480,
        },
    },
    hotelSearchCard: {
        xs: {
            width: 768,
            height: 260,
        },
        m: {
            width: 500,
            height: 380,
        },
    },
    roomCard: {
        width: 540,
        height: 230,
    },
    custom: {},
};
export var ImageFormat;
(function (ImageFormat) {
    ImageFormat["Webp"] = "webp";
})(ImageFormat || (ImageFormat = {}));
export function augmentImageUrl(origin, baseUrl, type, options, customSize) {
    var _a, _b;
    const breakpoint = options && options.breakpoint;
    const crop = (options === null || options === void 0 ? void 0 : options.crop) || true;
    const srcset = (options === null || options === void 0 ? void 0 : options.srcset) || false;
    const format = (options === null || options === void 0 ? void 0 : options.format) || undefined;
    const quality = (options === null || options === void 0 ? void 0 : options.quality) || 80;
    const retina = (options === null || options === void 0 ? void 0 : options.retina) || false;
    const multiplier = retina ? 2 : 1;
    // If type is given as custom, it will fetch the dimensions which are passed to it as props in customSize
    let dimensions = type === 'custom' ? customSize : imageSizes[type];
    let width;
    let height;
    if (Object.prototype.hasOwnProperty.call(dimensions, 'width')) {
        dimensions = dimensions;
        height = dimensions.height * multiplier;
        width = dimensions.width * multiplier;
    }
    else {
        dimensions = dimensions;
        height = breakpoint ? ((_a = dimensions[breakpoint]) === null || _a === void 0 ? void 0 : _a.height) || 0 * multiplier : 0;
        width = breakpoint ? ((_b = dimensions[breakpoint]) === null || _b === void 0 ? void 0 : _b.width) || 0 * multiplier : 0;
    }
    const parsedUrl = new URL(baseUrl);
    const params = new URLSearchParams(parsedUrl.search);
    if (origin === ImageOrigin.Wordpress) {
        // The wordpress CMS supports a comma delimited 'resize' parameter .
        const paramValue = `${width},${height}`;
        params.append('resize', paramValue);
        parsedUrl.search = params.toString();
        return parsedUrl.toString();
    }
    // 'facade' and 'hotelCDN' both support 'width' and 'height' parameters
    params.append('width', width.toString());
    params.append('height', height.toString());
    if (crop) {
        if (origin === ImageOrigin.Facade) {
            // 'crop' is a value of the 'mode' parameter.
            if (params.has('mode')) {
                params.set('mode', 'crop');
            }
            else {
                params.append('mode', 'crop');
            }
        }
        else {
            // ImageOrigin.HotelImage
            params.append('crop', 'true');
        }
    }
    if (format) {
        params.append('format', format);
    }
    if (quality) {
        params.append('quality', quality.toString());
    }
    parsedUrl.search = params.toString();
    return srcset ? `${parsedUrl.toString()} ${width}w` : parsedUrl.toString();
}
export function getHotelImageBaseUrl(hotelImageCDN, estabId) {
    return `https://${hotelImageCDN}/images/hotels/${estabId}`;
}
export function getHotelImageUrl(hotelImageCDN, estabId, index = 0) {
    return `${getHotelImageBaseUrl(hotelImageCDN, estabId)}/${index}`;
}
