export var AdvancePassengerInformationDocumentType;
(function (AdvancePassengerInformationDocumentType) {
    AdvancePassengerInformationDocumentType["IdentityCard"] = "IDENTITY_CARD";
    AdvancePassengerInformationDocumentType["Passport"] = "PASSPORT";
})(AdvancePassengerInformationDocumentType || (AdvancePassengerInformationDocumentType = {}));
export var AirportListType;
(function (AirportListType) {
    AirportListType["Arrival"] = "Arrival";
    AirportListType["Departure"] = "Departure";
})(AirportListType || (AirportListType = {}));
export var AttractionContentType;
(function (AttractionContentType) {
    AttractionContentType["Importantinformation"] = "IMPORTANTINFORMATION";
    AttractionContentType["Pageincluded"] = "PAGEINCLUDED";
    AttractionContentType["Whatsincluded"] = "WHATSINCLUDED";
})(AttractionContentType || (AttractionContentType = {}));
export var AttractionVisitorType;
(function (AttractionVisitorType) {
    AttractionVisitorType["Adult"] = "ADULT";
    AttractionVisitorType["Child"] = "CHILD";
    AttractionVisitorType["Infant"] = "INFANT";
})(AttractionVisitorType || (AttractionVisitorType = {}));
export var AuthorType;
(function (AuthorType) {
    AuthorType["Agent"] = "AGENT";
    AuthorType["Customer"] = "CUSTOMER";
    AuthorType["System"] = "SYSTEM";
})(AuthorType || (AuthorType = {}));
export var BackgroundColourName;
(function (BackgroundColourName) {
    BackgroundColourName["Accent"] = "Accent";
    BackgroundColourName["Black"] = "Black";
    BackgroundColourName["Offset"] = "Offset";
    BackgroundColourName["Primary"] = "Primary";
    BackgroundColourName["Secondary"] = "Secondary";
    BackgroundColourName["White"] = "White";
})(BackgroundColourName || (BackgroundColourName = {}));
export var BackgroundPosition;
(function (BackgroundPosition) {
    BackgroundPosition["BottomLeft"] = "BottomLeft";
    BackgroundPosition["BottomRight"] = "BottomRight";
    BackgroundPosition["FullWidth"] = "FullWidth";
    BackgroundPosition["Tiled"] = "Tiled";
    BackgroundPosition["TopLeft"] = "TopLeft";
    BackgroundPosition["TopRight"] = "TopRight";
})(BackgroundPosition || (BackgroundPosition = {}));
export var BookingStatus;
(function (BookingStatus) {
    BookingStatus["Booked"] = "BOOKED";
    BookingStatus["Cancelled"] = "CANCELLED";
    BookingStatus["Complete"] = "COMPLETE";
    BookingStatus["Holdawaitingconfirmation"] = "HOLDAWAITINGCONFIRMATION";
    BookingStatus["Other"] = "OTHER";
    BookingStatus["Pending"] = "PENDING";
    BookingStatus["Receipted"] = "RECEIPTED";
    BookingStatus["Ticketed"] = "TICKETED";
})(BookingStatus || (BookingStatus = {}));
/** Will require all the booking types */
export var BookingType;
(function (BookingType) {
    BookingType["Accommodation"] = "ACCOMMODATION";
    BookingType["Adhoc"] = "ADHOC";
    BookingType["Airportparking"] = "AIRPORTPARKING";
    BookingType["Attractions"] = "ATTRACTIONS";
    BookingType["Bundle"] = "BUNDLE";
    BookingType["Carhire"] = "CARHIRE";
    BookingType["Cruise"] = "CRUISE";
    BookingType["Flight"] = "FLIGHT";
    BookingType["Holiday"] = "HOLIDAY";
    BookingType["Insurance"] = "INSURANCE";
    BookingType["Marhaba"] = "MARHABA";
    BookingType["Packageholidays"] = "PACKAGEHOLIDAYS";
    BookingType["Transfer"] = "TRANSFER";
})(BookingType || (BookingType = {}));
export var CpgOrderStatus;
(function (CpgOrderStatus) {
    CpgOrderStatus["Failed"] = "FAILED";
    CpgOrderStatus["Retry"] = "RETRY";
    CpgOrderStatus["Success"] = "SUCCESS";
})(CpgOrderStatus || (CpgOrderStatus = {}));
export var CsrSuppressionStatus;
(function (CsrSuppressionStatus) {
    CsrSuppressionStatus["CsrsAllowed"] = "csrsAllowed";
    CsrSuppressionStatus["CsrsPartlySuppressed"] = "csrsPartlySuppressed";
    CsrSuppressionStatus["CsrsSuppressed"] = "csrsSuppressed";
})(CsrSuppressionStatus || (CsrSuppressionStatus = {}));
export var CallToActionType;
(function (CallToActionType) {
    CallToActionType["Button"] = "Button";
    CallToActionType["EmailSignUp"] = "EmailSignUp";
})(CallToActionType || (CallToActionType = {}));
export var CancellationReason;
(function (CancellationReason) {
    CancellationReason["Cancelled"] = "Cancelled";
    CancellationReason["Change"] = "Change";
    CancellationReason["LowerCost"] = "LowerCost";
    CancellationReason["Other"] = "Other";
    CancellationReason["Unable"] = "Unable";
})(CancellationReason || (CancellationReason = {}));
export var CancellationRejectionReason;
(function (CancellationRejectionReason) {
    CancellationRejectionReason["AlreadyInProgress"] = "AlreadyInProgress";
    CancellationRejectionReason["CannotWorkOutExactSupplierCharge"] = "CannotWorkOutExactSupplierCharge";
    CancellationRejectionReason["DisabledValidationForBookingTypeAndDomain"] = "DisabledValidationForBookingTypeAndDomain";
    CancellationRejectionReason["ErrorOccurredWhileGettingSupplierCharge"] = "ErrorOccurredWhileGettingSupplierCharge";
    CancellationRejectionReason["FullyPaidUp"] = "FullyPaidUp";
    CancellationRejectionReason["HasScheduledPayments"] = "HasScheduledPayments";
    CancellationRejectionReason["IncorrectType"] = "IncorrectType";
    CancellationRejectionReason["IsVilla"] = "IsVilla";
    CancellationRejectionReason["LinkedBookings"] = "LinkedBookings";
    CancellationRejectionReason["LinkedBookingsDifferentExchangeRates"] = "LinkedBookingsDifferentExchangeRates";
    CancellationRejectionReason["MixedSupplierAndTrCharges"] = "MixedSupplierAndTrCharges";
    CancellationRejectionReason["NoRefundDetails"] = "NoRefundDetails";
    CancellationRejectionReason["NoSupplierCancellationCharge"] = "NoSupplierCancellationCharge";
    CancellationRejectionReason["NoSuppliersFound"] = "NoSuppliersFound";
    CancellationRejectionReason["NotAllSuppliersAutoCancelable"] = "NotAllSuppliersAutoCancelable";
    CancellationRejectionReason["NotAllowedDomain"] = "NotAllowedDomain";
    CancellationRejectionReason["NotConfirmed"] = "NotConfirmed";
    CancellationRejectionReason["NotDefaultAccountExchangeRate"] = "NotDefaultAccountExchangeRate";
    CancellationRejectionReason["NotGbp"] = "NotGbp";
    CancellationRejectionReason["NotReceipted"] = "NotReceipted";
    CancellationRejectionReason["NotRefundable"] = "NotRefundable";
    CancellationRejectionReason["NotSingleRoom"] = "NotSingleRoom";
    CancellationRejectionReason["NotUkDomain"] = "NotUkDomain";
    CancellationRejectionReason["Ok"] = "Ok";
    CancellationRejectionReason["PackageBooking"] = "PackageBooking";
    CancellationRejectionReason["PayAtHotel"] = "PayAtHotel";
    CancellationRejectionReason["PayPal"] = "PayPal";
    CancellationRejectionReason["TooNearDeparture"] = "TooNearDeparture";
    /** Catch all if any other error creeps through */
    CancellationRejectionReason["Unknown"] = "Unknown";
})(CancellationRejectionReason || (CancellationRejectionReason = {}));
export var CardType;
(function (CardType) {
    CardType["Deal"] = "DEAL";
    CardType["Destination"] = "DESTINATION";
    CardType["Generic"] = "GENERIC";
    CardType["Hotel"] = "HOTEL";
    CardType["Merchandise"] = "MERCHANDISE";
    CardType["Priceblock"] = "PRICEBLOCK";
})(CardType || (CardType = {}));
export var CarouselDisplayStyle;
(function (CarouselDisplayStyle) {
    CarouselDisplayStyle["Adjacent"] = "Adjacent";
    CarouselDisplayStyle["Default"] = "Default";
    CarouselDisplayStyle["FullWidth"] = "FullWidth";
})(CarouselDisplayStyle || (CarouselDisplayStyle = {}));
/** An enumeration of the three data types valid for config settings (currently coming from Derwent.io) */
export var ConfigSettingType;
(function (ConfigSettingType) {
    ConfigSettingType["Boolean"] = "boolean";
    ConfigSettingType["Number"] = "number";
    ConfigSettingType["String"] = "string";
})(ConfigSettingType || (ConfigSettingType = {}));
export var ContinueToBookErrorStatus;
(function (ContinueToBookErrorStatus) {
    ContinueToBookErrorStatus["BasketErrorFlight"] = "BASKET_ERROR_FLIGHT";
    ContinueToBookErrorStatus["BasketHotelError"] = "BASKET_HOTEL_ERROR";
    ContinueToBookErrorStatus["BasketHotelNoProducts"] = "BASKET_HOTEL_NO_PRODUCTS";
    ContinueToBookErrorStatus["BookingErrorHotelGetBasket"] = "BOOKING_ERROR_HOTEL_GET_BASKET";
    ContinueToBookErrorStatus["BookingErrorHotelGetDetails"] = "BOOKING_ERROR_HOTEL_GET_DETAILS";
    ContinueToBookErrorStatus["BookingErrorHotelNoBasket"] = "BOOKING_ERROR_HOTEL_NO_BASKET";
    ContinueToBookErrorStatus["FlightDetailsError"] = "FLIGHT_DETAILS_ERROR";
    ContinueToBookErrorStatus["HotelDetails400"] = "HOTEL_DETAILS400";
    ContinueToBookErrorStatus["HotelDetails404"] = "HOTEL_DETAILS404";
    ContinueToBookErrorStatus["HotelDetails408"] = "HOTEL_DETAILS408";
    ContinueToBookErrorStatus["HotelDetails410"] = "HOTEL_DETAILS410";
    ContinueToBookErrorStatus["HotelDetails500"] = "HOTEL_DETAILS500";
    ContinueToBookErrorStatus["HotelDetails504"] = "HOTEL_DETAILS504";
    ContinueToBookErrorStatus["HotelDetailsOther"] = "HOTEL_DETAILS_OTHER";
    ContinueToBookErrorStatus["HotelDetailsUnkown"] = "HOTEL_DETAILS_UNKOWN";
    ContinueToBookErrorStatus["OtherError"] = "OTHER_ERROR";
    ContinueToBookErrorStatus["Success"] = "SUCCESS";
    ContinueToBookErrorStatus["TripError"] = "TRIP_ERROR";
    ContinueToBookErrorStatus["TripErrorNoAvailabilityId"] = "TRIP_ERROR_NO_AVAILABILITY_ID";
    ContinueToBookErrorStatus["TripErrorNoBasket"] = "TRIP_ERROR_NO_BASKET";
    ContinueToBookErrorStatus["TripErrorNoFlight"] = "TRIP_ERROR_NO_FLIGHT";
})(ContinueToBookErrorStatus || (ContinueToBookErrorStatus = {}));
export var CsrMessageOrigin;
(function (CsrMessageOrigin) {
    CsrMessageOrigin["AgentLog"] = "AGENT_LOG";
    CsrMessageOrigin["AgentQuestion"] = "AGENT_QUESTION";
    CsrMessageOrigin["CustomerAnswer"] = "CUSTOMER_ANSWER";
    CsrMessageOrigin["CustomerInitialMessage"] = "CUSTOMER_INITIAL_MESSAGE";
    CsrMessageOrigin["CustomerLog"] = "CUSTOMER_LOG";
})(CsrMessageOrigin || (CsrMessageOrigin = {}));
export var CsrStatus;
(function (CsrStatus) {
    CsrStatus["AwaitingCustomerAcknowledgement"] = "AWAITING_CUSTOMER_ACKNOWLEDGEMENT";
    CsrStatus["AwaitingCustomerResponse"] = "AWAITING_CUSTOMER_RESPONSE";
    CsrStatus["Completed"] = "COMPLETED";
    CsrStatus["InProgress"] = "IN_PROGRESS";
    CsrStatus["Pending"] = "PENDING";
})(CsrStatus || (CsrStatus = {}));
export var CurrencyFormats;
(function (CurrencyFormats) {
    CurrencyFormats["Formatted"] = "FORMATTED";
    CurrencyFormats["Rounded"] = "ROUNDED";
    CurrencyFormats["Value"] = "VALUE";
})(CurrencyFormats || (CurrencyFormats = {}));
export var DateFormats;
(function (DateFormats) {
    DateFormats["Default"] = "DEFAULT";
    DateFormats["Fulldate"] = "FULLDATE";
    DateFormats["Iso"] = "ISO";
    DateFormats["Monthyear"] = "MONTHYEAR";
    DateFormats["Raw"] = "RAW";
    DateFormats["Shortdate"] = "SHORTDATE";
    DateFormats["Shortdaydate"] = "SHORTDAYDATE";
    DateFormats["Time"] = "TIME";
})(DateFormats || (DateFormats = {}));
export var DateSearchType;
(function (DateSearchType) {
    DateSearchType["ChooseDate"] = "CHOOSE_DATE";
    DateSearchType["ChooseMonth"] = "CHOOSE_MONTH";
})(DateSearchType || (DateSearchType = {}));
export var DeviceType;
(function (DeviceType) {
    DeviceType["Desktop"] = "Desktop";
    DeviceType["Mobile"] = "Mobile";
})(DeviceType || (DeviceType = {}));
export var ExternalProvider;
(function (ExternalProvider) {
    ExternalProvider["Apple"] = "APPLE";
    ExternalProvider["Facebook"] = "FACEBOOK";
    ExternalProvider["Google"] = "GOOGLE";
    ExternalProvider["Googlepeople"] = "GOOGLEPEOPLE";
    ExternalProvider["Googlev2"] = "GOOGLEV2";
})(ExternalProvider || (ExternalProvider = {}));
export var ExtraType;
(function (ExtraType) {
    ExtraType["Attraction"] = "ATTRACTION";
    ExtraType["Bag"] = "BAG";
    ExtraType["Handluggage"] = "HANDLUGGAGE";
    ExtraType["Includedholdbag"] = "INCLUDEDHOLDBAG";
    ExtraType["Insurance"] = "INSURANCE";
    ExtraType["Mandatorytransfer"] = "MANDATORYTRANSFER";
    ExtraType["Optionaltransfer"] = "OPTIONALTRANSFER";
    ExtraType["Seat"] = "SEAT";
})(ExtraType || (ExtraType = {}));
export var Flighttype;
(function (Flighttype) {
    Flighttype["Both"] = "BOTH";
    Flighttype["Inbound"] = "INBOUND";
    Flighttype["None"] = "NONE";
    Flighttype["Outbound"] = "OUTBOUND";
})(Flighttype || (Flighttype = {}));
export var FilterGroupEnum;
(function (FilterGroupEnum) {
    FilterGroupEnum["Checkbox"] = "CHECKBOX";
    FilterGroupEnum["Pricerange"] = "PRICERANGE";
    FilterGroupEnum["Range"] = "RANGE";
    FilterGroupEnum["Text"] = "TEXT";
})(FilterGroupEnum || (FilterGroupEnum = {}));
export var FindAddressItemType;
(function (FindAddressItemType) {
    FindAddressItemType["Address"] = "ADDRESS";
    FindAddressItemType["Container"] = "CONTAINER";
})(FindAddressItemType || (FindAddressItemType = {}));
export var FlightItineraryType;
(function (FlightItineraryType) {
    FlightItineraryType["Inbound"] = "INBOUND";
    FlightItineraryType["Outbound"] = "OUTBOUND";
})(FlightItineraryType || (FlightItineraryType = {}));
export var FlightJourneyType;
(function (FlightJourneyType) {
    FlightJourneyType["Multicity"] = "MULTICITY";
    FlightJourneyType["Oneway"] = "ONEWAY";
    FlightJourneyType["Return"] = "RETURN";
})(FlightJourneyType || (FlightJourneyType = {}));
/** FlightSortCriterion contains possible sorting options for Flight searches */
export var FlightSortCriterion;
(function (FlightSortCriterion) {
    FlightSortCriterion["DepartureTimeEarliestFirst"] = "DEPARTURE_TIME_EARLIEST_FIRST";
    FlightSortCriterion["DepartureTimeLatestFirst"] = "DEPARTURE_TIME_LATEST_FIRST";
    FlightSortCriterion["DurationLongestFirst"] = "DURATION_LONGEST_FIRST";
    FlightSortCriterion["DurationQuickestFirst"] = "DURATION_QUICKEST_FIRST";
    FlightSortCriterion["PriceHighestFirst"] = "PRICE_HIGHEST_FIRST";
    FlightSortCriterion["PriceLowestFirst"] = "PRICE_LOWEST_FIRST";
    FlightSortCriterion["RecommendedHighestFirst"] = "RECOMMENDED_HIGHEST_FIRST";
    FlightSortCriterion["ReturnTimeEarliestFirst"] = "RETURN_TIME_EARLIEST_FIRST";
    FlightSortCriterion["ReturnTimeLatestFirst"] = "RETURN_TIME_LATEST_FIRST";
})(FlightSortCriterion || (FlightSortCriterion = {}));
export var GlobalContentKey;
(function (GlobalContentKey) {
    GlobalContentKey["Footer"] = "FOOTER";
    GlobalContentKey["Header"] = "HEADER";
    GlobalContentKey["Popup"] = "POPUP";
    GlobalContentKey["Promobar"] = "PROMOBAR";
    GlobalContentKey["Redirects"] = "REDIRECTS";
})(GlobalContentKey || (GlobalContentKey = {}));
export var HypeType;
(function (HypeType) {
    HypeType["Amendable"] = "AMENDABLE";
    HypeType["Covidprotectioncover"] = "COVIDPROTECTIONCOVER";
    HypeType["Nonrefundable"] = "NONREFUNDABLE";
    HypeType["Refundable"] = "REFUNDABLE";
    HypeType["Specialoffertext"] = "SPECIALOFFERTEXT";
})(HypeType || (HypeType = {}));
export var ImageOrigin;
(function (ImageOrigin) {
    ImageOrigin["Facade"] = "Facade";
    ImageOrigin["HotelImage"] = "HotelImage";
    ImageOrigin["Wordpress"] = "Wordpress";
})(ImageOrigin || (ImageOrigin = {}));
export var LegFilterInput;
(function (LegFilterInput) {
    LegFilterInput["Inbound"] = "INBOUND";
    LegFilterInput["Outbound"] = "OUTBOUND";
})(LegFilterInput || (LegFilterInput = {}));
export var MailingPreferenceType;
(function (MailingPreferenceType) {
    MailingPreferenceType["Email"] = "EMAIL";
    MailingPreferenceType["Sms"] = "SMS";
})(MailingPreferenceType || (MailingPreferenceType = {}));
export var NotificationType;
(function (NotificationType) {
    NotificationType["Balance"] = "BALANCE";
    NotificationType["CsrQuestion"] = "CSR_QUESTION";
    NotificationType["FlightCheckIn"] = "FLIGHT_CHECK_IN";
    NotificationType["LeaveReview"] = "LEAVE_REVIEW";
    NotificationType["Other"] = "OTHER";
    NotificationType["PassengerApi"] = "PASSENGER_API";
    NotificationType["PayAtHotel"] = "PAY_AT_HOTEL";
    NotificationType["ScheduledChange"] = "SCHEDULED_CHANGE";
})(NotificationType || (NotificationType = {}));
export var NotificationTypes;
(function (NotificationTypes) {
    NotificationTypes["Error"] = "Error";
    NotificationTypes["None"] = "None";
    NotificationTypes["Notification"] = "Notification";
})(NotificationTypes || (NotificationTypes = {}));
export var OverlayImagePosition;
(function (OverlayImagePosition) {
    OverlayImagePosition["Bottom"] = "Bottom";
    OverlayImagePosition["Top"] = "Top";
})(OverlayImagePosition || (OverlayImagePosition = {}));
export var PageStrand;
(function (PageStrand) {
    PageStrand["Holiday"] = "HOLIDAY";
    PageStrand["Hotel"] = "HOTEL";
})(PageStrand || (PageStrand = {}));
export var PassengerType;
(function (PassengerType) {
    PassengerType["Adult"] = "ADULT";
    PassengerType["Child"] = "CHILD";
    PassengerType["Infant"] = "INFANT";
    PassengerType["None"] = "NONE";
})(PassengerType || (PassengerType = {}));
export var PaymentMethod;
(function (PaymentMethod) {
    PaymentMethod["CreditCard"] = "CreditCard";
    PaymentMethod["DebitCard"] = "DebitCard";
    PaymentMethod["PayPal"] = "PayPal";
    PaymentMethod["Unsupported"] = "Unsupported";
})(PaymentMethod || (PaymentMethod = {}));
/** PaymentStructure holds the payment structure the user has selected. */
export var PaymentStructure;
(function (PaymentStructure) {
    /** Paying deposit amount only */
    PaymentStructure["Deposit"] = "DEPOSIT";
    /** Paying the full amunt of the bookings */
    PaymentStructure["Fullamount"] = "FULLAMOUNT";
    /** Paying via the prebuilt payment schedule */
    PaymentStructure["Schedule"] = "SCHEDULE";
})(PaymentStructure || (PaymentStructure = {}));
export var PaymentType;
(function (PaymentType) {
    PaymentType["ApplePay"] = "APPLE_PAY";
    PaymentType["Creditcard"] = "CREDITCARD";
    PaymentType["Debitcard"] = "DEBITCARD";
    PaymentType["Enbd"] = "ENBD";
    PaymentType["GPay"] = "G_PAY";
    PaymentType["Openbanking"] = "OPENBANKING";
    PaymentType["Paypal"] = "PAYPAL";
    PaymentType["PaypalPayLater"] = "PAYPAL_PAY_LATER";
    PaymentType["PaypalV2"] = "PAYPAL_V2";
    PaymentType["Unsupported"] = "UNSUPPORTED";
})(PaymentType || (PaymentType = {}));
export var PendingBookingReason;
(function (PendingBookingReason) {
    PendingBookingReason["CustomerConfirmation"] = "CUSTOMER_CONFIRMATION";
    PendingBookingReason["Unknown"] = "UNKNOWN";
})(PendingBookingReason || (PendingBookingReason = {}));
export var PickUpDropOffType;
(function (PickUpDropOffType) {
    PickUpDropOffType["Airport"] = "AIRPORT";
    PickUpDropOffType["Resort"] = "RESORT";
})(PickUpDropOffType || (PickUpDropOffType = {}));
export var PlacesAutocompleteStatus;
(function (PlacesAutocompleteStatus) {
    PlacesAutocompleteStatus["InvalidRequest"] = "INVALID_REQUEST";
    PlacesAutocompleteStatus["Ok"] = "OK";
    PlacesAutocompleteStatus["OverQueryLimit"] = "OVER_QUERY_LIMIT";
    PlacesAutocompleteStatus["RequestDenied"] = "REQUEST_DENIED";
    PlacesAutocompleteStatus["UnknownError"] = "UNKNOWN_ERROR";
    PlacesAutocompleteStatus["ZeroResults"] = "ZERO_RESULTS";
})(PlacesAutocompleteStatus || (PlacesAutocompleteStatus = {}));
export var PlacesDetailStatus;
(function (PlacesDetailStatus) {
    PlacesDetailStatus["InvalidRequest"] = "INVALID_REQUEST";
    PlacesDetailStatus["NotFound"] = "NOT_FOUND";
    PlacesDetailStatus["Ok"] = "OK";
    PlacesDetailStatus["OverQueryLimit"] = "OVER_QUERY_LIMIT";
    PlacesDetailStatus["RequestDenied"] = "REQUEST_DENIED";
    PlacesDetailStatus["UnknownError"] = "UNKNOWN_ERROR";
    PlacesDetailStatus["ZeroResults"] = "ZERO_RESULTS";
})(PlacesDetailStatus || (PlacesDetailStatus = {}));
export var PlacesReviewsSortType;
(function (PlacesReviewsSortType) {
    PlacesReviewsSortType["MostRelevant"] = "mostRelevant";
    PlacesReviewsSortType["Newest"] = "newest";
})(PlacesReviewsSortType || (PlacesReviewsSortType = {}));
export var PriceTolerance;
(function (PriceTolerance) {
    PriceTolerance["LessThanFifty"] = "lessThanFifty";
    PriceTolerance["LessThanFive"] = "lessThanFive";
    PriceTolerance["LessThanTwoHundredAndFifty"] = "lessThanTwoHundredAndFifty";
    PriceTolerance["MoreThanTwoHundredAndFifty"] = "moreThanTwoHundredAndFifty";
})(PriceTolerance || (PriceTolerance = {}));
export var RebookIntention;
(function (RebookIntention) {
    RebookIntention["No"] = "No";
    RebookIntention["Yes7Days"] = "Yes7Days";
    RebookIntention["YesNot7Days"] = "YesNot7Days";
})(RebookIntention || (RebookIntention = {}));
export var SelectRoomResult;
(function (SelectRoomResult) {
    SelectRoomResult["BasketErrorFlight"] = "BasketErrorFlight";
    SelectRoomResult["BasketErrorHotel"] = "BasketErrorHotel";
    SelectRoomResult["BasketErrorHotelEmpty"] = "BasketErrorHotelEmpty";
    SelectRoomResult["BookingErrorHotelGetBasket"] = "BookingErrorHotelGetBasket";
    SelectRoomResult["BookingErrorHotelGetDetails"] = "BookingErrorHotelGetDetails";
    SelectRoomResult["BookingErrorHotelNoBasket"] = "BookingErrorHotelNoBasket";
    SelectRoomResult["FlightDetailsError"] = "FlightDetailsError";
    SelectRoomResult["HotelGetDetails400"] = "HotelGetDetails400";
    SelectRoomResult["HotelGetDetails404"] = "HotelGetDetails404";
    SelectRoomResult["HotelGetDetails408"] = "HotelGetDetails408";
    SelectRoomResult["HotelGetDetails410"] = "HotelGetDetails410";
    SelectRoomResult["HotelGetDetails500"] = "HotelGetDetails500";
    SelectRoomResult["HotelGetDetails504"] = "HotelGetDetails504";
    SelectRoomResult["HotelGetDetailsNotFound"] = "HotelGetDetailsNotFound";
    SelectRoomResult["HotelGetDetailsUnknown"] = "HotelGetDetailsUnknown";
    SelectRoomResult["OtherError"] = "OtherError";
    SelectRoomResult["Success"] = "Success";
    SelectRoomResult["TripError"] = "TripError";
    SelectRoomResult["TripErrorNoBasket"] = "TripErrorNoBasket";
    SelectRoomResult["TripErrorNoFlight"] = "TripErrorNoFlight";
    SelectRoomResult["TripErrorNoHotelAvailability"] = "TripErrorNoHotelAvailability";
})(SelectRoomResult || (SelectRoomResult = {}));
/** SortCriterion contains possible sorting options for Hotel searches */
export var SortCriterion;
(function (SortCriterion) {
    SortCriterion["CustomerRatingHighestFirst"] = "CUSTOMER_RATING_HIGHEST_FIRST";
    SortCriterion["DistanceLowestFirst"] = "DISTANCE_LOWEST_FIRST";
    SortCriterion["DurationLowestFirst"] = "DURATION_LOWEST_FIRST";
    SortCriterion["HotelNameLowestFirst"] = "HOTEL_NAME_LOWEST_FIRST";
    SortCriterion["None"] = "NONE";
    SortCriterion["PriceHighestFirst"] = "PRICE_HIGHEST_FIRST";
    SortCriterion["PriceLowestFirst"] = "PRICE_LOWEST_FIRST";
    SortCriterion["RecommendedHighestFirst"] = "RECOMMENDED_HIGHEST_FIRST";
    SortCriterion["RoomNightsBookedInLastYearHighestFirst"] = "ROOM_NIGHTS_BOOKED_IN_LAST_YEAR_HIGHEST_FIRST";
    SortCriterion["StarRatingHighestFirst"] = "STAR_RATING_HIGHEST_FIRST";
    SortCriterion["StarRatingLowestFirst"] = "STAR_RATING_LOWEST_FIRST";
})(SortCriterion || (SortCriterion = {}));
export var TextColor;
(function (TextColor) {
    TextColor["Dark"] = "Dark";
    TextColor["Light"] = "Light";
})(TextColor || (TextColor = {}));
export var TimingType;
(function (TimingType) {
    TimingType["SearchLeadTime"] = "searchLeadTime";
})(TimingType || (TimingType = {}));
export var TransfersAutocompleterRequestFor;
(function (TransfersAutocompleterRequestFor) {
    TransfersAutocompleterRequestFor["Airports"] = "AIRPORTS";
    TransfersAutocompleterRequestFor["All"] = "ALL";
    TransfersAutocompleterRequestFor["Resorts"] = "RESORTS";
})(TransfersAutocompleterRequestFor || (TransfersAutocompleterRequestFor = {}));
export var TriggerConditions;
(function (TriggerConditions) {
    TriggerConditions["ExitIntent"] = "ExitIntent";
    TriggerConditions["Inactivity"] = "Inactivity";
    TriggerConditions["ScrollDepth"] = "ScrollDepth";
})(TriggerConditions || (TriggerConditions = {}));
export var TripBookingStatus;
(function (TripBookingStatus) {
    TripBookingStatus["Cancelled"] = "CANCELLED";
    TripBookingStatus["Pending"] = "PENDING";
    TripBookingStatus["Receipted"] = "RECEIPTED";
})(TripBookingStatus || (TripBookingStatus = {}));
export var TripTravelledStatus;
(function (TripTravelledStatus) {
    TripTravelledStatus["InResort"] = "IN_RESORT";
    TripTravelledStatus["Past"] = "PAST";
    TripTravelledStatus["Predeparture"] = "PREDEPARTURE";
})(TripTravelledStatus || (TripTravelledStatus = {}));
export var TripType;
(function (TripType) {
    TripType["Flight"] = "FLIGHT";
    TripType["Holiday"] = "HOLIDAY";
    TripType["Hotel"] = "HOTEL";
    TripType["Transfer"] = "TRANSFER";
})(TripType || (TripType = {}));
const result = {
    possibleTypes: {
        AirportAutocompleterResult: ['AirportGroup', 'SingleAirport'],
        AttractionPage: ['AttractionCmsPage', 'AttractionProductPage'],
        AttractionPageResponse: ['AttractionCmsPage', 'AttractionProductPage'],
        BackgroundUnion: ['BackgroundColour', 'BackgroundImage'],
        BasketProductItinerary: [
            'BasketFlightItinerary',
            'BasketHotelItinerary',
            'BasketInsuranceItinerary',
            'BasketTransferItinerary',
        ],
        BookingPayment: ['ClearedPayment', 'DirectPayment', 'FailedPayment', 'FuturePayment'],
        CallToActionUnion: ['Button', 'EmailSignUp'],
        CardBase: ['DealCard', 'DestinationCard', 'GenericCard', 'HotelCard', 'PriceBlockCards'],
        CardUnion: ['Card', 'DealCard', 'DestinationCard', 'HotelCard', 'MerchandiseCard', 'PriceBlockCards'],
        Component: [
            'AccordionGroup',
            'Breadcrumbs',
            'Carousel',
            'ComponentGroup',
            'ContentBlock',
            'Faq',
            'FlightRoute',
            'FooterMenu',
            'Gallery',
            'GenericComponent',
            'Grid',
            'HTMLBlock',
            'Headline',
            'Hero',
            'InlineLinkGroup',
            'IntroText',
            'KeyFacts',
            'KeyFactsItem',
            'LinkMenu',
            'Map',
            'PaymentCard',
            'RootComponentUnknown',
            'RootUSPGroup',
            'RoutingTabset',
            'SearchUnit',
            'SectionHeading',
            'SignupForm',
            'SubComponentUnknown',
            'Tabset',
            'TransferSearchUnit',
            'USP',
            'USPGroup',
            'USPItem',
            'VideoWidget',
            'WeatherItemGroup',
        ],
        CreateBookingResponse: [
            'CpgBookingResponse',
            'PaypalBookingResponse',
            'PendingCustomerConfirmationResponse',
            'PostPaymentErrorBookingResponse',
            'PrePaymentErrorBookingResponse',
            'RefreshBookingResponse',
            'SuccessfulBookingResponse',
            'ThreeDSBookingResponse',
            'UnexpectedbookingResponse',
            'ValidationErrorBookingResponse',
        ],
        Extra: [
            'AttractionExtra',
            'BaggageExtra',
            'InsuranceExtra',
            'MandatoryTransferExtra',
            'OptionalTransferExtra',
            'PassengerExtra',
            'RoomExtra',
        ],
        FilterGroup: ['CheckboxFilterGroup', 'RangeFilterGroup', 'TextFilter'],
        FilterGroupBase: ['CheckboxFilterGroup', 'RangeFilterGroup', 'TextFilter'],
        GlobalContentUnion: ['FooterMenu', 'MainMenu', 'PageRedirects', 'PopUp', 'PromoBar'],
        Itinerary: [
            'AirportParkingItinerary',
            'AttractionsItinerary',
            'CarHireItinerary',
            'FlightItinerary',
            'GenericItinerary',
            'HotelItinerary',
            'InsuranceItinerary',
            'TransferItinerary',
        ],
        Notification: [
            'BalanceNotification',
            'CsrQuestionNotification',
            'FlightCheckInNotification',
            'LeaveReviewNotification',
            'OtherNotification',
            'PassengerApiNotification',
            'PayAtHotelNotification',
            'ScheduledChangeNotification',
        ],
        RootComponent: [
            'Breadcrumbs',
            'ComponentGroup',
            'Gallery',
            'GenericComponent',
            'Hero',
            'Map',
            'RootComponentUnknown',
            'RootUSPGroup',
            'RoutingTabset',
        ],
        RootComponentUnion: [
            'Breadcrumbs',
            'ComponentGroup',
            'Gallery',
            'GenericComponent',
            'Hero',
            'Map',
            'RootComponentUnknown',
            'RootUSPGroup',
            'RoutingTabset',
        ],
        SubComponent: [
            'AccordionGroup',
            'Carousel',
            'ContentBlock',
            'Faq',
            'FlightRoute',
            'FooterMenu',
            'Grid',
            'HTMLBlock',
            'Headline',
            'InlineLinkGroup',
            'IntroText',
            'KeyFacts',
            'KeyFactsItem',
            'LinkMenu',
            'PaymentCard',
            'SearchUnit',
            'SectionHeading',
            'SignupForm',
            'SubComponentUnknown',
            'Tabset',
            'TransferSearchUnit',
            'USP',
            'USPGroup',
            'USPItem',
            'VideoWidget',
            'WeatherItemGroup',
        ],
        SubComponentUnion: [
            'Accordion',
            'AccordionGroup',
            'Button',
            'Callout',
            'Card',
            'Carousel',
            'ContentBlock',
            'Faq',
            'FlightRoute',
            'FooterMenu',
            'Grid',
            'HTMLBlock',
            'Headline',
            'ImageCard',
            'InlineLinkGroup',
            'IntroText',
            'KeyFacts',
            'KeyFactsItem',
            'LinkMenu',
            'PaymentCard',
            'SearchUnit',
            'SectionHeading',
            'SignupForm',
            'TabItem',
            'Tabset',
            'USP',
            'USPGroup',
            'USPItem',
            'VideoWidget',
            'WeatherItemGroup',
            'WeatherMonthItem',
        ],
        SuccessfulBookingResponseInterface: ['PendingCustomerConfirmationResponse', 'SuccessfulBookingResponse'],
        UnionNotification: [
            'BalanceNotification',
            'CsrQuestionNotification',
            'FlightCheckInNotification',
            'LeaveReviewNotification',
            'OtherNotification',
            'PassengerApiNotification',
            'PayAtHotelNotification',
            'ScheduledChangeNotification',
        ],
        selectHolidayFlightResponse: ['AdjustedDatesNoAction', 'TripState'],
    },
};
export default result;
