import * as React from 'react';
import { ActionButton, Anchor, Container, FlexGrid, Header, MenuIcon, Styler, Stacker, Divider, HeartIcon, useDisclosure, Dialog2, DialogHeader, } from '@honeycomb/ui-core';
import { FeatureFlagEnum, hasFeatureFlag, parseBoolean, useGlobalContext } from '@honeycomb/data';
import { AccountMenuTrigger } from '../../features/account/utils/AccountMenuTrigger';
export function DefaultMasthead({ brandLogo }) {
    const { resourceStrings: { HC_WISHLIST_LABEL }, configSettings: { EnableShortlist }, } = useGlobalContext();
    const [menuOpen, { open: handleOpenMenu, close: handleCloseMenu }] = useDisclosure(false);
    const shortlistEnabled = parseBoolean(EnableShortlist) || hasFeatureFlag(FeatureFlagEnum.SHORTLIST);
    return (React.createElement(React.Fragment, null,
        React.createElement(Header, { component: "div", disableGutters: true },
            React.createElement(Container, null,
                React.createElement(FlexGrid, { container: true, alignItems: "center", flexWrap: "nowrap" },
                    React.createElement(FlexGrid, { item: true, xs: "fill" }, brandLogo ? (React.createElement("a", { href: "/" }, brandLogo)) : (React.createElement(Anchor, { href: "/", underline: "none", weight: "semiBold", bg: "background.offset", py: 2, px: 3 }, "YOUR BRAND LOGO"))),
                    React.createElement(FlexGrid, { item: true },
                        React.createElement(Stacker, { direction: "row", spacing: 1 },
                            shortlistEnabled && (React.createElement(ActionButton, { href: "/wishlist", icon: React.createElement(HeartIcon, null), "aria-label": HC_WISHLIST_LABEL, hideLabel: { xs: true, m: false } }, HC_WISHLIST_LABEL)),
                            React.createElement(AccountMenuTrigger, null),
                            React.createElement(ActionButton, { icon: React.createElement(MenuIcon, null), onClick: handleOpenMenu, "aria-label": "Menu" })))))),
        React.createElement(Divider, null),
        React.createElement(Dialog2, { position: "drawer-right", open: menuOpen, onClose: handleCloseMenu, size: "s" },
            React.createElement(DialogHeader, { withCloseButton: true, borderStyle: "border" }, "MENU"),
            React.createElement(Styler, { h: 400, display: "flex", alignItems: "center", justifyContent: "center" }, "YOUR MENU CONTENT"))));
}
