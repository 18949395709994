import { FeatureFlagEnum, hasFeatureFlag, MainMenu, parseBoolean, useGlobalContext } from '@honeycomb/data';
import {
    Container,
    FlexGrid,
    Header,
    MenuIcon,
    Stacker,
    ActionButton,
    Divider,
    PhoneIcon,
    Styler,
    HelpIcon,
    Anchor,
    TypographyBody,
    WhatsappIcon,
    HeartIcon,
    useDisclosure,
} from '@honeycomb/ui-core';
import { AccountMenuTrigger, NavigationMenu } from '@honeycomb/ui';
import { MastheadLogo } from './MastheadLogo';

export type MastheadProps = {
    reduced?: boolean;
};

// We need the account button to have a consistent width and reserve that space while it is loading,
// otherwise we negatively impact Largest Contentful Paint due to the shifting of neighbouring content.
const ACCOUNT_BUTTON_WIDTH = { xs: 'auto', l: 138 }; // Width must accommodate the longest caption ("My account")

export function Masthead({ reduced = false }: MastheadProps) {
    const [menuOpen, { open: handleOpenMenu, close: handleCloseMenu }] = useDisclosure(false);

    const {
        resourceStrings: {
            TELEPHONENUMBER,
            WHATSAPP_NUMBER,
            HC_SUPPORTCENTREURL,
            MAINMENU_HEADING,
            HC_SUPPORTCENTRE_LABEL,
            MASTHEAD_PHONENUMBER_LABEL,
            MASTHEAD_WHATSAPP_LABEL,
            MASTHEAD_WHATSAPP_ARIALABEL,
            HC_WISHLIST_LABEL,
        },
        configSettings: { EnableShortlist },
        globalContents,
    } = useGlobalContext();

    const shortlistEnabled = parseBoolean(EnableShortlist) || hasFeatureFlag(FeatureFlagEnum.SHORTLIST);
    const phoneHref = `tel:${TELEPHONENUMBER.replace(/\s/g, '')}`;
    const whatsappHref = `https://wa.me/${WHATSAPP_NUMBER.replace(/\s/g, '')}`;

    const { HEADER } = globalContents;
    const { content: menuData } = HEADER || {};

    return (
        <>
            <Header component="div" disableGutters={true}>
                <Container>
                    <FlexGrid container={true} alignItems="center" flexWrap="nowrap">
                        <FlexGrid xs="hold">
                            {/* eslint-disable-next-line @next/next/no-html-link-for-pages */}
                            <a href="/">
                                <MastheadLogo />
                            </a>
                        </FlexGrid>
                        <FlexGrid xs="fill">
                            <Stacker
                                direction="row"
                                alignItems="center"
                                justifyContent="flex-end"
                                spacing={{ xs: 1, l: 4 }}
                            >
                                {/* TELEPHONE NUMBER (desktop) */}
                                <Styler display={{ xs: 'none', l: 'block' }}>
                                    <TypographyBody
                                        component="div"
                                        size="xs"
                                        fontSize="0.563rem" // 9px
                                        lineHeight={1}
                                        mb={0.5}
                                    >
                                        {MASTHEAD_PHONENUMBER_LABEL}
                                    </TypographyBody>
                                    <Anchor
                                        size="xl"
                                        weight="semiBold"
                                        color="primary.base"
                                        href={phoneHref}
                                        underline="none"
                                        display="block"
                                        lineHeight={1}
                                    >
                                        {TELEPHONENUMBER}
                                    </Anchor>
                                </Styler>

                                {/* TELEPHONE NUMBER (mobile) */}
                                <Styler display={{ l: 'none' }}>
                                    <ActionButton
                                        href={phoneHref}
                                        target="_new"
                                        icon={<PhoneIcon />}
                                        aria-label={MASTHEAD_PHONENUMBER_LABEL}
                                    />
                                </Styler>

                                {/* WHATSAPP BUTTON (icon-only for mobile) */}
                                <ActionButton
                                    href={whatsappHref}
                                    target="_new"
                                    icon={<WhatsappIcon />}
                                    aria-label={MASTHEAD_WHATSAPP_ARIALABEL}
                                    hideLabel={{ xs: true, l: false }}
                                >
                                    {MASTHEAD_WHATSAPP_LABEL}
                                </ActionButton>

                                {!reduced && (
                                    <AccountMenuTrigger
                                        buttonColor="text.body"
                                        hideLabel={{ xs: true, l: false }}
                                        w={ACCOUNT_BUTTON_WIDTH}
                                    />
                                )}

                                {shortlistEnabled && (
                                    <ActionButton
                                        href="/wishlist"
                                        icon={<HeartIcon />}
                                        aria-label={HC_WISHLIST_LABEL}
                                        hideLabel={{ xs: true, l: false }}
                                    >
                                        {HC_WISHLIST_LABEL}
                                    </ActionButton>
                                )}

                                {/* HELP BUTTON (icon-only for mobile) */}
                                <ActionButton
                                    href={HC_SUPPORTCENTREURL}
                                    target="_new"
                                    icon={<HelpIcon />}
                                    aria-label={HC_SUPPORTCENTRE_LABEL}
                                    hideLabel={{ xs: true, l: false }}
                                >
                                    {HC_SUPPORTCENTRE_LABEL}
                                </ActionButton>

                                {/* MENU BUTTON (icon-only always) */}
                                {!reduced && (
                                    <ActionButton icon={<MenuIcon />} onClick={handleOpenMenu} aria-label="Menu" />
                                )}
                            </Stacker>
                        </FlexGrid>
                    </FlexGrid>
                </Container>
                <NavigationMenu
                    menuData={(menuData as MainMenu) ?? undefined}
                    title={MAINMENU_HEADING}
                    open={menuOpen}
                    onClose={handleCloseMenu}
                />
            </Header>
            <Divider color="border" thickness={1} />
        </>
    );
}
