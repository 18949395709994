var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useCallback, useState } from 'react';
import { ActionButton, MenuLink, Styler, UserIcon, TravelerIcon, SupportIcon, LogoutIcon, Stacker, Divider, SettingsIcon, PopoverAnchor, Popover, PopoverPanel, Paper, } from '@honeycomb/ui-core';
import { parseBoolean, useAccount, useGlobalContext, useSignOut } from '@honeycomb/data';
import { AccountScreenEnum, accountScreenRouting, accountSettingsRouting, accountTripCSRsSelectedRoute, accountTripCSRsSelectedRouting, accountTripsRoute, accountTripsRouting, accountSettingsRoute, } from '@honeycomb/routing';
import { useRouter } from 'next/router';
export function AccountMenuTrigger(props) {
    var _a, _b;
    const { isLoggedIn, refreshSignedIn, userLoading: waitingForUserData } = useAccount();
    const router = useRouter();
    const { pathname, query } = router;
    const tripsPage = (pathname === null || pathname === void 0 ? void 0 : pathname.includes('trips')) ? 'trips' : 'undefined';
    const settingsPage = (pathname === null || pathname === void 0 ? void 0 : pathname.includes('settings')) ? 'settings' : 'undefined';
    const csrsPage = (pathname === null || pathname === void 0 ? void 0 : pathname.includes('csrs')) ? 'csrs' : 'undefined';
    const tripsPageActive = tripsPage === 'trips';
    const settingsPageActive = settingsPage === 'settings';
    const csrsPageActive = csrsPage === 'csrs';
    const tripId = (_b = (_a = query === null || query === void 0 ? void 0 : query.bookedTripId) === null || _a === void 0 ? void 0 : _a.toString()) !== null && _b !== void 0 ? _b : '';
    const { resourceStrings: { LOGIN_SIGN_IN_LABEL, LOGIN_SIGNED_IN_LABEL, MYB_ACCOUNTMENU_LOGOUT, MYB_ACCOUNTMENU_SETTINGS, MYB_ACCOUNTMENU_INBOX, MYB_ACCOUNTMENU_MYTRIPS, }, configSettings: { HcSignInLink, HcAccountMenuEnabled }, } = useGlobalContext();
    const menuEnabled = parseBoolean(HcAccountMenuEnabled);
    const signInLink = HcSignInLink;
    const { signOut } = useSignOut([refreshSignedIn, () => accountScreenRouting(null)]);
    const [open, setOpen] = useState(false);
    const handleSettingsClick = useCallback(() => __awaiter(this, void 0, void 0, function* () {
        yield accountSettingsRouting();
        setOpen(false);
    }), []);
    const tid = parseInt(tripId);
    const [cSRsSelectedHref] = accountTripCSRsSelectedRoute(tid);
    const [accountTripsRouteHref] = accountTripsRoute();
    const [accountSettingsRouteHref] = accountSettingsRoute();
    const handleAccountScreenRouting = useCallback(() => {
        (() => __awaiter(this, void 0, void 0, function* () {
            try {
                yield accountScreenRouting(signInLink !== null && signInLink !== void 0 ? signInLink : AccountScreenEnum.SIGN_IN);
            }
            catch (error) {
                console.log('error', error);
            }
        }))();
    }, [signInLink]);
    const handleButtonClick = React.useCallback(() => {
        if (isLoggedIn) {
            setOpen((current) => !current);
        }
        else {
            handleAccountScreenRouting();
        }
    }, [handleAccountScreenRouting, isLoggedIn]);
    if (!menuEnabled) {
        return null;
    }
    const buttonLabel = isLoggedIn ? LOGIN_SIGNED_IN_LABEL : LOGIN_SIGN_IN_LABEL;
    return (React.createElement(Popover, { open: open, onClose: () => setOpen(false), placement: "bottom-end", shift: true, dismiss: true },
        React.createElement(PopoverAnchor, null,
            React.createElement(ActionButton, Object.assign({ onClick: handleButtonClick, icon: React.createElement(UserIcon, null), "aria-label": buttonLabel, hideLabel: { xs: true, m: false }, disabled: waitingForUserData }, props), buttonLabel)),
        React.createElement(PopoverPanel, null,
            React.createElement(Paper, { shadow: "s", radius: "s", border: 1, overflow: "hidden", w: 200 },
                React.createElement("nav", null,
                    React.createElement(Stacker, { component: "ul", spacing: 0, bg: "background.content" },
                        React.createElement("li", null,
                            React.createElement(MenuLink, { button: true, href: accountTripsRouteHref, onClick: (event) => {
                                    event.preventDefault();
                                    accountTripsRouting();
                                    setOpen(false);
                                }, icon: React.createElement(TravelerIcon, { size: "xs" }), selected: tripsPageActive }, MYB_ACCOUNTMENU_MYTRIPS)),
                        tripId ? (React.createElement(Styler, { component: "li" },
                            React.createElement(MenuLink, { button: true, href: cSRsSelectedHref, onClick: (event) => {
                                    event.preventDefault();
                                    accountTripCSRsSelectedRouting(tid);
                                    setOpen(false);
                                }, icon: React.createElement(SupportIcon, { size: "xs" }), selected: csrsPageActive }, MYB_ACCOUNTMENU_INBOX))) : null,
                        React.createElement("li", null,
                            React.createElement(MenuLink, { button: true, href: accountSettingsRouteHref, onClick: (event) => {
                                    event.preventDefault();
                                    handleSettingsClick();
                                    setOpen(false);
                                }, icon: React.createElement(SettingsIcon, { size: "xs" }), selected: settingsPageActive }, MYB_ACCOUNTMENU_SETTINGS)),
                        React.createElement(Divider, { thickness: 1, component: "li" }),
                        React.createElement("li", null,
                            React.createElement(MenuLink, { onClick: () => {
                                    signOut();
                                    setOpen(false);
                                }, icon: React.createElement(LogoutIcon, { size: "xs" }) }, MYB_ACCOUNTMENU_LOGOUT))))))));
}
