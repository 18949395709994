import { isEqual } from 'lodash';
import { TripType } from '../../contracts/types';
import { clearLastViewedEstab } from '../hotels';
export const clearLastSearch = () => {
    return typeof window !== 'undefined' && window.localStorage.removeItem('lastSearch');
};
export function setLastSearch(searchState) {
    if (searchState && typeof window !== 'undefined') {
        if (searchState.tripType !== TripType.Hotel && searchState.tripType !== TripType.Holiday)
            return;
        // Set the lastSearch in local storage to be the searchState specified, if one entry exists that will be
        // updated with new searchState.
        clearLastSearch();
        clearLastViewedEstab();
        window.localStorage.setItem('lastSearch', JSON.stringify(searchState));
    }
}
export const getLastSearch = () => {
    return typeof window !== 'undefined' && window.localStorage.getItem('lastSearch');
};
export function updateLastSearch(searchState) {
    const searchData = getLastSearch();
    if (!searchData) {
        setLastSearch(searchState);
        return;
    }
    // Parse previous search state, assuming it should be JSON
    let prevSearchState;
    try {
        prevSearchState = JSON.parse(searchData);
    }
    catch (_a) {
        // If JSON parsing fails, treat it as a change and update
        setLastSearch(searchState);
        return;
    }
    if (!isEqual(searchState, prevSearchState))
        setLastSearch(searchState);
}
export function setSearchResultCount(count) {
    if (typeof window !== 'undefined') {
        window.localStorage.setItem('SearchCount', count.toString());
    }
}
export function getSearchCount() {
    return typeof window !== 'undefined' && window.localStorage.getItem('SearchCount');
}
export function setSearchResult(hotels, tripStateId) {
    var _a;
    if (typeof window !== 'undefined') {
        const searchResult = window.localStorage.getItem('SearchResult');
        const shouldPersist = !searchResult || ((_a = JSON.parse(searchResult)) === null || _a === void 0 ? void 0 : _a.tripStateId) !== tripStateId;
        if (shouldPersist) {
            const searchData = getLastSearch();
            let searchState = {};
            try {
                searchState = JSON.parse(searchData || '{}');
            }
            catch (error) {
                console.error('Error parsing search data:', error);
            }
            window.localStorage.setItem('SearchResult', JSON.stringify({ hotels, tripStateId, searchState }));
        }
    }
}
export function getSearchResult(tripStateId) {
    if (typeof window !== 'undefined') {
        const searchResultJson = window.localStorage.getItem('SearchResult');
        const searchResult = searchResultJson ? JSON.parse(searchResultJson) : undefined;
        if ((searchResult === null || searchResult === void 0 ? void 0 : searchResult.tripStateId) === tripStateId)
            return searchResult.hotels;
    }
    return undefined;
}
export function getTypedLastSearch() {
    const searchResult = typeof window !== 'undefined' && window.localStorage.getItem('SearchResult');
    let searchDetails = {};
    try {
        const parsedSearchResult = JSON.parse(searchResult || '{}');
        searchDetails = parsedSearchResult.searchState;
        return searchDetails;
    }
    catch (error) {
        console.error('Error parsing search data:', error);
        return null;
    }
}
