export var ResourceStringsKey;
(function (ResourceStringsKey) {
    ResourceStringsKey["HC_MASTHEAD_ATOLPROTECTION_DESC"] = "HC_MASTHEAD_ATOLPROTECTION_DESC";
    ResourceStringsKey["HC_MASTHEAD_ATOLPROTECTION_TITLE"] = "HC_MASTHEAD_ATOLPROTECTION_TITLE";
    ResourceStringsKey["HC_CALLTOBOOKTELEPHONENUMBER"] = "HC_CALLTOBOOKTELEPHONENUMBER";
    ResourceStringsKey["HC_MASTHEAD_CALLTOBOOKCALLOUT"] = "HC_MASTHEAD_CALLTOBOOKCALLOUT";
    ResourceStringsKey["HC_SUPPORTCENTREURL"] = "HC_SUPPORTCENTREURL";
    ResourceStringsKey["HEADER_MENUBUTTON_LABEL"] = "HEADER_MENUBUTTON_LABEL";
    ResourceStringsKey["MAINMENU_HEADING"] = "MAINMENU_HEADING";
    ResourceStringsKey["DISCLAIMERINSURANCE"] = "DISCLAIMERINSURANCE";
    ResourceStringsKey["TERMS_OF_BUSINESS"] = "TERMS_OF_BUSINESS";
    ResourceStringsKey["FOOTER_DISCLAIMER_TEXT"] = "FOOTER_DISCLAIMER_TEXT";
    ResourceStringsKey["FOOTER_STANDARD_CANCELLATION_LINK"] = "FOOTER_STANDARD_CANCELLATION_LINK";
    ResourceStringsKey["FOOTER_CANCEL_TERMS"] = "FOOTER_CANCEL_TERMS";
    ResourceStringsKey["AGENCY_TERMS_HEADING"] = "AGENCY_TERMS_HEADING";
    ResourceStringsKey["TELEPHONENUMBER"] = "TELEPHONENUMBER";
    ResourceStringsKey["CALLUS"] = "CALLUS";
    ResourceStringsKey["COPYRIGHT"] = "COPYRIGHT";
    ResourceStringsKey["ADULT"] = "ADULT";
    ResourceStringsKey["ADULTS"] = "ADULTS";
    ResourceStringsKey["AGEOFCHILD"] = "AGEOFCHILD";
    ResourceStringsKey["AGEOFCHILDREN"] = "AGEOFCHILDREN";
    ResourceStringsKey["CHILD"] = "CHILD";
    ResourceStringsKey["CHILDREN"] = "CHILDREN";
    ResourceStringsKey["DATEPICKER_CHILD_VALIDATION_MSG"] = "DATEPICKER_CHILD_VALIDATION_MSG";
    ResourceStringsKey["RADIOPRICEFILTERUPTO"] = "RADIOPRICEFILTERUPTO";
    ResourceStringsKey["ROOM"] = "ROOM";
    ResourceStringsKey["ROOMS"] = "ROOMS";
    ResourceStringsKey["SEARCH_OCCUPANCY_FIELD_TITLE"] = "SEARCH_OCCUPANCY_FIELD_TITLE";
    ResourceStringsKey["SEARCH_OCCUPANCY_PLACEHOLDER"] = "SEARCH_OCCUPANCY_PLACEHOLDER";
    ResourceStringsKey["SEARCH_OCCUPANCY_POPUP_TITLE"] = "SEARCH_OCCUPANCY_POPUP_TITLE";
    ResourceStringsKey["SEARCH_OCCUPANCY_ROOM_TITLE"] = "SEARCH_OCCUPANCY_ROOM_TITLE";
    ResourceStringsKey["SEARCH_STEP_DONEBUTTONLABEL"] = "SEARCH_STEP_DONEBUTTONLABEL";
    ResourceStringsKey["YEARS"] = "YEARS";
    ResourceStringsKey["HC_GROUP_BOOKINGS_PROMPT"] = "HC_GROUP_BOOKINGS_PROMPT";
    ResourceStringsKey["SINGLENIGHT"] = "SINGLENIGHT";
    ResourceStringsKey["NIGHTS"] = "NIGHTS";
    ResourceStringsKey["HC_HOTEL_NIGHTS"] = "HC_HOTEL_NIGHTS";
    ResourceStringsKey["SEARCH_DATES_PLACEHOLDER"] = "SEARCH_DATES_PLACEHOLDER";
    ResourceStringsKey["SEARCH_DATES_ERROR_EMPTY"] = "SEARCH_DATES_ERROR_EMPTY";
    ResourceStringsKey["SEARCH_DATES_FIELD_TITLE"] = "SEARCH_DATES_FIELD_TITLE";
    ResourceStringsKey["SEARCH_DATES_POPUP_TITLE"] = "SEARCH_DATES_POPUP_TITLE";
    ResourceStringsKey["SEARCH_STEP_NEXTBUTTONLABEL"] = "SEARCH_STEP_NEXTBUTTONLABEL";
    ResourceStringsKey["SEARCH_FROMDATENAME_HOLIDAY"] = "SEARCH_FROMDATENAME_HOLIDAY";
    ResourceStringsKey["SEARCH_FROMDATENAME_HOTELONLY"] = "SEARCH_FROMDATENAME_HOTELONLY";
    ResourceStringsKey["SEARCH_TODATENAME_HOLIDAY"] = "SEARCH_TODATENAME_HOLIDAY";
    ResourceStringsKey["SEARCH_TODATENAME_HOTELONLY"] = "SEARCH_TODATENAME_HOTELONLY";
    ResourceStringsKey["SEARCH_DATES_ERROR_MISSINGDATE"] = "SEARCH_DATES_ERROR_MISSINGDATE";
    ResourceStringsKey["SEARCH_DATES_ERROR_MISSINGDATES"] = "SEARCH_DATES_ERROR_MISSINGDATES";
    ResourceStringsKey["SEARCH_DATES_MONTH_PROMPT"] = "SEARCH_DATES_MONTH_PROMPT";
    ResourceStringsKey["SEARCH_DATES_DATESELECTPROMPT"] = "SEARCH_DATES_DATESELECTPROMPT";
    ResourceStringsKey["SEARCH_DATES_RESETLINK_TEXT"] = "SEARCH_DATES_RESETLINK_TEXT";
    ResourceStringsKey["AUTOCOMPLETER_RESULTS_POPULAR"] = "AUTOCOMPLETER_RESULTS_POPULAR";
    ResourceStringsKey["HC_DESTINATIONS"] = "HC_DESTINATIONS";
    ResourceStringsKey["AUTOCOMPLETER_NORESULTS"] = "AUTOCOMPLETER_NORESULTS";
    ResourceStringsKey["SEARCH_DESTINATION_ERROR_EMPTY"] = "SEARCH_DESTINATION_ERROR_EMPTY";
    ResourceStringsKey["SEARCH_DESTINATION_PLACEHOLDER"] = "SEARCH_DESTINATION_PLACEHOLDER";
    ResourceStringsKey["HC_DESTINATION"] = "HC_DESTINATION";
    ResourceStringsKey["SEARCH_DESTINATION_POPUP_TITLE"] = "SEARCH_DESTINATION_POPUP_TITLE";
    ResourceStringsKey["HC_HOTELS"] = "HC_HOTELS";
    ResourceStringsKey["HC_TOP_SELLERS"] = "HC_TOP_SELLERS";
    ResourceStringsKey["HC_RECENT_SEARCHES"] = "HC_RECENT_SEARCHES";
    ResourceStringsKey["SEARCH_DEPARTURE_PLACEHOLDER"] = "SEARCH_DEPARTURE_PLACEHOLDER";
    ResourceStringsKey["SEARCH_DEPARTURE_ERROR_EMPTY"] = "SEARCH_DEPARTURE_ERROR_EMPTY";
    ResourceStringsKey["SEARCH_DEPARTURE_POPUP_TITLE"] = "SEARCH_DEPARTURE_POPUP_TITLE";
    ResourceStringsKey["FLYINGFROMLABEL"] = "FLYINGFROMLABEL";
    ResourceStringsKey["SEARCHBUTTONTEXT"] = "SEARCHBUTTONTEXT";
    ResourceStringsKey["HC_LOADING"] = "HC_LOADING";
    ResourceStringsKey["FUNNEL_BREADCRUMB_HOTEL"] = "FUNNEL_BREADCRUMB_HOTEL";
    ResourceStringsKey["FUNNEL_BREADCRUMB_ROOMS"] = "FUNNEL_BREADCRUMB_ROOMS";
    ResourceStringsKey["FUNNEL_BREADCRUMB_ROOMS_FLIGHTS"] = "FUNNEL_BREADCRUMB_ROOMS_FLIGHTS";
    ResourceStringsKey["FUNNEL_BREADCRUMB_STAY"] = "FUNNEL_BREADCRUMB_STAY";
    ResourceStringsKey["FUNNEL_BREADCRUMB_BOOK"] = "FUNNEL_BREADCRUMB_BOOK";
    ResourceStringsKey["HC_BREADCRUMB_GUEST_INFORMATION"] = "HC_BREADCRUMB_GUEST_INFORMATION";
    ResourceStringsKey["HC_BREADCRUMB_PAYMENT"] = "HC_BREADCRUMB_PAYMENT";
    ResourceStringsKey["FUNNEL_BREADCRUMB_EXTRAS"] = "FUNNEL_BREADCRUMB_EXTRAS";
    ResourceStringsKey["HC_SECURETODAYFROM"] = "HC_SECURETODAYFROM";
    ResourceStringsKey["PAYLOWDEPOSIT"] = "PAYLOWDEPOSIT";
    ResourceStringsKey["SECURE_TODAY_AND_PAY_BY"] = "SECURE_TODAY_AND_PAY_BY";
    ResourceStringsKey["DEPOSITFEE"] = "DEPOSITFEE";
    ResourceStringsKey["CITYTAXDESCRIPTION"] = "CITYTAXDESCRIPTION";
    ResourceStringsKey["HC_HOTELLOCALCHARGES"] = "HC_HOTELLOCALCHARGES";
    ResourceStringsKey["HC_PRICEINCLUDES"] = "HC_PRICEINCLUDES";
    ResourceStringsKey["LOCALCHARGES_DESCRIPTION"] = "LOCALCHARGES_DESCRIPTION";
    ResourceStringsKey["LOCALCHARGES_HEADING"] = "LOCALCHARGES_HEADING";
    ResourceStringsKey["LOCALCHARGES"] = "LOCALCHARGES";
    ResourceStringsKey["NIGHT"] = "NIGHT";
    ResourceStringsKey["RETURNFLIGHTS"] = "RETURNFLIGHTS";
    ResourceStringsKey["TRANSFER_INCLUSION_MESSAGE"] = "TRANSFER_INCLUSION_MESSAGE";
    ResourceStringsKey["SPREADTHECOST"] = "SPREADTHECOST";
    ResourceStringsKey["ANDTHEN"] = "ANDTHEN";
    ResourceStringsKey["EQUALPAYMENTS"] = "EQUALPAYMENTS";
    ResourceStringsKey["PAYMENTOPTIONFROM_MONTHLY"] = "PAYMENTOPTIONFROM_MONTHLY";
    ResourceStringsKey["PAYMENTOPTION_MONTHLY"] = "PAYMENTOPTION_MONTHLY";
    ResourceStringsKey["MONTHLYPAYMENTFEE"] = "MONTHLYPAYMENTFEE";
    ResourceStringsKey["NOHOTELDESC"] = "NOHOTELDESC";
    ResourceStringsKey["NOTAVL"] = "NOTAVL";
    ResourceStringsKey["SEARCHALTDATES"] = "SEARCHALTDATES";
    ResourceStringsKey["HOTEL_SPONSORED_LABEL"] = "HOTEL_SPONSORED_LABEL";
    ResourceStringsKey["HOTEL_TOTALPRICEFROM"] = "HOTEL_TOTALPRICEFROM";
    ResourceStringsKey["PERPERSON"] = "PERPERSON";
    ResourceStringsKey["VIEWMOREDETAILS"] = "VIEWMOREDETAILS";
    ResourceStringsKey["VIEWMORE"] = "VIEWMORE";
    ResourceStringsKey["HOLIDAYPRICEFROM"] = "HOLIDAYPRICEFROM";
    ResourceStringsKey["HOTEL_REVIEW_CUSTOMERCHECKEDIN"] = "HOTEL_REVIEW_CUSTOMERCHECKEDIN";
    ResourceStringsKey["HOTEL_REVIEWS_TEXTCROPPER_CAPTION_CROPPED"] = "HOTEL_REVIEWS_TEXTCROPPER_CAPTION_CROPPED";
    ResourceStringsKey["HOTEL_REVIEWS_TEXTCROPPER_CAPTION_EXPANDED"] = "HOTEL_REVIEWS_TEXTCROPPER_CAPTION_EXPANDED";
    ResourceStringsKey["HOTEL_OVERVIEW_FACILITIES"] = "HOTEL_OVERVIEW_FACILITIES";
    ResourceStringsKey["FROM"] = "FROM";
    ResourceStringsKey["HOTELSTABOVERVIEW"] = "HOTELSTABOVERVIEW";
    ResourceStringsKey["HOTELINFORMATION"] = "HOTELINFORMATION";
    ResourceStringsKey["SHOW_LESS"] = "SHOW_LESS";
    ResourceStringsKey["SHOW_MORE"] = "SHOW_MORE";
    ResourceStringsKey["HOTEL_OVERVIEWTAB_OURCUSTOMERREVIEWS"] = "HOTEL_OVERVIEWTAB_OURCUSTOMERREVIEWS";
    ResourceStringsKey["HOTEL_OVERVIEWTAB_SEEALLCUSTOMERREVIEWS"] = "HOTEL_OVERVIEWTAB_SEEALLCUSTOMERREVIEWS";
    ResourceStringsKey["HOTEL_REVIEWS_NOREVIEWS"] = "HOTEL_REVIEWS_NOREVIEWS";
    ResourceStringsKey["LOCALCHARGES_INCLUDED"] = "LOCALCHARGES_INCLUDED";
    ResourceStringsKey["ROOMCOSTREFUNDABLE"] = "ROOMCOSTREFUNDABLE";
    ResourceStringsKey["ROOMCOSTNONREFUNDABLE"] = "ROOMCOSTNONREFUNDABLE";
    ResourceStringsKey["PRICE_SELECTED"] = "PRICE_SELECTED";
    ResourceStringsKey["TOTAL_PRICE"] = "TOTAL_PRICE";
    ResourceStringsKey["HOTELDETAILS_PERPERSONPRICE"] = "HOTELDETAILS_PERPERSONPRICE";
    ResourceStringsKey["ROOMPRICE"] = "ROOMPRICE";
    ResourceStringsKey["ALLBOARDTYPES"] = "ALLBOARDTYPES";
    ResourceStringsKey["BOARDOPTIONS_FILTERLABEL"] = "BOARDOPTIONS_FILTERLABEL";
    ResourceStringsKey["ROOMINFOHEADER"] = "ROOMINFOHEADER";
    ResourceStringsKey["HOTELDETAILS_TOTALHOLIDAYPRICE"] = "HOTELDETAILS_TOTALHOLIDAYPRICE";
    ResourceStringsKey["HOTELDETAILS_TOTALHOTELPRICE"] = "HOTELDETAILS_TOTALHOTELPRICE";
    ResourceStringsKey["PERPERSONPRICE"] = "PERPERSONPRICE";
    ResourceStringsKey["CONFIRMROOMBUTTONTEXT"] = "CONFIRMROOMBUTTONTEXT";
    ResourceStringsKey["HC_CONFIRMANDCONTINUETOBOOK"] = "HC_CONFIRMANDCONTINUETOBOOK";
    ResourceStringsKey["CONTINUETOFLIGHTS"] = "CONTINUETOFLIGHTS";
    ResourceStringsKey["CONFIRM_TEXT_PREFIX"] = "CONFIRM_TEXT_PREFIX";
    ResourceStringsKey["BOARDSELECTION"] = "BOARDSELECTION";
    ResourceStringsKey["STEPPERROOMLABEL"] = "STEPPERROOMLABEL";
    ResourceStringsKey["STEPPERROOMSELECTION"] = "STEPPERROOMSELECTION";
    ResourceStringsKey["STEPPERSELECTEDROOM"] = "STEPPERSELECTEDROOM";
    ResourceStringsKey["HOTELROOMS_SOLDOUT_TITLE"] = "HOTELROOMS_SOLDOUT_TITLE";
    ResourceStringsKey["ROOMNAVL_FINDOTHERHOTELS"] = "ROOMNAVL_FINDOTHERHOTELS";
    ResourceStringsKey["HOTELROOMS_SOLDOUT_BODY"] = "HOTELROOMS_SOLDOUT_BODY";
    ResourceStringsKey["ROOMNAVL_TRYALTDATES"] = "ROOMNAVL_TRYALTDATES";
    ResourceStringsKey["FOR"] = "FOR";
    ResourceStringsKey["ROOMSFORADULTSLOWER"] = "ROOMSFORADULTSLOWER";
    ResourceStringsKey["ROOMSFORONEADULTLOWER"] = "ROOMSFORONEADULTLOWER";
    ResourceStringsKey["TOTALPRICE"] = "TOTALPRICE";
    ResourceStringsKey["HC_PREVIOUSSELECTION"] = "HC_PREVIOUSSELECTION";
    ResourceStringsKey["SELECTED"] = "SELECTED";
    ResourceStringsKey["HOTEL_REVIEW_SEEMOREREVIEWS"] = "HOTEL_REVIEW_SEEMOREREVIEWS";
    ResourceStringsKey["HC_PERPERSON"] = "HC_PERPERSON";
    ResourceStringsKey["PERPERSONPRICEFROM"] = "PERPERSONPRICEFROM";
    ResourceStringsKey["HC_TOTALPRICE"] = "HC_TOTALPRICE";
    ResourceStringsKey["ONEADULTLOWER"] = "ONEADULTLOWER";
    ResourceStringsKey["NADULTSLOWER"] = "NADULTSLOWER";
    ResourceStringsKey["CHILDLOWER"] = "CHILDLOWER";
    ResourceStringsKey["CHILDRENLOWER"] = "CHILDRENLOWER";
    ResourceStringsKey["CHANGEROOM"] = "CHANGEROOM";
    ResourceStringsKey["TOTALCOST"] = "TOTALCOST";
    ResourceStringsKey["ROOMSUMMARYBANNER_SUMMARYBUTTON"] = "ROOMSUMMARYBANNER_SUMMARYBUTTON";
    ResourceStringsKey["TOTAL"] = "TOTAL";
    ResourceStringsKey["ROOMSELECTION"] = "ROOMSELECTION";
    ResourceStringsKey["HOTELTABS_CHOOSEROOMS"] = "HOTELTABS_CHOOSEROOMS";
    ResourceStringsKey["HOTELTABS_CHOOSEROOM"] = "HOTELTABS_CHOOSEROOM";
    ResourceStringsKey["FUNNEL_BREADCRUMB_FLIGHTS"] = "FUNNEL_BREADCRUMB_FLIGHTS";
    ResourceStringsKey["CUSTOMISEMESSAGE"] = "CUSTOMISEMESSAGE";
    ResourceStringsKey["FLIGHT_DETAILS_MODAL_TITLE"] = "FLIGHT_DETAILS_MODAL_TITLE";
    ResourceStringsKey["RETURN"] = "RETURN";
    ResourceStringsKey["OPERATED_BY_CARRIER_PROMPT"] = "OPERATED_BY_CARRIER_PROMPT";
    ResourceStringsKey["HC_OUTBOUND"] = "HC_OUTBOUND";
    ResourceStringsKey["HC_DIRECT"] = "HC_DIRECT";
    ResourceStringsKey["HC_STOP"] = "HC_STOP";
    ResourceStringsKey["HCSTOPS"] = "HCSTOPS";
    ResourceStringsKey["HC_LAYOVER"] = "HC_LAYOVER";
    ResourceStringsKey["FLIGHT_DETAILS_INFO_HEADING"] = "FLIGHT_DETAILS_INFO_HEADING";
    ResourceStringsKey["HOLDLUGGAGEAMOUNT"] = "HOLDLUGGAGEAMOUNT";
    ResourceStringsKey["OPENJAWMESSAGE"] = "OPENJAWMESSAGE";
    ResourceStringsKey["HOTEL_DETAILS_YOUR_ROOM_OPTIONS"] = "HOTEL_DETAILS_YOUR_ROOM_OPTIONS";
    ResourceStringsKey["NOROOMAVAILABILITYMESSAGE"] = "NOROOMAVAILABILITYMESSAGE";
    ResourceStringsKey["OURCUSTOMERRATING"] = "OURCUSTOMERRATING";
    ResourceStringsKey["EXTENDEDFLIGHTSELECTIONNIGHTSNOTIFICATIONTEXT"] = "EXTENDEDFLIGHTSELECTIONNIGHTSNOTIFICATIONTEXT";
    ResourceStringsKey["NORESULTS_MATCHING_FILTERS_HOTELS_SUB"] = "NORESULTS_MATCHING_FILTERS_HOTELS_SUB";
    ResourceStringsKey["NORESULTS_MATCHING_FILTERS_MAIN"] = "NORESULTS_MATCHING_FILTERS_MAIN";
    ResourceStringsKey["RESETFILTERSBUTTON_CAPTION"] = "RESETFILTERSBUTTON_CAPTION";
    ResourceStringsKey["SEARCHALTDATESBUTTON_CAPTION"] = "SEARCHALTDATESBUTTON_CAPTION";
    ResourceStringsKey["LOOK_HOTEL_ONLY"] = "LOOK_HOTEL_ONLY";
    ResourceStringsKey["HOTELS_PAGE_TITLE_HOLIDAY"] = "HOTELS_PAGE_TITLE_HOLIDAY";
    ResourceStringsKey["HOTELS_PAGE_TITLE_HOTEL"] = "HOTELS_PAGE_TITLE_HOTEL";
    ResourceStringsKey["HOTELS_PAGE_TITLE_HOLIDAY_LOADING"] = "HOTELS_PAGE_TITLE_HOLIDAY_LOADING";
    ResourceStringsKey["HOTELS_PAGE_TITLE_HOTEL_LOADING"] = "HOTELS_PAGE_TITLE_HOTEL_LOADING";
    ResourceStringsKey["HC_XMATCHES"] = "HC_XMATCHES";
    ResourceStringsKey["HC_NOMATCHES"] = "HC_NOMATCHES";
    ResourceStringsKey["SORTBY"] = "SORTBY";
    ResourceStringsKey["RECOMMENDED"] = "RECOMMENDED";
    ResourceStringsKey["PRICECHEAPESTFIRST"] = "PRICECHEAPESTFIRST";
    ResourceStringsKey["CUSTOMERRATINGHIGHESTFIRST"] = "CUSTOMERRATINGHIGHESTFIRST";
    ResourceStringsKey["STARRATINGHIGHESTFIRST"] = "STARRATINGHIGHESTFIRST";
    ResourceStringsKey["PRICENOBRACKETS"] = "PRICENOBRACKETS";
    ResourceStringsKey["CUSTOMERRATINGNOBRACKETS"] = "CUSTOMERRATINGNOBRACKETS";
    ResourceStringsKey["STARRATINGNOBRACKETS"] = "STARRATINGNOBRACKETS";
    ResourceStringsKey["DURATIONQUICKESTFIRST"] = "DURATIONQUICKESTFIRST";
    ResourceStringsKey["DURATIONNOBRACKETS"] = "DURATIONNOBRACKETS";
    ResourceStringsKey["NORESULTS_MATCHING_FILTERS_FLIGHTS_SUB"] = "NORESULTS_MATCHING_FILTERS_FLIGHTS_SUB";
    ResourceStringsKey["PRICEPROMISEPAGE"] = "PRICEPROMISEPAGE";
    ResourceStringsKey["FLIGHTFINANCIALPROTECTIONMESSAGEHOLIDAY"] = "FLIGHTFINANCIALPROTECTIONMESSAGEHOLIDAY";
    ResourceStringsKey["POPUP_ATOL_PROTECTED"] = "POPUP_ATOL_PROTECTED";
    ResourceStringsKey["BOOKWITHCONFIDENCEHEADER"] = "BOOKWITHCONFIDENCEHEADER";
    ResourceStringsKey["POPUPABTACONTENT"] = "POPUPABTACONTENT";
    ResourceStringsKey["MENUPRICEGUARANTEE"] = "MENUPRICEGUARANTEE";
    ResourceStringsKey["SEARCH_USP_1"] = "SEARCH_USP_1";
    ResourceStringsKey["SEARCH_USP_2"] = "SEARCH_USP_2";
    ResourceStringsKey["SEARCH_USP_3"] = "SEARCH_USP_3";
    ResourceStringsKey["HOTELS_LOADMORE"] = "HOTELS_LOADMORE";
    ResourceStringsKey["SRP_FILTERBUTTON_LABEL_MOBILE"] = "SRP_FILTERBUTTON_LABEL_MOBILE";
    ResourceStringsKey["SRP_FILTERBUTTON_LABEL"] = "SRP_FILTERBUTTON_LABEL";
    ResourceStringsKey["MODAL_FILTER_RESULTS_TITLE"] = "MODAL_FILTER_RESULTS_TITLE";
    ResourceStringsKey["VIEWMATCHES"] = "VIEWMATCHES";
    ResourceStringsKey["SRP_MAPBUTTON_LABEL"] = "SRP_MAPBUTTON_LABEL";
    ResourceStringsKey["SPONSOREDHOTELSMESSAGE"] = "SPONSOREDHOTELSMESSAGE";
    ResourceStringsKey["MAPLINKTEXT"] = "MAPLINKTEXT";
    ResourceStringsKey["BOOKING_HOTELINFORMATION_CROPPEDCAPTION"] = "BOOKING_HOTELINFORMATION_CROPPEDCAPTION";
    ResourceStringsKey["IMPORTANTINFORMATION"] = "IMPORTANTINFORMATION";
    ResourceStringsKey["BOOKING_PAYMENTOPTIONS_METHODSHEADING"] = "BOOKING_PAYMENTOPTIONS_METHODSHEADING";
    ResourceStringsKey["PAYMENTMETHOD_CARD"] = "PAYMENTMETHOD_CARD";
    ResourceStringsKey["PAYMENTMETHOD_PAYPAL"] = "PAYMENTMETHOD_PAYPAL";
    ResourceStringsKey["PAYMENTMETHOD_APPLEPAY"] = "PAYMENTMETHOD_APPLEPAY";
    ResourceStringsKey["PAYMENTMETHOD_GPAY"] = "PAYMENTMETHOD_GPAY";
    ResourceStringsKey["LOCALCHARGES_EXCHANGERATE_WARNING"] = "LOCALCHARGES_EXCHANGERATE_WARNING";
    ResourceStringsKey["LOCALCHARGES_EXCHANGERATE_INFO"] = "LOCALCHARGES_EXCHANGERATE_INFO";
    ResourceStringsKey["ADMINFEESLABEL"] = "ADMINFEESLABEL";
    ResourceStringsKey["ADMINFEEADVICE"] = "ADMINFEEADVICE";
    ResourceStringsKey["ADMINFEEMOREINFOLINK"] = "ADMINFEEMOREINFOLINK";
    ResourceStringsKey["ADMINFEEMOREINFOTITLE"] = "ADMINFEEMOREINFOTITLE";
    ResourceStringsKey["ADMINFEEMOREINFOCONTENTWITHVALUE"] = "ADMINFEEMOREINFOCONTENTWITHVALUE";
    ResourceStringsKey["DIALOG_DISMISSLINK"] = "DIALOG_DISMISSLINK";
    ResourceStringsKey["BOOKING_PAYMENTOPTIONS_OPTIONSHEADING"] = "BOOKING_PAYMENTOPTIONS_OPTIONSHEADING";
    ResourceStringsKey["PAYMENTOPTION_DEPOSIT"] = "PAYMENTOPTION_DEPOSIT";
    ResourceStringsKey["BOOKING_PAYMENTOPTIONS_PAYTODAY"] = "BOOKING_PAYMENTOPTIONS_PAYTODAY";
    ResourceStringsKey["PAYMENTOPTION_FULL"] = "PAYMENTOPTION_FULL";
    ResourceStringsKey["BOOKING_PAYMENTOPTIONS_DEPOSIT_TOTAL_LABEL"] = "BOOKING_PAYMENTOPTIONS_DEPOSIT_TOTAL_LABEL";
    ResourceStringsKey["PAYINFULL_PROMPT"] = "PAYINFULL_PROMPT";
    ResourceStringsKey["PAYINFULL_TIP"] = "PAYINFULL_TIP";
    ResourceStringsKey["PRIVACYPAGE"] = "PRIVACYPAGE";
    ResourceStringsKey["PASSENGERSFORMPRIVACYPOLICYMODALTITLE"] = "PASSENGERSFORMPRIVACYPOLICYMODALTITLE";
    ResourceStringsKey["BOOKINGSUMMARY_NOTIFICATION_BODY"] = "BOOKINGSUMMARY_NOTIFICATION_BODY";
    ResourceStringsKey["BOOKINGSUMMARY_NOTIFICATION_HEADER"] = "BOOKINGSUMMARY_NOTIFICATION_HEADER";
    ResourceStringsKey["ROOMNUMBER"] = "ROOMNUMBER";
    ResourceStringsKey["TRANSFERSH1"] = "TRANSFERSH1";
    ResourceStringsKey["TRANSFERTOTAL"] = "TRANSFERTOTAL";
    ResourceStringsKey["BOOKING_TRIPDETAILS_CAPTION_ADMINFEE"] = "BOOKING_TRIPDETAILS_CAPTION_ADMINFEE";
    ResourceStringsKey["BOOKING_TRIPDETAILS_CAPTION_TOTAL"] = "BOOKING_TRIPDETAILS_CAPTION_TOTAL";
    ResourceStringsKey["BOOKING_TRIPDETAILS_CAPTION_HOTEL_PRICE"] = "BOOKING_TRIPDETAILS_CAPTION_HOTEL_PRICE";
    ResourceStringsKey["BOOKING_TRIPDETAILS_CAPTION_DISCOUNT"] = "BOOKING_TRIPDETAILS_CAPTION_DISCOUNT";
    ResourceStringsKey["HOTELFEES_CAPTION"] = "HOTELFEES_CAPTION";
    ResourceStringsKey["BOOKING_TRIPDETAILS_CAPTION_DEPOSIT"] = "BOOKING_TRIPDETAILS_CAPTION_DEPOSIT";
    ResourceStringsKey["BOOKING_TRIPDETAILS_CAPTION_DEPOSIT_SECURE"] = "BOOKING_TRIPDETAILS_CAPTION_DEPOSIT_SECURE";
    ResourceStringsKey["BOOKING_TRIPDETAILS_CAPTION_BALANCE"] = "BOOKING_TRIPDETAILS_CAPTION_BALANCE";
    ResourceStringsKey["ATOLPROTECTION"] = "ATOLPROTECTION";
    ResourceStringsKey["HOLIDAY_PACKAGE_PRICE"] = "HOLIDAY_PACKAGE_PRICE";
    ResourceStringsKey["COVID_PROTECTION_COVER_TITLE"] = "COVID_PROTECTION_COVER_TITLE";
    ResourceStringsKey["BOOKING_TRIPDETAILS_CAPTION_TOTAL_HOLIDAY"] = "BOOKING_TRIPDETAILS_CAPTION_TOTAL_HOLIDAY";
    ResourceStringsKey["HOTELFEES_DESCRIPTION"] = "HOTELFEES_DESCRIPTION";
    ResourceStringsKey["HOTELFEES_HEADING"] = "HOTELFEES_HEADING";
    ResourceStringsKey["BOOKING_CAPTION_BALANCE"] = "BOOKING_CAPTION_BALANCE";
    ResourceStringsKey["BAGGAGE"] = "BAGGAGE";
    ResourceStringsKey["BAGGAGE_BAGS_TOTAL"] = "BAGGAGE_BAGS_TOTAL";
    ResourceStringsKey["INSURANCE"] = "INSURANCE";
    ResourceStringsKey["INSURANCETOTAL"] = "INSURANCETOTAL";
    ResourceStringsKey["BOOKINGSUMMARY_HEADING_FLIGHTS"] = "BOOKINGSUMMARY_HEADING_FLIGHTS";
    ResourceStringsKey["FLIGHTLISTINGDETAILSLINKCAPTION"] = "FLIGHTLISTINGDETAILSLINKCAPTION";
    ResourceStringsKey["BAGGAGEFLIGHTHAND"] = "BAGGAGEFLIGHTHAND";
    ResourceStringsKey["FLIGHT_SUMMARY_INCLUDES"] = "FLIGHT_SUMMARY_INCLUDES";
    ResourceStringsKey["HC_RETURN_TICKET"] = "HC_RETURN_TICKET";
    ResourceStringsKey["HC_ONEWAY_TICKET"] = "HC_ONEWAY_TICKET";
    ResourceStringsKey["BOOKINGSUMMARY"] = "BOOKINGSUMMARY";
    ResourceStringsKey["EMAILTHISHOTELHEADING"] = "EMAILTHISHOTELHEADING";
    ResourceStringsKey["EMAILTHISHOLIDAYHEADING"] = "EMAILTHISHOLIDAYHEADING";
    ResourceStringsKey["SENDBESTDEALS"] = "SENDBESTDEALS";
    ResourceStringsKey["BOOKING_EMAILFIELD_ERROR_NOTANEMAIL"] = "BOOKING_EMAILFIELD_ERROR_NOTANEMAIL";
    ResourceStringsKey["HOLIDAYQUOTESEND"] = "HOLIDAYQUOTESEND";
    ResourceStringsKey["ERRORGENERICTITLE"] = "ERRORGENERICTITLE";
    ResourceStringsKey["HOLIDAYQUOTESENT"] = "HOLIDAYQUOTESENT";
    ResourceStringsKey["BOOKING_HELPFULFOOTER_TEXT"] = "BOOKING_HELPFULFOOTER_TEXT";
    ResourceStringsKey["BOOKING_HELPFULFOOTER_PROMPT"] = "BOOKING_HELPFULFOOTER_PROMPT";
    ResourceStringsKey["BOOKING_HELPFULFOOTER_SUPPORTLINK"] = "BOOKING_HELPFULFOOTER_SUPPORTLINK";
    ResourceStringsKey["BOOKING_CONTACTDETAILSHEADING"] = "BOOKING_CONTACTDETAILSHEADING";
    ResourceStringsKey["BOOKING_CONTACTDETAILSSUBHEADING"] = "BOOKING_CONTACTDETAILSSUBHEADING";
    ResourceStringsKey["BOOKING_PHONEFIELD_LABEL"] = "BOOKING_PHONEFIELD_LABEL";
    ResourceStringsKey["BOOKING_PHONEFIELD_ERROR_EMPTY"] = "BOOKING_PHONEFIELD_ERROR_EMPTY";
    ResourceStringsKey["BOOKING_PHONEFIELD_ERROR_INVALID"] = "BOOKING_PHONEFIELD_ERROR_INVALID";
    ResourceStringsKey["BOOKING_EMAILFIELD_LABEL"] = "BOOKING_EMAILFIELD_LABEL";
    ResourceStringsKey["BOOKING_EMAILFIELD_ERROR_EMPTY"] = "BOOKING_EMAILFIELD_ERROR_EMPTY";
    ResourceStringsKey["BOOKING_SPECIALREQUESTSHEADING"] = "BOOKING_SPECIALREQUESTSHEADING";
    ResourceStringsKey["BOOKING_SPECIALREQUESTSFIELD_LABEL"] = "BOOKING_SPECIALREQUESTSFIELD_LABEL";
    ResourceStringsKey["ADDDISCOUNTINPUTLABEL"] = "ADDDISCOUNTINPUTLABEL";
    ResourceStringsKey["ADDDISCOUNTBLOCKTITLE"] = "ADDDISCOUNTBLOCKTITLE";
    ResourceStringsKey["ADDDISCOUNTAPPLYBUTTON"] = "ADDDISCOUNTAPPLYBUTTON";
    ResourceStringsKey["ADDDISCOUNTREMOVEBUTTON"] = "ADDDISCOUNTREMOVEBUTTON";
    ResourceStringsKey["VOUCHER_CODE_EXPIRED"] = "VOUCHER_CODE_EXPIRED";
    ResourceStringsKey["VOUCHER_CODE_APPLIED"] = "VOUCHER_CODE_APPLIED";
    ResourceStringsKey["VOUCHER_CODE_REMOVAL_MSG"] = "VOUCHER_CODE_REMOVAL_MSG";
    ResourceStringsKey["INFANT"] = "INFANT";
    ResourceStringsKey["BOOKING_CHILDAGEPROMPT_XYEARS"] = "BOOKING_CHILDAGEPROMPT_XYEARS";
    ResourceStringsKey["BOOKING_CHILDAGEPROMPT_0YEARS"] = "BOOKING_CHILDAGEPROMPT_0YEARS";
    ResourceStringsKey["BOOKING_CHILDAGEPROMPT_1YEAR"] = "BOOKING_CHILDAGEPROMPT_1YEAR";
    ResourceStringsKey["BOOKING_TITLEFIELD_LABEL"] = "BOOKING_TITLEFIELD_LABEL";
    ResourceStringsKey["BOOKING_TITLEFIELD_ERROR_EMPTY"] = "BOOKING_TITLEFIELD_ERROR_EMPTY";
    ResourceStringsKey["DROPDOWN_TITLE_DEFAULT"] = "DROPDOWN_TITLE_DEFAULT";
    ResourceStringsKey["ADULTTITLES"] = "ADULTTITLES";
    ResourceStringsKey["BOOKING_LEADPAXHEADING_HOTEL"] = "BOOKING_LEADPAXHEADING_HOTEL";
    ResourceStringsKey["CHILDTITLES"] = "CHILDTITLES";
    ResourceStringsKey["BOOKING_NAMEFIELD_LABEL"] = "BOOKING_NAMEFIELD_LABEL";
    ResourceStringsKey["BOOKING_FIRST_NAMEFIELD_ERROR_EMPTY"] = "BOOKING_FIRST_NAMEFIELD_ERROR_EMPTY";
    ResourceStringsKey["BOOKING_NAMEFIELD_ERROR_INVALID"] = "BOOKING_NAMEFIELD_ERROR_INVALID";
    ResourceStringsKey["BOOKING_SURNAMEFIELD_LABEL"] = "BOOKING_SURNAMEFIELD_LABEL";
    ResourceStringsKey["BOOKING_SURNAMEFIELD_ERROR_EMPTY"] = "BOOKING_SURNAMEFIELD_ERROR_EMPTY";
    ResourceStringsKey["BOOKING_DOBFIELD_ERROR_CHILD"] = "BOOKING_DOBFIELD_ERROR_CHILD";
    ResourceStringsKey["BOOKING_DOBFIELD_ERROR_INVALID"] = "BOOKING_DOBFIELD_ERROR_INVALID";
    ResourceStringsKey["BOOKING_DOBFIELD_ERROR_EMPTY"] = "BOOKING_DOBFIELD_ERROR_EMPTY";
    ResourceStringsKey["BOOKING_DOBFIELD_ERROR_FUTURE"] = "BOOKING_DOBFIELD_ERROR_FUTURE";
    ResourceStringsKey["BOOKING_DOBFIELD_LABEL"] = "BOOKING_DOBFIELD_LABEL";
    ResourceStringsKey["BOOKING_DOB_FIELD_ERROR_ADULT"] = "BOOKING_DOB_FIELD_ERROR_ADULT";
    ResourceStringsKey["BOOKING_CONTACT_GROUP_ERROR"] = "BOOKING_CONTACT_GROUP_ERROR";
    ResourceStringsKey["BOOKING_NAME_GROUP_ERROR_SINGLE"] = "BOOKING_NAME_GROUP_ERROR_SINGLE";
    ResourceStringsKey["BOOKING_NAME_GROUP_ERROR_MULTI"] = "BOOKING_NAME_GROUP_ERROR_MULTI";
    ResourceStringsKey["BOOKING_ERROR_PROMPT"] = "BOOKING_ERROR_PROMPT";
    ResourceStringsKey["BOOKING_NAME_ERROR_PROMPT_ADDON"] = "BOOKING_NAME_ERROR_PROMPT_ADDON";
    ResourceStringsKey["CARDHOLDER_DETAILS_VALIDATION"] = "CARDHOLDER_DETAILS_VALIDATION";
    ResourceStringsKey["SECURE_PAYMENT_DETAILS_VALIDATION"] = "SECURE_PAYMENT_DETAILS_VALIDATION";
    ResourceStringsKey["HC_DETAILS_ADULTS"] = "HC_DETAILS_ADULTS";
    ResourceStringsKey["HC_DETAILS_CHILD"] = "HC_DETAILS_CHILD";
    ResourceStringsKey["HC_DETAILS_INFANT"] = "HC_DETAILS_INFANT";
    ResourceStringsKey["BOOKING_PASSPORTMATCHTITLE"] = "BOOKING_PASSPORTMATCHTITLE";
    ResourceStringsKey["BOOKING_PASSPORTMATCHPROMPT"] = "BOOKING_PASSPORTMATCHPROMPT";
    ResourceStringsKey["BOOKING_PROMOTIONAL_OPT_IN"] = "BOOKING_PROMOTIONAL_OPT_IN";
    ResourceStringsKey["BOOKING_GUESTINFO_SUBMIT_BUTTON"] = "BOOKING_GUESTINFO_SUBMIT_BUTTON";
    ResourceStringsKey["BOOKING_SECURE_CHECKOUT_PROMPT"] = "BOOKING_SECURE_CHECKOUT_PROMPT";
    ResourceStringsKey["BOOKING_STEP_ONE"] = "BOOKING_STEP_ONE";
    ResourceStringsKey["BOOKING_STEP_THREE"] = "BOOKING_STEP_THREE";
    ResourceStringsKey["BOOKING_STEP_ONE_MOBILE"] = "BOOKING_STEP_ONE_MOBILE";
    ResourceStringsKey["BOOKING_STEP_THREE_MOBILE"] = "BOOKING_STEP_THREE_MOBILE";
    ResourceStringsKey["EDITABLE_NAME_TEXT"] = "EDITABLE_NAME_TEXT";
    ResourceStringsKey["PAYMENT_AUTOCOMPLETER_SEARCH_PLACEHOLDER"] = "PAYMENT_AUTOCOMPLETER_SEARCH_PLACEHOLDER";
    ResourceStringsKey["ADDRESSLOOKUP_MANUALADDRESS_LINK"] = "ADDRESSLOOKUP_MANUALADDRESS_LINK";
    ResourceStringsKey["BOOKING_COUNTRYFIELD_ERROR_EMPTY"] = "BOOKING_COUNTRYFIELD_ERROR_EMPTY";
    ResourceStringsKey["BOOKING_ADDRESSFIELD_LABEL"] = "BOOKING_ADDRESSFIELD_LABEL";
    ResourceStringsKey["BOOKING_CITYFIELD_LABEL"] = "BOOKING_CITYFIELD_LABEL";
    ResourceStringsKey["BOOKING_POSTCODEFIELD_LABEL"] = "BOOKING_POSTCODEFIELD_LABEL";
    ResourceStringsKey["BOOKING_COUNTRYFIELD_LABEL"] = "BOOKING_COUNTRYFIELD_LABEL";
    ResourceStringsKey["ADDRESSLOOKUP_SEARCHAGAIN_LINK"] = "ADDRESSLOOKUP_SEARCHAGAIN_LINK";
    ResourceStringsKey["ADDRESSLOOKUP_HEADING"] = "ADDRESSLOOKUP_HEADING";
    ResourceStringsKey["BOOKING_ADDRESSFIELD_ERROR_EMPTY"] = "BOOKING_ADDRESSFIELD_ERROR_EMPTY";
    ResourceStringsKey["BOOKING_CITYFIELD_ERROR_EMPTY"] = "BOOKING_CITYFIELD_ERROR_EMPTY";
    ResourceStringsKey["BOOKING_POSTCODEFIELD_ERROR_EMPTY"] = "BOOKING_POSTCODEFIELD_ERROR_EMPTY";
    ResourceStringsKey["BOOKING_ADDRESSEDIT_PROMPT"] = "BOOKING_ADDRESSEDIT_PROMPT";
    ResourceStringsKey["EDIT_CARDHOLDERS_DETAILS"] = "EDIT_CARDHOLDERS_DETAILS";
    ResourceStringsKey["NOT_FOUND_MESSAGE"] = "NOT_FOUND_MESSAGE";
    ResourceStringsKey["ADDRESS_FIELD"] = "ADDRESS_FIELD";
    ResourceStringsKey["BOOKING_CARDNUMBERFIELD_ERROR_EMPTY"] = "BOOKING_CARDNUMBERFIELD_ERROR_EMPTY";
    ResourceStringsKey["BOOKING_CARDTYPE_ERROR_NOTACCEPTED"] = "BOOKING_CARDTYPE_ERROR_NOTACCEPTED";
    ResourceStringsKey["BOOKING_CARDNUMBERFIELD_LABEL"] = "BOOKING_CARDNUMBERFIELD_LABEL";
    ResourceStringsKey["BOOKING_EXPIRYDATEFIELD_ERROR_EMPTY"] = "BOOKING_EXPIRYDATEFIELD_ERROR_EMPTY";
    ResourceStringsKey["BOOKING_EXPIRYDATEFIELD_ERROR_INVALID"] = "BOOKING_EXPIRYDATEFIELD_ERROR_INVALID";
    ResourceStringsKey["BOOKING_EXPIRYDATEFIELD_ERROR_PAST"] = "BOOKING_EXPIRYDATEFIELD_ERROR_PAST";
    ResourceStringsKey["BOOKING_EXPIRYDATEFIELD_LABEL"] = "BOOKING_EXPIRYDATEFIELD_LABEL";
    ResourceStringsKey["BOOKING_SECURITYCODEFIELD_ERROR_EMPTY"] = "BOOKING_SECURITYCODEFIELD_ERROR_EMPTY";
    ResourceStringsKey["BOOKING_SECURITYCODEFIELD_LABEL"] = "BOOKING_SECURITYCODEFIELD_LABEL";
    ResourceStringsKey["BOOKING_SECURITYCODEPROMPT_AMEX"] = "BOOKING_SECURITYCODEPROMPT_AMEX";
    ResourceStringsKey["BOOKING_SECURITYCODEPROMPT"] = "BOOKING_SECURITYCODEPROMPT";
    ResourceStringsKey["SECURITY_CODE_PLACEHOLDER"] = "SECURITY_CODE_PLACEHOLDER";
    ResourceStringsKey["LONG_CARD_PLACEHOLDER"] = "LONG_CARD_PLACEHOLDER";
    ResourceStringsKey["BOOKING_PAYMENTDETAILS_CARDDETAILSHEADING"] = "BOOKING_PAYMENTDETAILS_CARDDETAILSHEADING";
    ResourceStringsKey["HC_BOOKING_CARD_ACCEPTANCE_MESSAGE"] = "HC_BOOKING_CARD_ACCEPTANCE_MESSAGE";
    ResourceStringsKey["BOOKING_PAYMENTDETAILS_INTRO"] = "BOOKING_PAYMENTDETAILS_INTRO";
    ResourceStringsKey["AUTHENTICATINGPAYMENTTITLE"] = "AUTHENTICATINGPAYMENTTITLE";
    ResourceStringsKey["HC_THREEDSCONTACTCUSTSERV"] = "HC_THREEDSCONTACTCUSTSERV";
    ResourceStringsKey["BOOKING_PAYMENTDETAILS_SUBMITBUTTONTEXT_PAYFULL"] = "BOOKING_PAYMENTDETAILS_SUBMITBUTTONTEXT_PAYFULL";
    ResourceStringsKey["BOOKING_PAYMENTDETAILS_CARDHOLDERHEADING"] = "BOOKING_PAYMENTDETAILS_CARDHOLDERHEADING";
    ResourceStringsKey["BOOKING_CARDHOLDERHINT"] = "BOOKING_CARDHOLDERHINT";
    ResourceStringsKey["BOOKING_PAYMENTDETAILS_SUBMITBUTTONTEXT_DEPOSIT"] = "BOOKING_PAYMENTDETAILS_SUBMITBUTTONTEXT_DEPOSIT";
    ResourceStringsKey["CONTINUETOBOOK"] = "CONTINUETOBOOK";
    ResourceStringsKey["EXTENDEDFLIGHTSELECTIONNOHOTELNOTIFICATIONTEXT"] = "EXTENDEDFLIGHTSELECTIONNOHOTELNOTIFICATIONTEXT";
    ResourceStringsKey["OPERATED_BY_TWO_CARRIERS_PROMPT"] = "OPERATED_BY_TWO_CARRIERS_PROMPT";
    ResourceStringsKey["OPERATED_BY_THREE_CARRIERS_PROMPT"] = "OPERATED_BY_THREE_CARRIERS_PROMPT";
    ResourceStringsKey["HC_AIRPORTSEPARATOR"] = "HC_AIRPORTSEPARATOR";
    ResourceStringsKey["CURRENTFLIGHT"] = "CURRENTFLIGHT";
    ResourceStringsKey["PRICEPERADULT"] = "PRICEPERADULT";
    ResourceStringsKey["VIEW"] = "VIEW";
    ResourceStringsKey["INFULL"] = "INFULL";
    ResourceStringsKey["FLIGHTDETAILSLINK_LOWERCASE"] = "FLIGHTDETAILSLINK_LOWERCASE";
    ResourceStringsKey["HC_ATOL_PROTECTED"] = "HC_ATOL_PROTECTED";
    ResourceStringsKey["EXTENDEDFLIGHTSELECTIONMODALTITLE"] = "EXTENDEDFLIGHTSELECTIONMODALTITLE";
    ResourceStringsKey["EXTENDEDFLIGHTSELECTIONMODALTEXTSTART"] = "EXTENDEDFLIGHTSELECTIONMODALTEXTSTART";
    ResourceStringsKey["EXTENDEDFLIGHTSELECTIONMODALTEXTEND"] = "EXTENDEDFLIGHTSELECTIONMODALTEXTEND";
    ResourceStringsKey["EXTENDEDFLIGHTSELECTIONMODALDURATIONINCREASE"] = "EXTENDEDFLIGHTSELECTIONMODALDURATIONINCREASE";
    ResourceStringsKey["EXTENDEDFLIGHTSELECTIONMODALDURATIONDECREASE"] = "EXTENDEDFLIGHTSELECTIONMODALDURATIONDECREASE";
    ResourceStringsKey["EXTENDEDFLIGHTSELECTIONMODALTIMELINEFROM"] = "EXTENDEDFLIGHTSELECTIONMODALTIMELINEFROM";
    ResourceStringsKey["EXTENDEDFLIGHTSELECTIONMODALTIMELINETO"] = "EXTENDEDFLIGHTSELECTIONMODALTIMELINETO";
    ResourceStringsKey["EXTENDEDFLIGHTSELECTIONMODALTIMELINENIGHTS"] = "EXTENDEDFLIGHTSELECTIONMODALTIMELINENIGHTS";
    ResourceStringsKey["EXTENDEDFLIGHTSELECTIONMODALCANCELBUTTONLABEL"] = "EXTENDEDFLIGHTSELECTIONMODALCANCELBUTTONLABEL";
    ResourceStringsKey["EXTENDEDFLIGHTSELECTIONMODALPROCEEDBUTTONLABEL"] = "EXTENDEDFLIGHTSELECTIONMODALPROCEEDBUTTONLABEL";
    ResourceStringsKey["FLIGHTS_PAGE_TITLE"] = "FLIGHTS_PAGE_TITLE";
    ResourceStringsKey["FLIGHTS_PAGE_TITLE_LOADING"] = "FLIGHTS_PAGE_TITLE_LOADING";
    ResourceStringsKey["FLIGHTS_PAGE_SUBTITLE"] = "FLIGHTS_PAGE_SUBTITLE";
    ResourceStringsKey["FLIGHTS_LOADMORE"] = "FLIGHTS_LOADMORE";
    ResourceStringsKey["AIRLINE"] = "AIRLINE";
    ResourceStringsKey["FLIGHTFILTERSHEADER"] = "FLIGHTFILTERSHEADER";
    ResourceStringsKey["FILTERSSUBHEADER"] = "FILTERSSUBHEADER";
    ResourceStringsKey["CHECKEDBAGSHEADING"] = "CHECKEDBAGSHEADING";
    ResourceStringsKey["CHECKEDBAGSUBHEADING"] = "CHECKEDBAGSUBHEADING";
    ResourceStringsKey["EXTRACTADONE"] = "EXTRACTADONE";
    ResourceStringsKey["HC_REMOVE"] = "HC_REMOVE";
    ResourceStringsKey["EXTRAS_INSURANCE_FROM"] = "EXTRAS_INSURANCE_FROM";
    ResourceStringsKey["HOTEL_REVIEW_ROOM"] = "HOTEL_REVIEW_ROOM";
    ResourceStringsKey["EXTRAS_INSURANCE_FROM_DOB_ERROR"] = "EXTRAS_INSURANCE_FROM_DOB_ERROR";
    ResourceStringsKey["EXTRAS_INSURANCE_DOB_ADULT_ERROR"] = "EXTRAS_INSURANCE_DOB_ADULT_ERROR";
    ResourceStringsKey["EXTRAS_INSURANCE_FORM_MAX_AGE_ERROR"] = "EXTRAS_INSURANCE_FORM_MAX_AGE_ERROR";
    ResourceStringsKey["EXTRAS_INSURANCE_INSURANCE_TOTAL_TEXT"] = "EXTRAS_INSURANCE_INSURANCE_TOTAL_TEXT";
    ResourceStringsKey["HC_ADD"] = "HC_ADD";
    ResourceStringsKey["HC_ADDED"] = "HC_ADDED";
    ResourceStringsKey["HC_UPDATE"] = "HC_UPDATE";
    ResourceStringsKey["EXTRAS_INSURANCE_DOB_FORM_CUSTOMER_CONFIRMATION"] = "EXTRAS_INSURANCE_DOB_FORM_CUSTOMER_CONFIRMATION";
    ResourceStringsKey["EXTRAS_INSURANCE_DOB_FORM_INTRO"] = "EXTRAS_INSURANCE_DOB_FORM_INTRO";
    ResourceStringsKey["INSURANCEINFO"] = "INSURANCEINFO";
    ResourceStringsKey["VIEWPOLICY"] = "VIEWPOLICY";
    ResourceStringsKey["HC_PRICETERMS"] = "HC_PRICETERMS";
    ResourceStringsKey["TOTALPRICESUMMARY"] = "TOTALPRICESUMMARY";
    ResourceStringsKey["SELECTFLIGHTTEXT"] = "SELECTFLIGHTTEXT";
    ResourceStringsKey["OPTIONSINSURANCEVIEWPOLICY"] = "OPTIONSINSURANCEVIEWPOLICY";
    ResourceStringsKey["OPTIONSINSURANCEPRODUCTINFO"] = "OPTIONSINSURANCEPRODUCTINFO";
    ResourceStringsKey["HC_INSURANCEDOCLINK"] = "HC_INSURANCEDOCLINK";
    ResourceStringsKey["DESCRIPTIONCOLUMN"] = "DESCRIPTIONCOLUMN";
    ResourceStringsKey["COVERLIMITS"] = "COVERLIMITS";
    ResourceStringsKey["EXCESS"] = "EXCESS";
    ResourceStringsKey["INSURANCE_DETAILS_MODAL_TITLE"] = "INSURANCE_DETAILS_MODAL_TITLE";
    ResourceStringsKey["ABOUTINSURANCE"] = "ABOUTINSURANCE";
    ResourceStringsKey["OPTIONSINSURANCEINFO"] = "OPTIONSINSURANCEINFO";
    ResourceStringsKey["EXTRAS_INSURANCE_BUTTON_TEXT_SELECT"] = "EXTRAS_INSURANCE_BUTTON_TEXT_SELECT";
    ResourceStringsKey["EXTRAS_INSURANCE_OWN_INSURANCE_TEXT"] = "EXTRAS_INSURANCE_OWN_INSURANCE_TEXT";
    ResourceStringsKey["EXTRAS_INSURANCE_BUTTON_TEXT_SELECTED"] = "EXTRAS_INSURANCE_BUTTON_TEXT_SELECTED";
    ResourceStringsKey["OPTIONSINSURANCETITLE"] = "OPTIONSINSURANCETITLE";
    ResourceStringsKey["INSURANCESUBTITLE"] = "INSURANCESUBTITLE";
    ResourceStringsKey["INSURANCEINFORMATION"] = "INSURANCEINFORMATION";
    ResourceStringsKey["MOREINSURANCEINFO"] = "MOREINSURANCEINFO";
    ResourceStringsKey["INSURANCESUCCESS"] = "INSURANCESUCCESS";
    ResourceStringsKey["BAGGAGEREMOVED"] = "BAGGAGEREMOVED";
    ResourceStringsKey["BAGGAGEADDED"] = "BAGGAGEADDED";
    ResourceStringsKey["ADDBAGGAGE"] = "ADDBAGGAGE";
    ResourceStringsKey["ADDXHOLDBAGS"] = "ADDXHOLDBAGS";
    ResourceStringsKey["HC_BAGGAGEINFORMATION"] = "HC_BAGGAGEINFORMATION";
    ResourceStringsKey["BAGGAGESUBTITLE"] = "BAGGAGESUBTITLE";
    ResourceStringsKey["BAGGAGETITLE"] = "BAGGAGETITLE";
    ResourceStringsKey["BAGWEIGHTUNIT"] = "BAGWEIGHTUNIT";
    ResourceStringsKey["HANDBAGGAGE"] = "HANDBAGGAGE";
    ResourceStringsKey["HC_BAG"] = "HC_BAG";
    ResourceStringsKey["HC_BAGS"] = "HC_BAGS";
    ResourceStringsKey["HC_HOLDBAGGAGEADDED"] = "HC_HOLDBAGGAGEADDED";
    ResourceStringsKey["HOLDBAGGAGE"] = "HOLDBAGGAGE";
    ResourceStringsKey["INCLUDEDFREE"] = "INCLUDEDFREE";
    ResourceStringsKey["NOWEIGHTRESTRICTION"] = "NOWEIGHTRESTRICTION";
    ResourceStringsKey["ONEBAGPERPERSON"] = "ONEBAGPERPERSON";
    ResourceStringsKey["PRICEPERBAG"] = "PRICEPERBAG";
    ResourceStringsKey["TOTALBAGPRICESUMMARY"] = "TOTALBAGPRICESUMMARY";
    ResourceStringsKey["REMOVE"] = "REMOVE";
    ResourceStringsKey["BAGGAGESUCCESS"] = "BAGGAGESUCCESS";
    ResourceStringsKey["TRANSFERDURATION"] = "TRANSFERDURATION";
    ResourceStringsKey["PRICETERMS"] = "PRICETERMS";
    ResourceStringsKey["TRANSFERTITLE"] = "TRANSFERTITLE";
    ResourceStringsKey["TRANSFERSUBTITLE"] = "TRANSFERSUBTITLE";
    ResourceStringsKey["TRANSFERINFO"] = "TRANSFERINFO";
    ResourceStringsKey["TRANSFERSUCCESS"] = "TRANSFERSUCCESS";
    ResourceStringsKey["TRANSFER"] = "TRANSFER";
    ResourceStringsKey["BAGGAGEFLIGHTINFO"] = "BAGGAGEFLIGHTINFO";
    ResourceStringsKey["BAGGAGEFLIGHTHOLD"] = "BAGGAGEFLIGHTHOLD";
    ResourceStringsKey["BAGGAGEFLIGHTHOLDSING"] = "BAGGAGEFLIGHTHOLDSING";
    ResourceStringsKey["BAGGAGEFLIGHTHOLDWEIGHT"] = "BAGGAGEFLIGHTHOLDWEIGHT";
    ResourceStringsKey["EXTRAS_MT_SECTION_ALERT_MESSAGE"] = "EXTRAS_MT_SECTION_ALERT_MESSAGE";
    ResourceStringsKey["EXTRAS_MT_TITLE"] = "EXTRAS_MT_TITLE";
    ResourceStringsKey["EXTRAS_MT_TITLE_SUBTEXT"] = "EXTRAS_MT_TITLE_SUBTEXT";
    ResourceStringsKey["TRANSFER_EXTRAS_MT_TITLE_SUBTEXT"] = "TRANSFER_EXTRAS_MT_TITLE_SUBTEXT";
    ResourceStringsKey["EXTRAS_MT_OUTBOUND_TITLE"] = "EXTRAS_MT_OUTBOUND_TITLE";
    ResourceStringsKey["EXTRAS_MT_INBOUND_TITLE"] = "EXTRAS_MT_INBOUND_TITLE";
    ResourceStringsKey["EXTRAS_MT_FLIGHT_NUMBER_FIELD_LABEL"] = "EXTRAS_MT_FLIGHT_NUMBER_FIELD_LABEL";
    ResourceStringsKey["EXTRAS_MT_OUTBOUND_FLIGHT_TIME_FIELD_LABEL"] = "EXTRAS_MT_OUTBOUND_FLIGHT_TIME_FIELD_LABEL";
    ResourceStringsKey["EXTRAS_MT_OUTBND_DEPARTURE_FLIGHT_TIME_FIELD_LABEL"] = "EXTRAS_MT_OUTBND_DEPARTURE_FLIGHT_TIME_FIELD_LABEL";
    ResourceStringsKey["EXTRAS_MT_OUTBOUND_ARRIVAL_FLIGHT_TIME_FIELD_LABEL"] = "EXTRAS_MT_OUTBOUND_ARRIVAL_FLIGHT_TIME_FIELD_LABEL";
    ResourceStringsKey["EXTRAS_MT_INBOUND_FLIGHT_TIME_FIELD_LABEL"] = "EXTRAS_MT_INBOUND_FLIGHT_TIME_FIELD_LABEL";
    ResourceStringsKey["EXTRAS_MT_INBND_DEPARTURE_FLIGHT_TIME_FIELD_LABEL"] = "EXTRAS_MT_INBND_DEPARTURE_FLIGHT_TIME_FIELD_LABEL";
    ResourceStringsKey["EXTRAS_MT_INBOUND_ARRIVAL_FLIGHT_TIME_FIELD_LABEL"] = "EXTRAS_MT_INBOUND_ARRIVAL_FLIGHT_TIME_FIELD_LABEL";
    ResourceStringsKey["EXTRAS_MT_FLIGHT_NUMBER_ERROR_MESSAGE_FORMAT"] = "EXTRAS_MT_FLIGHT_NUMBER_ERROR_MESSAGE_FORMAT";
    ResourceStringsKey["EXTRAS_MT_OUT_FLIGHT_NUMBER_ERROR_MESSAGE"] = "EXTRAS_MT_OUT_FLIGHT_NUMBER_ERROR_MESSAGE";
    ResourceStringsKey["EXTRAS_MT_IN_FLIGHT_NUMBER_ERROR_MESSAGE"] = "EXTRAS_MT_IN_FLIGHT_NUMBER_ERROR_MESSAGE";
    ResourceStringsKey["EXTRAS_MT_OUT_FLIGHT_TIME_ERROR_MESSAGE"] = "EXTRAS_MT_OUT_FLIGHT_TIME_ERROR_MESSAGE";
    ResourceStringsKey["EXTRAS_MT_IN_FLIGHT_TIME_ERROR_MESSAGE"] = "EXTRAS_MT_IN_FLIGHT_TIME_ERROR_MESSAGE";
    ResourceStringsKey["MANDATORYTRANSFERTITLE"] = "MANDATORYTRANSFERTITLE";
    ResourceStringsKey["MANDATORYTRANSFERSUBTITLE"] = "MANDATORYTRANSFERSUBTITLE";
    ResourceStringsKey["MANDATORYTRANSFER_INFO"] = "MANDATORYTRANSFER_INFO";
    ResourceStringsKey["SASH_FREE"] = "SASH_FREE";
    ResourceStringsKey["SASH_TRANSFER"] = "SASH_TRANSFER";
    ResourceStringsKey["SASH_INCLUDED"] = "SASH_INCLUDED";
    ResourceStringsKey["TRANSFERADDED2"] = "TRANSFERADDED2";
    ResourceStringsKey["HC_NO_EXTRAS"] = "HC_NO_EXTRAS";
    ResourceStringsKey["EXTRAS_NO_INSURANCE_MODAL_HEADER"] = "EXTRAS_NO_INSURANCE_MODAL_HEADER";
    ResourceStringsKey["EXTRAS_NO_INSURANCE_MODAL_CONTENT_SUBHEADER"] = "EXTRAS_NO_INSURANCE_MODAL_CONTENT_SUBHEADER";
    ResourceStringsKey["EXTRAS_NO_INSURANCE_MODAL_QUOTATION"] = "EXTRAS_NO_INSURANCE_MODAL_QUOTATION";
    ResourceStringsKey["EXTRAS_NO_INSURANCE_MODAL_QUOTATION_SOURCE"] = "EXTRAS_NO_INSURANCE_MODAL_QUOTATION_SOURCE";
    ResourceStringsKey["EXTRAS_NO_INSURANCE_MODAL_CONFIRMATION_TEXT"] = "EXTRAS_NO_INSURANCE_MODAL_CONFIRMATION_TEXT";
    ResourceStringsKey["EXTRAS_NO_INSURANCE_MODAL_PROCEED_BUTTON_LABEL"] = "EXTRAS_NO_INSURANCE_MODAL_PROCEED_BUTTON_LABEL";
    ResourceStringsKey["EXTRAS_NO_INSURANCE_MODAL_RETURN_BUTTON_LABEL"] = "EXTRAS_NO_INSURANCE_MODAL_RETURN_BUTTON_LABEL";
    ResourceStringsKey["EXTRAS_PAGE_TITLE"] = "EXTRAS_PAGE_TITLE";
    ResourceStringsKey["BOOKINGSUMMARY_HOTEL_REFERENCE"] = "BOOKINGSUMMARY_HOTEL_REFERENCE";
    ResourceStringsKey["BOOKINGCOMPLETE_PAYMENTSHEADING"] = "BOOKINGCOMPLETE_PAYMENTSHEADING";
    ResourceStringsKey["PAYMENTBREAKDOWN_ITEMCAPTION_TOTAL"] = "PAYMENTBREAKDOWN_ITEMCAPTION_TOTAL";
    ResourceStringsKey["PAYMENTBREAKDOWN_ITEMCAPTION_DEPOSIT"] = "PAYMENTBREAKDOWN_ITEMCAPTION_DEPOSIT";
    ResourceStringsKey["PAYMENTBREAKDOWN_ITEMCAPTION_BALANCE"] = "PAYMENTBREAKDOWN_ITEMCAPTION_BALANCE";
    ResourceStringsKey["PAYMENTBREAKDOWN_MESSAGE_PAIDINFULL"] = "PAYMENTBREAKDOWN_MESSAGE_PAIDINFULL";
    ResourceStringsKey["PAYMENTBREAKDOWN_HEADING_BALANCEPAYMENTS"] = "PAYMENTBREAKDOWN_HEADING_BALANCEPAYMENTS";
    ResourceStringsKey["REMAINING_PAYMENTS"] = "REMAINING_PAYMENTS";
    ResourceStringsKey["BOOKINGCOMPLETE_INTROTITLE_HOTEL"] = "BOOKINGCOMPLETE_INTROTITLE_HOTEL";
    ResourceStringsKey["BOOKINGCOMPLETE_INTROTITLE_HOLIDAY"] = "BOOKINGCOMPLETE_INTROTITLE_HOLIDAY";
    ResourceStringsKey["BOOKINGCOMPLETE_INTROTITLE_UNKNOWN"] = "BOOKINGCOMPLETE_INTROTITLE_UNKNOWN";
    ResourceStringsKey["BOOKINGCOMPLETE_INTROEMAILPROMPT"] = "BOOKINGCOMPLETE_INTROEMAILPROMPT";
    ResourceStringsKey["BOOKINGCOMPLETE_INTROEMAILLINK"] = "BOOKINGCOMPLETE_INTROEMAILLINK";
    ResourceStringsKey["BOOKINGCOMPLETE_INTROCONFIDENCE"] = "BOOKINGCOMPLETE_INTROCONFIDENCE";
    ResourceStringsKey["BOOKINGCOMPLETE_EMAILHELP_TEXT"] = "BOOKINGCOMPLETE_EMAILHELP_TEXT";
    ResourceStringsKey["BOOKINGCOMPLETE_EMAILHELP_HEADING"] = "BOOKINGCOMPLETE_EMAILHELP_HEADING";
    ResourceStringsKey["BAGGAGEINFORMATION"] = "BAGGAGEINFORMATION";
    ResourceStringsKey["LOGIN_VERIFICATION_HEADING"] = "LOGIN_VERIFICATION_HEADING";
    ResourceStringsKey["LOGIN_VERIFICATION_SUCCESS"] = "LOGIN_VERIFICATION_SUCCESS";
    ResourceStringsKey["LOGIN_VERIFICATION_FAILED"] = "LOGIN_VERIFICATION_FAILED";
    ResourceStringsKey["EXTRAREMOVE"] = "EXTRAREMOVE";
    ResourceStringsKey["LOGIN_SIGN_UP_CHECK_EMAIL_SUBHEADING"] = "LOGIN_SIGN_UP_CHECK_EMAIL_SUBHEADING";
    ResourceStringsKey["LOGIN_SIGN_UP_PASSWORD_BUTTON"] = "LOGIN_SIGN_UP_PASSWORD_BUTTON";
    ResourceStringsKey["LOGIN_SIGN_UP_PASSWORD_CONFIRM_FIELD_LABEL"] = "LOGIN_SIGN_UP_PASSWORD_CONFIRM_FIELD_LABEL";
    ResourceStringsKey["LOGIN_SIGN_UP_FAILED"] = "LOGIN_SIGN_UP_FAILED";
    ResourceStringsKey["LOGIN_SIGN_UP_CHECK_EMAIL_HEADING"] = "LOGIN_SIGN_UP_CHECK_EMAIL_HEADING";
    ResourceStringsKey["LOGIN_SIGN_UP_BUTTON"] = "LOGIN_SIGN_UP_BUTTON";
    ResourceStringsKey["LOGIN_SIGN_UP_PASSWORD_HEADING"] = "LOGIN_SIGN_UP_PASSWORD_HEADING";
    ResourceStringsKey["LOGIN_SIGN_UP_PASSWORD_SUBHEADING"] = "LOGIN_SIGN_UP_PASSWORD_SUBHEADING";
    ResourceStringsKey["LOGIN_SIGN_IN_PASSWORD_BUTTON"] = "LOGIN_SIGN_IN_PASSWORD_BUTTON";
    ResourceStringsKey["LOGIN_SIGN_IN_DIVIDER"] = "LOGIN_SIGN_IN_DIVIDER";
    ResourceStringsKey["LOGIN_SIGN_IN_PASSWORD_SUBHEADING"] = "LOGIN_SIGN_IN_PASSWORD_SUBHEADING";
    ResourceStringsKey["LOGIN_SIGN_IN_FAILED"] = "LOGIN_SIGN_IN_FAILED";
    ResourceStringsKey["LOGIN_SIGN_IN_SOCIAL_FAILED"] = "LOGIN_SIGN_IN_SOCIAL_FAILED";
    ResourceStringsKey["LOGIN_SIGN_UP_SUBHEADING"] = "LOGIN_SIGN_UP_SUBHEADING";
    ResourceStringsKey["LOGIN_FORGOT_FAILED"] = "LOGIN_FORGOT_FAILED";
    ResourceStringsKey["LOGIN_FORGOT_CHECK_HEADING"] = "LOGIN_FORGOT_CHECK_HEADING";
    ResourceStringsKey["LOGIN_FORGOT_CHECK_SUBHEADING"] = "LOGIN_FORGOT_CHECK_SUBHEADING";
    ResourceStringsKey["LOGIN_SIGN_IN_HEADING"] = "LOGIN_SIGN_IN_HEADING";
    ResourceStringsKey["LOGIN_SIGN_IN_SUBHEADING"] = "LOGIN_SIGN_IN_SUBHEADING";
    ResourceStringsKey["LOGIN_SIGN_IN_BUTTON"] = "LOGIN_SIGN_IN_BUTTON";
    ResourceStringsKey["LOGIN_SIGN_IN_CREATE_PROMPT"] = "LOGIN_SIGN_IN_CREATE_PROMPT";
    ResourceStringsKey["LOGIN_SIGN_IN_PASSWORD_HEADING"] = "LOGIN_SIGN_IN_PASSWORD_HEADING";
    ResourceStringsKey["LOGIN_EMAIL_INVALID"] = "LOGIN_EMAIL_INVALID";
    ResourceStringsKey["LOGIN_CONFIRM_PASSWORD_INVALID"] = "LOGIN_CONFIRM_PASSWORD_INVALID";
    ResourceStringsKey["LOGIN_PASSWORD_INVALID"] = "LOGIN_PASSWORD_INVALID";
    ResourceStringsKey["LOGIN_SIGN_UP_HEADING"] = "LOGIN_SIGN_UP_HEADING";
    ResourceStringsKey["LOGIN_CHANGE_PASSWORD_SUCCESS_HEADING"] = "LOGIN_CHANGE_PASSWORD_SUCCESS_HEADING";
    ResourceStringsKey["LOGIN_FORGOT_HEADING"] = "LOGIN_FORGOT_HEADING";
    ResourceStringsKey["LOGIN_FORGOT_SUBHEADING"] = "LOGIN_FORGOT_SUBHEADING";
    ResourceStringsKey["LOGIN_FORGOT_BUTTON"] = "LOGIN_FORGOT_BUTTON";
    ResourceStringsKey["LOGIN_CHANGE_PASSWORD_FAILED"] = "LOGIN_CHANGE_PASSWORD_FAILED";
    ResourceStringsKey["LOGIN_CHANGE_PASSWORD_SUCCESS_SUBHEADING"] = "LOGIN_CHANGE_PASSWORD_SUCCESS_SUBHEADING";
    ResourceStringsKey["LOGIN_CHANGE_PASSWORD_CONFIRM_FIELD_LABEL"] = "LOGIN_CHANGE_PASSWORD_CONFIRM_FIELD_LABEL";
    ResourceStringsKey["LOGIN_CHANGE_PASSWORD_HEADING"] = "LOGIN_CHANGE_PASSWORD_HEADING";
    ResourceStringsKey["LOGIN_CHANGE_PASSWORD_SUBHEADING"] = "LOGIN_CHANGE_PASSWORD_SUBHEADING";
    ResourceStringsKey["LOGIN_CHANGE_PASSWORD_BUTTON"] = "LOGIN_CHANGE_PASSWORD_BUTTON";
    ResourceStringsKey["HC_CALLTOBOOKMODALTITLE"] = "HC_CALLTOBOOKMODALTITLE";
    ResourceStringsKey["HC_CALLTOBOOKMODAL"] = "HC_CALLTOBOOKMODAL";
    ResourceStringsKey["YOUR_TRANSFERS_REFERENCE"] = "YOUR_TRANSFERS_REFERENCE";
    ResourceStringsKey["BOOKINGSUMMARY_TRANSFER_TIME_OUTBOUND"] = "BOOKINGSUMMARY_TRANSFER_TIME_OUTBOUND";
    ResourceStringsKey["BOOKINGSUMMARY_TRANSFER_TIME_INBOUND"] = "BOOKINGSUMMARY_TRANSFER_TIME_INBOUND";
    ResourceStringsKey["BOOKING_COMPLETE_TRANSFERS_TOTAL_LABEL"] = "BOOKING_COMPLETE_TRANSFERS_TOTAL_LABEL";
    ResourceStringsKey["MOBILE_RATE_PILL_LABEL"] = "MOBILE_RATE_PILL_LABEL";
    ResourceStringsKey["COVID_PAYMENT_DISCLAIMER_ERROR"] = "COVID_PAYMENT_DISCLAIMER_ERROR";
    ResourceStringsKey["GLOBALWAIVERHEADING"] = "GLOBALWAIVERHEADING";
    ResourceStringsKey["GLOBALWAIVERBODYCONTENT"] = "GLOBALWAIVERBODYCONTENT";
    ResourceStringsKey["BOOKING_CONDITIONS_HEADING"] = "BOOKING_CONDITIONS_HEADING";
    ResourceStringsKey["BOOKINGSUMMARY_HEADING_BAGGAGE"] = "BOOKINGSUMMARY_HEADING_BAGGAGE";
    ResourceStringsKey["BOOKING_COMPLETE_BAGS_TOTAL_LABEL"] = "BOOKING_COMPLETE_BAGS_TOTAL_LABEL";
    ResourceStringsKey["HC_AIRLINELOGOBASEURL"] = "HC_AIRLINELOGOBASEURL";
    ResourceStringsKey["BOOKING_COMPLETE_FLIGHTS_TOTAL_LABEL"] = "BOOKING_COMPLETE_FLIGHTS_TOTAL_LABEL";
    ResourceStringsKey["BOOKINGSUMMARY_FLIGHT_REFERENCE"] = "BOOKINGSUMMARY_FLIGHT_REFERENCE";
    ResourceStringsKey["BOOKINGSUMMARY_HEADING_INSURANCE"] = "BOOKINGSUMMARY_HEADING_INSURANCE";
    ResourceStringsKey["BOOKING_COMPLETE_INSURANCE_TOTAL_LABEL"] = "BOOKING_COMPLETE_INSURANCE_TOTAL_LABEL";
    ResourceStringsKey["BOOKINGSUMMARY_INSURANCE_REFERENCE"] = "BOOKINGSUMMARY_INSURANCE_REFERENCE";
    ResourceStringsKey["BOOKING_COMPLETE_HOTEL_TOTAL_LABEL"] = "BOOKING_COMPLETE_HOTEL_TOTAL_LABEL";
    ResourceStringsKey["FILTERSHEADERAPPLIED"] = "FILTERSHEADERAPPLIED";
    ResourceStringsKey["FILTERSSUBHEADERPLURAL"] = "FILTERSSUBHEADERPLURAL";
    ResourceStringsKey["FILTERSSUBHEADERSINGLE"] = "FILTERSSUBHEADERSINGLE";
    ResourceStringsKey["HC_RELOADPAYMENTDETAILS"] = "HC_RELOADPAYMENTDETAILS";
    ResourceStringsKey["HC_ERROR_MSG"] = "HC_ERROR_MSG";
    ResourceStringsKey["HC_ERROR_MSG_WITH_ALT"] = "HC_ERROR_MSG_WITH_ALT";
    ResourceStringsKey["HC_ERROR_FLIGHT_SELECTION"] = "HC_ERROR_FLIGHT_SELECTION";
    ResourceStringsKey["BOOKING_COMPLETE_PACKAGE_TOTAL_LABEL"] = "BOOKING_COMPLETE_PACKAGE_TOTAL_LABEL";
    ResourceStringsKey["SEARCH_TABS_HOLIDAY_LABEL"] = "SEARCH_TABS_HOLIDAY_LABEL";
    ResourceStringsKey["SEARCH_TABS_HOTEL_LABEL"] = "SEARCH_TABS_HOTEL_LABEL";
    ResourceStringsKey["FLIGHTSEARCHSINGLERESULTMESSAGE"] = "FLIGHTSEARCHSINGLERESULTMESSAGE";
    ResourceStringsKey["SINGLEFLIGHTMESSAGERETURNTORESULTS"] = "SINGLEFLIGHTMESSAGERETURNTORESULTS";
    ResourceStringsKey["STARTNEWSEARCH"] = "STARTNEWSEARCH";
    ResourceStringsKey["ADULTSLOWER"] = "ADULTSLOWER";
    ResourceStringsKey["ADULTLOWER"] = "ADULTLOWER";
    ResourceStringsKey["XADULT"] = "XADULT";
    ResourceStringsKey["HC_MATCH"] = "HC_MATCH";
    ResourceStringsKey["HC_BIRTHDAY_VALIDATION_FOR_GUEST"] = "HC_BIRTHDAY_VALIDATION_FOR_GUEST";
    ResourceStringsKey["HC_LOGO_TITLE"] = "HC_LOGO_TITLE";
    ResourceStringsKey["HOLIDAYPRICE"] = "HOLIDAYPRICE";
    ResourceStringsKey["HOTEL_REVIEW_CUSTOMERLEFTNOCOMMENTS"] = "HOTEL_REVIEW_CUSTOMERLEFTNOCOMMENTS";
    ResourceStringsKey["HCBookingConfirmationReload"] = "HCBookingConfirmationReload";
    ResourceStringsKey["CLASS"] = "CLASS";
    ResourceStringsKey["DEFAULT_META_TITLE"] = "DEFAULT_META_TITLE";
    ResourceStringsKey["DEFAULT_META_DESCRIPTION"] = "DEFAULT_META_DESCRIPTION";
    ResourceStringsKey["WHATINCLUDEDTITLE"] = "WHATINCLUDEDTITLE";
    ResourceStringsKey["EXCLUDEDTEXT"] = "EXCLUDEDTEXT";
    ResourceStringsKey["INCLUDEDTEXT"] = "INCLUDEDTEXT";
    ResourceStringsKey["WHATINCLUDEDTEXT"] = "WHATINCLUDEDTEXT";
    ResourceStringsKey["BOOKING_CHANGEPAXLINKTEXT"] = "BOOKING_CHANGEPAXLINKTEXT";
    ResourceStringsKey["POPUPITAAHEADER"] = "POPUPITAAHEADER";
    ResourceStringsKey["POPUPITAACONTENT"] = "POPUPITAACONTENT";
    ResourceStringsKey["TRANSFER_BREADCRUMB_CHOOSE"] = "TRANSFER_BREADCRUMB_CHOOSE";
    ResourceStringsKey["TRANSFER_BREADCRUMB_SEARCH"] = "TRANSFER_BREADCRUMB_SEARCH";
    ResourceStringsKey["MAILINGLISTSIGNUP_EMAILMISSING"] = "MAILINGLISTSIGNUP_EMAILMISSING";
    ResourceStringsKey["MAILINGLISTSIGNUP_EMAILINVALID"] = "MAILINGLISTSIGNUP_EMAILINVALID";
    ResourceStringsKey["UNSUBSCRIBE"] = "UNSUBSCRIBE";
    ResourceStringsKey["UNSUBSCRIBE_SUCCESSHEADING"] = "UNSUBSCRIBE_SUCCESSHEADING";
    ResourceStringsKey["UNSUBSCRIBE_PROMPT"] = "UNSUBSCRIBE_PROMPT";
    ResourceStringsKey["UNSUBSCRIBE_SUCCESSTEXT"] = "UNSUBSCRIBE_SUCCESSTEXT";
    ResourceStringsKey["SUBMIT"] = "SUBMIT";
    ResourceStringsKey["CONTINUE_BROWSING"] = "CONTINUE_BROWSING";
    ResourceStringsKey["UNSUBSCRIBE_FROM_EMAILLIST"] = "UNSUBSCRIBE_FROM_EMAILLIST";
    ResourceStringsKey["TRAVEL_AWARE_LINK"] = "TRAVEL_AWARE_LINK";
    ResourceStringsKey["FLIGHT_NUMBERFIELD_ERROR_EMPTY"] = "FLIGHT_NUMBERFIELD_ERROR_EMPTY";
    ResourceStringsKey["TRANSFERPROVIDERDETAILS"] = "TRANSFERPROVIDERDETAILS";
    ResourceStringsKey["SELECT_YOUR_TRANSFER"] = "SELECT_YOUR_TRANSFER";
    ResourceStringsKey["TRANSFERPROVIDERREQUIREMENTSBOOKBYPHONE"] = "TRANSFERPROVIDERREQUIREMENTSBOOKBYPHONE";
    ResourceStringsKey["AIRPORTS"] = "AIRPORTS";
    ResourceStringsKey["SEARCHUNITTRANSFERAIRPORTLABEL"] = "SEARCHUNITTRANSFERAIRPORTLABEL";
    ResourceStringsKey["AIRPORT_AUTO_COMPLETER_PLACEHOLDER"] = "AIRPORT_AUTO_COMPLETER_PLACEHOLDER";
    ResourceStringsKey["SEARCH_AIRPORT_ERROR_EMPTY"] = "SEARCH_AIRPORT_ERROR_EMPTY";
    ResourceStringsKey["APPROXFLIGHTDEPARTURE"] = "APPROXFLIGHTDEPARTURE";
    ResourceStringsKey["APPROXFLIGHTARRIVAL"] = "APPROXFLIGHTARRIVAL";
    ResourceStringsKey["FLIGHT_DEPARTURE_DATE"] = "FLIGHT_DEPARTURE_DATE";
    ResourceStringsKey["FLIGHT_ARRIVAL_DATE"] = "FLIGHT_ARRIVAL_DATE";
    ResourceStringsKey["RESORT_POPUP_TITLE"] = "RESORT_POPUP_TITLE";
    ResourceStringsKey["SEARCHUNITTRANSFERRESORTLABEL"] = "SEARCHUNITTRANSFERRESORTLABEL";
    ResourceStringsKey["RESORT_AUTO_COMPLETER_PLACEHOLDER"] = "RESORT_AUTO_COMPLETER_PLACEHOLDER";
    ResourceStringsKey["SEARCH_RESORT_ERROR_EMPTY"] = "SEARCH_RESORT_ERROR_EMPTY";
    ResourceStringsKey["HOTELDETAILS"] = "HOTELDETAILS";
    ResourceStringsKey["ENTERHOTELDETAILSADVICE"] = "ENTERHOTELDETAILSADVICE";
    ResourceStringsKey["HOTELORPROPERTYNAME"] = "HOTELORPROPERTYNAME";
    ResourceStringsKey["TRANSFER_HOTEL_NAME_ERROR_EMPTY"] = "TRANSFER_HOTEL_NAME_ERROR_EMPTY";
    ResourceStringsKey["HOTELORPROPERTYADDRESS"] = "HOTELORPROPERTYADDRESS";
    ResourceStringsKey["TRANSFER_HOTEL_ADDRESS_ERROR_EMPTY"] = "TRANSFER_HOTEL_ADDRESS_ERROR_EMPTY";
    ResourceStringsKey["OPTIONSSEATSELECTED"] = "OPTIONSSEATSELECTED";
    ResourceStringsKey["OPTIONSSEATTITLE"] = "OPTIONSSEATTITLE";
    ResourceStringsKey["OPTIONSSEATSUBTITLE"] = "OPTIONSSEATSUBTITLE";
    ResourceStringsKey["OPTIONSSEATCARDTITLE"] = "OPTIONSSEATCARDTITLE";
    ResourceStringsKey["OPTIONSSEATINTRO"] = "OPTIONSSEATINTRO";
    ResourceStringsKey["CHOOSESEATS"] = "CHOOSESEATS";
    ResourceStringsKey["CHANGESEATS"] = "CHANGESEATS";
    ResourceStringsKey["PERADULT"] = "PERADULT";
    ResourceStringsKey["SEATSELECTION"] = "SEATSELECTION";
    ResourceStringsKey["SEATSELECTION_INTRO"] = "SEATSELECTION_INTRO";
    ResourceStringsKey["AVAILABLESEAT"] = "AVAILABLESEAT";
    ResourceStringsKey["AVAILABLEWITHRESTRICTIONS"] = "AVAILABLEWITHRESTRICTIONS";
    ResourceStringsKey["EXTRALEGROOM"] = "EXTRALEGROOM";
    ResourceStringsKey["SEATKEY"] = "SEATKEY";
    ResourceStringsKey["UNAVAILABLE"] = "UNAVAILABLE";
    ResourceStringsKey["CHOOSEANOTHERSEAT"] = "CHOOSEANOTHERSEAT";
    ResourceStringsKey["CONFIRM"] = "CONFIRM";
    ResourceStringsKey["SAFETYMSG"] = "SAFETYMSG";
    ResourceStringsKey["SELECTSEAT"] = "SELECTSEAT";
    ResourceStringsKey["SEAT_EXTRALEGROOM"] = "SEAT_EXTRALEGROOM";
    ResourceStringsKey["SEAT_STANDARD"] = "SEAT_STANDARD";
    ResourceStringsKey["SEAT_UPFRONT"] = "SEAT_UPFRONT";
    ResourceStringsKey["ADULTWITHINFANT"] = "ADULTWITHINFANT";
    ResourceStringsKey["MANAGEOUTBOUNDFLIGHT"] = "MANAGEOUTBOUNDFLIGHT";
    ResourceStringsKey["MANAGEINBOUNDFLIGHT"] = "MANAGEINBOUNDFLIGHT";
    ResourceStringsKey["PASSENGER"] = "PASSENGER";
    ResourceStringsKey["PRICE"] = "PRICE";
    ResourceStringsKey["SEAT"] = "SEAT";
    ResourceStringsKey["SELECTPASSENGER"] = "SELECTPASSENGER";
    ResourceStringsKey["CONFIRMSEATS"] = "CONFIRMSEATS";
    ResourceStringsKey["CLEARSELECTION"] = "CLEARSELECTION";
    ResourceStringsKey["MULTIAIRPORTDROPDOWNTITLE"] = "MULTIAIRPORTDROPDOWNTITLE";
    ResourceStringsKey["AUTOCOMPLETERTITLE"] = "AUTOCOMPLETERTITLE";
    ResourceStringsKey["HC_DEPARTUREAUTOCOMPLETER_LABEL"] = "HC_DEPARTUREAUTOCOMPLETER_LABEL";
    ResourceStringsKey["TRANSFER_ADDED"] = "TRANSFER_ADDED";
    ResourceStringsKey["TRANSFER_REMOVED"] = "TRANSFER_REMOVED";
    ResourceStringsKey["PAYMENT_PLAN_TITLE_NO_OPTIONS"] = "PAYMENT_PLAN_TITLE_NO_OPTIONS";
    ResourceStringsKey["YOURTRANSFERTO"] = "YOURTRANSFERTO";
    ResourceStringsKey["CABIN_CLASS"] = "CABIN_CLASS";
    ResourceStringsKey["HC_CABIN_ECONOMY"] = "HC_CABIN_ECONOMY";
    ResourceStringsKey["HC_CABIN_PREMIUM_ECONOMY"] = "HC_CABIN_PREMIUM_ECONOMY";
    ResourceStringsKey["HC_CABIN_BUSINESS"] = "HC_CABIN_BUSINESS";
    ResourceStringsKey["HC_CABIN_FIRST"] = "HC_CABIN_FIRST";
    ResourceStringsKey["HC_SELECT"] = "HC_SELECT";
    ResourceStringsKey["HC_SELECTED"] = "HC_SELECTED";
    ResourceStringsKey["APPROX"] = "APPROX";
    ResourceStringsKey["ARRIVESAT"] = "ARRIVESAT";
    ResourceStringsKey["DEPARTSAT"] = "DEPARTSAT";
    ResourceStringsKey["INBOUND"] = "INBOUND";
    ResourceStringsKey["HC_TRANSFER_RETURN"] = "HC_TRANSFER_RETURN";
    ResourceStringsKey["HC_TRANSFER_ONEWAY"] = "HC_TRANSFER_ONEWAY";
    ResourceStringsKey["AIRPORT"] = "AIRPORT";
    ResourceStringsKey["LEAVINGFROM"] = "LEAVINGFROM";
    ResourceStringsKey["HC_GOINGTO"] = "HC_GOINGTO";
    ResourceStringsKey["FLIGHTARRIVING"] = "FLIGHTARRIVING";
    ResourceStringsKey["HCFLIGHTDEPARTURE"] = "HCFLIGHTDEPARTURE";
    ResourceStringsKey["TRANSFERTYPE"] = "TRANSFERTYPE";
    ResourceStringsKey["TRANSFERDETAILS"] = "TRANSFERDETAILS";
    ResourceStringsKey["COSTBREAKDOWN"] = "COSTBREAKDOWN";
    ResourceStringsKey["PASSENGERS"] = "PASSENGERS";
    ResourceStringsKey["BOOKINGCOMPLETE_INTROTITLE_TRANSFER_NODEST"] = "BOOKINGCOMPLETE_INTROTITLE_TRANSFER_NODEST";
    ResourceStringsKey["HC_BOOKING_COMPLETE_TRANSFER_INTRO"] = "HC_BOOKING_COMPLETE_TRANSFER_INTRO";
    ResourceStringsKey["HC_PERNIGHT"] = "HC_PERNIGHT";
    ResourceStringsKey["PERNIGHT"] = "PERNIGHT";
    ResourceStringsKey["DESTINATIONAIRPORT"] = "DESTINATIONAIRPORT";
    ResourceStringsKey["DEPARTUREAIRPORT"] = "DEPARTUREAIRPORT";
    ResourceStringsKey["DEPARTUREAIRPORTERROR"] = "DEPARTUREAIRPORTERROR";
    ResourceStringsKey["HC_TRANSFER_DESTINATION_LABEL"] = "HC_TRANSFER_DESTINATION_LABEL";
    ResourceStringsKey["HC_TRANSFER_DEPARTURE_LABEL"] = "HC_TRANSFER_DEPARTURE_LABEL";
    ResourceStringsKey["SEARCH_TABS_FLIGHT_LABEL"] = "SEARCH_TABS_FLIGHT_LABEL";
    ResourceStringsKey["FLYINGTOLABEL"] = "FLYINGTOLABEL";
    ResourceStringsKey["SEARCH_ARRIVAL_PLACEHOLDER"] = "SEARCH_ARRIVAL_PLACEHOLDER";
    ResourceStringsKey["SEARCH_ARRIVAL_ERROR_EMPTY"] = "SEARCH_ARRIVAL_ERROR_EMPTY";
    ResourceStringsKey["SEARCH_ARRIVAL_POPUP_TITLE"] = "SEARCH_ARRIVAL_POPUP_TITLE";
    ResourceStringsKey["SEARCH_FLIGHT_OCCUPANCY_POPUP_TITLE"] = "SEARCH_FLIGHT_OCCUPANCY_POPUP_TITLE";
    ResourceStringsKey["HC_BOOKING_TERMS_MSG"] = "HC_BOOKING_TERMS_MSG";
    ResourceStringsKey["HC_SUPPLIER_COND"] = "HC_SUPPLIER_COND";
    ResourceStringsKey["HC_AGENCY_TNB"] = "HC_AGENCY_TNB";
    ResourceStringsKey["HC_COVID_WAVIER"] = "HC_COVID_WAVIER";
    ResourceStringsKey["FLIGHTDEPARTSAT"] = "FLIGHTDEPARTSAT";
    ResourceStringsKey["FLIGHTARRIVESAT"] = "FLIGHTARRIVESAT";
    ResourceStringsKey["LEAD_BOOKERDETAILHEADING"] = "LEAD_BOOKERDETAILHEADING";
    ResourceStringsKey["HCSEATEXTRAS"] = "HCSEATEXTRAS";
    ResourceStringsKey["SEATSADDEDTOYOURBASKET"] = "SEATSADDEDTOYOURBASKET";
    ResourceStringsKey["SEATSREMOVEDFROMYOURBASKET"] = "SEATSREMOVEDFROMYOURBASKET";
    ResourceStringsKey["HC_CHANGESEATS"] = "HC_CHANGESEATS";
    ResourceStringsKey["HC_CHOOSESEATS"] = "HC_CHOOSESEATS";
    ResourceStringsKey["HC_CONFIRMSEATS"] = "HC_CONFIRMSEATS";
    ResourceStringsKey["HC_CLEARSELECTION"] = "HC_CLEARSELECTION";
    ResourceStringsKey["PAYMENT_PLAN_TITLE"] = "PAYMENT_PLAN_TITLE";
    ResourceStringsKey["HC_PAYMENT_HEADER"] = "HC_PAYMENT_HEADER";
    ResourceStringsKey["HC_ARRIVAL_AIRPORT"] = "HC_ARRIVAL_AIRPORT";
    ResourceStringsKey["HC_FLIGHT_BAGGAGE_INFO"] = "HC_FLIGHT_BAGGAGE_INFO";
    ResourceStringsKey["HC_FLIGHT_NOTREFUNDABLE_NOTE"] = "HC_FLIGHT_NOTREFUNDABLE_NOTE";
    ResourceStringsKey["HC_OUTBOUND_MESSAGE"] = "HC_OUTBOUND_MESSAGE";
    ResourceStringsKey["HC_INBOUND_MESSAGE"] = "HC_INBOUND_MESSAGE";
    ResourceStringsKey["HC_PAYMENT_METHOD_HEADER"] = "HC_PAYMENT_METHOD_HEADER";
    ResourceStringsKey["HC_PAYMENT_METHOD_HINT"] = "HC_PAYMENT_METHOD_HINT";
    ResourceStringsKey["HC_CARDHOLDER_BILLING_ADDRESS"] = "HC_CARDHOLDER_BILLING_ADDRESS";
    ResourceStringsKey["HC_BILLING_ADDRESS"] = "HC_BILLING_ADDRESS";
    ResourceStringsKey["YOUR_FULL_FLIGHT_ITINERARY"] = "YOUR_FULL_FLIGHT_ITINERARY";
    ResourceStringsKey["HC_START_TYPING_ADDRESS"] = "HC_START_TYPING_ADDRESS";
    ResourceStringsKey["BRANDEDFARE_BUTTON_SELECTED"] = "BRANDEDFARE_BUTTON_SELECTED";
    ResourceStringsKey["BRANDEDFARE_BUTTON_NOT_SELECTED"] = "BRANDEDFARE_BUTTON_NOT_SELECTED";
    ResourceStringsKey["BRANDEDFARE_FULL_DETAILS"] = "BRANDEDFARE_FULL_DETAILS";
    ResourceStringsKey["BRANDEDFARE_OUTBOUND_FLIGHT"] = "BRANDEDFARE_OUTBOUND_FLIGHT";
    ResourceStringsKey["BRANDEDFARE_INBOUND_FLIGHT"] = "BRANDEDFARE_INBOUND_FLIGHT";
    ResourceStringsKey["BRANDEDFARE_RETURN_FLIGHT"] = "BRANDEDFARE_RETURN_FLIGHT";
    ResourceStringsKey["BRANDEDFARE_HANDBAGGAGE_INCLUDED"] = "BRANDEDFARE_HANDBAGGAGE_INCLUDED";
    ResourceStringsKey["BRANDEDFARE_HANDBAGGAGE_ONLY"] = "BRANDEDFARE_HANDBAGGAGE_ONLY";
    ResourceStringsKey["BRANDEDFARE_SEAT_SELECTION_INCLUDED"] = "BRANDEDFARE_SEAT_SELECTION_INCLUDED";
    ResourceStringsKey["BRANDEDFARE_SEAT_SELECTION_NOT_INCLUDED"] = "BRANDEDFARE_SEAT_SELECTION_NOT_INCLUDED";
    ResourceStringsKey["BRANDEDFARE_MEALS_AND_DRINKS"] = "BRANDEDFARE_MEALS_AND_DRINKS";
    ResourceStringsKey["BRANDEDFARE_WIFI"] = "BRANDEDFARE_WIFI";
    ResourceStringsKey["BRANDEDFARE_HAND_BAGGAGE_TITLE"] = "BRANDEDFARE_HAND_BAGGAGE_TITLE";
    ResourceStringsKey["BRANDEDFARE_CHECKED_BAGGAGE_TITLE"] = "BRANDEDFARE_CHECKED_BAGGAGE_TITLE";
    ResourceStringsKey["BRANDEDFARE_SEAT_SELECTION_TITLE"] = "BRANDEDFARE_SEAT_SELECTION_TITLE";
    ResourceStringsKey["BRANDEDFARE_MEALS_AND_DRINKS_TITLE"] = "BRANDEDFARE_MEALS_AND_DRINKS_TITLE";
    ResourceStringsKey["BRANDEDFARE_WIFI_TITLE"] = "BRANDEDFARE_WIFI_TITLE";
    ResourceStringsKey["BRANDEDFARE_UPGRADE_TITLE"] = "BRANDEDFARE_UPGRADE_TITLE";
    ResourceStringsKey["BRANDEDFARE_INCLUDES_TITLE"] = "BRANDEDFARE_INCLUDES_TITLE";
    ResourceStringsKey["BRANDEDFARE_FURTHER_DETAILS"] = "BRANDEDFARE_FURTHER_DETAILS";
    ResourceStringsKey["OUTBOUND_DATE_ERROR"] = "OUTBOUND_DATE_ERROR";
    ResourceStringsKey["INBOUND_DATE_ERROR"] = "INBOUND_DATE_ERROR";
    ResourceStringsKey["ARRIVAL_IN_TIME_ERROR_MESSAGE"] = "ARRIVAL_IN_TIME_ERROR_MESSAGE";
    ResourceStringsKey["HC_OUTBOUND_DATE_ERROR"] = "HC_OUTBOUND_DATE_ERROR";
    ResourceStringsKey["HC_INBOUND_DATE_ERROR"] = "HC_INBOUND_DATE_ERROR";
    ResourceStringsKey["HC_ARRIVAL_IN_TIME_ERROR_MESSAGE"] = "HC_ARRIVAL_IN_TIME_ERROR_MESSAGE";
    ResourceStringsKey["HC_SELECT_AIRPORT_FLYING_TO"] = "HC_SELECT_AIRPORT_FLYING_TO";
    ResourceStringsKey["HC_START_TYPING_ARRIVAL_AIRPORT"] = "HC_START_TYPING_ARRIVAL_AIRPORT";
    ResourceStringsKey["HC_START_TYPING_DEPARTURE_AIRPORT"] = "HC_START_TYPING_DEPARTURE_AIRPORT";
    ResourceStringsKey["SEO_STUCTUREDDATA_ADDRESSCOUNTRY"] = "SEO_STUCTUREDDATA_ADDRESSCOUNTRY";
    ResourceStringsKey["HC_PAYMENTFAILED"] = "HC_PAYMENTFAILED";
    ResourceStringsKey["HC_PAYMENTASSISTENCE"] = "HC_PAYMENTASSISTENCE";
    ResourceStringsKey["HC_SUPPORTCENTRE"] = "HC_SUPPORTCENTRE";
    ResourceStringsKey["HC_TOTALCOST"] = "HC_TOTALCOST";
    ResourceStringsKey["HC_TOTALPAID"] = "HC_TOTALPAID";
    ResourceStringsKey["HC_CANCELLATIONCHARGE"] = "HC_CANCELLATIONCHARGE";
    ResourceStringsKey["HC_BALANCEOUTSTANDING"] = "HC_BALANCEOUTSTANDING";
    ResourceStringsKey["HC_NONREFUNDABLECHARGES"] = "HC_NONREFUNDABLECHARGES";
    ResourceStringsKey["HC_TOTALAMOUNTCHARGEABLE"] = "HC_TOTALAMOUNTCHARGEABLE";
    ResourceStringsKey["HC_TOTALAMOUNTREFUNDABLE"] = "HC_TOTALAMOUNTREFUNDABLE";
    ResourceStringsKey["ATTRACTIONS"] = "ATTRACTIONS";
    ResourceStringsKey["ADDATTRACTIONS"] = "ADDATTRACTIONS";
    ResourceStringsKey["HC_ATTRACTIONSLOOKINGFORTHINGS"] = "HC_ATTRACTIONSLOOKINGFORTHINGS";
    ResourceStringsKey["ATTRACTIONSTOTAL"] = "ATTRACTIONSTOTAL";
    ResourceStringsKey["HC_ATTRACTIONS_ADDED"] = "HC_ATTRACTIONS_ADDED";
    ResourceStringsKey["HC_ATTRACTIONS_REMOVED"] = "HC_ATTRACTIONS_REMOVED";
    ResourceStringsKey["TRANSFER_SEARCH_ARRIVAL_DATE_PLACEHOLDER"] = "TRANSFER_SEARCH_ARRIVAL_DATE_PLACEHOLDER";
    ResourceStringsKey["TRANSFER_SEARCH_DEPARTURE_DATE_PLACEHOLDER"] = "TRANSFER_SEARCH_DEPARTURE_DATE_PLACEHOLDER";
    ResourceStringsKey["HIDE_TRANSFER_SEARCH"] = "HIDE_TRANSFER_SEARCH";
    ResourceStringsKey["HC_CONTINUE"] = "HC_CONTINUE";
    ResourceStringsKey["HC_THISPAGEISNOTAVAILABLE"] = "HC_THISPAGEISNOTAVAILABLE";
    ResourceStringsKey["HC_BALANCEREMAININGMESSAGE"] = "HC_BALANCEREMAININGMESSAGE";
    ResourceStringsKey["PAYMENTSUMMARY"] = "PAYMENTSUMMARY";
    ResourceStringsKey["PAYPALANCETHANKSNOTE"] = "PAYPALANCETHANKSNOTE";
    ResourceStringsKey["HC_PAYPALANCETHANKSNOTE"] = "HC_PAYPALANCETHANKSNOTE";
    ResourceStringsKey["PAYMENTLEFT"] = "PAYMENTLEFT";
    ResourceStringsKey["HC_PAYMENTLEFT"] = "HC_PAYMENTLEFT";
    ResourceStringsKey["HC_DUEON"] = "HC_DUEON";
    ResourceStringsKey["HC_PAYTRIPBALANCE"] = "HC_PAYTRIPBALANCE";
    ResourceStringsKey["HC_HOWMUCHWOULDYOULIKETOPAY"] = "HC_HOWMUCHWOULDYOULIKETOPAY";
    ResourceStringsKey["HC_YOURTRIPWILLBEPAIDINFULL"] = "HC_YOURTRIPWILLBEPAIDINFULL";
    ResourceStringsKey["HC_PAY"] = "HC_PAY";
    ResourceStringsKey["HC_PAYMENTAMOUNTISNOTVALID"] = "HC_PAYMENTAMOUNTISNOTVALID";
    ResourceStringsKey["POSTPAYBALANCEMSG"] = "POSTPAYBALANCEMSG";
    ResourceStringsKey["VIEWDETAILS"] = "VIEWDETAILS";
    ResourceStringsKey["PAYBALANCE"] = "PAYBALANCE";
    ResourceStringsKey["HC_ACCOUNT_PAYBALANCEDISABLED_MESSAGE"] = "HC_ACCOUNT_PAYBALANCEDISABLED_MESSAGE";
    ResourceStringsKey["COOKIES_TITLE_MOBILE"] = "COOKIES_TITLE_MOBILE";
    ResourceStringsKey["COOKIES_MESSAGE_MOBILE"] = "COOKIES_MESSAGE_MOBILE";
    ResourceStringsKey["COOKIES_MESSAGE_MOBILE_BUTTONCAPTION"] = "COOKIES_MESSAGE_MOBILE_BUTTONCAPTION";
    ResourceStringsKey["NOTRANSFERS"] = "NOTRANSFERS";
    ResourceStringsKey["NOTRANSFERSADVICE"] = "NOTRANSFERSADVICE";
    ResourceStringsKey["HC_TRIP_ADVISOR_RATING_TOOLTIP"] = "HC_TRIP_ADVISOR_RATING_TOOLTIP";
    ResourceStringsKey["HC_TRIPADVISOR_TRAVELLERRATING"] = "HC_TRIPADVISOR_TRAVELLERRATING";
    ResourceStringsKey["CSR_STATUS_AWAITING_CUSTOMER_ACKNOWLEDGEMENT"] = "CSR_STATUS_AWAITING_CUSTOMER_ACKNOWLEDGEMENT";
    ResourceStringsKey["CSR_STATUS_AWAITING_CUSTOMER_RESPONSE"] = "CSR_STATUS_AWAITING_CUSTOMER_RESPONSE";
    ResourceStringsKey["CSR_STATUS_COMPLETED"] = "CSR_STATUS_COMPLETED";
    ResourceStringsKey["CSR_STATUS_IN_PROGRESS"] = "CSR_STATUS_IN_PROGRESS";
    ResourceStringsKey["CSR_STATUS_PENDING"] = "CSR_STATUS_PENDING";
    ResourceStringsKey["CSR_H1_TEXT"] = "CSR_H1_TEXT";
    ResourceStringsKey["HC_ACCOUNT_RAISECSR_SUBMITTED_MESSAGE"] = "HC_ACCOUNT_RAISECSR_SUBMITTED_MESSAGE";
    ResourceStringsKey["HC_PAYMENTAMOUNTEXCEEDS"] = "HC_PAYMENTAMOUNTEXCEEDS";
    ResourceStringsKey["HC_MOBILE_CALL_MESSAGE"] = "HC_MOBILE_CALL_MESSAGE";
    ResourceStringsKey["HC_ATTRACTION_WHATSINCLUDEDTEXT"] = "HC_ATTRACTION_WHATSINCLUDEDTEXT";
    ResourceStringsKey["HC_ATTRACTION_IMPORTANTINFORMATION"] = "HC_ATTRACTION_IMPORTANTINFORMATION";
    ResourceStringsKey["BOOKINGSUMMARY_ATTRACTION_REFERENCE"] = "BOOKINGSUMMARY_ATTRACTION_REFERENCE";
    ResourceStringsKey["HC_MARKETING_OVERLAY_TEXT"] = "HC_MARKETING_OVERLAY_TEXT";
    ResourceStringsKey["HC_THEREWASAPROBLEMPROCESSINGYOURPAYMENT"] = "HC_THEREWASAPROBLEMPROCESSINGYOURPAYMENT";
    ResourceStringsKey["HC_ACCOUNT_PAYBALANCE_CARDDETAILSHEADING"] = "HC_ACCOUNT_PAYBALANCE_CARDDETAILSHEADING";
    ResourceStringsKey["HC_TR_ACCOUNT_PAYBALANCE_CARD_ACCEPTANCE_MESSAGE"] = "HC_TR_ACCOUNT_PAYBALANCE_CARD_ACCEPTANCE_MESSAGE";
    ResourceStringsKey["HC_AIRPORT"] = "HC_AIRPORT";
    ResourceStringsKey["RESORT"] = "RESORT";
    ResourceStringsKey["HC_HOW_MANY_PEOPLE"] = "HC_HOW_MANY_PEOPLE";
    ResourceStringsKey["ARRIVING"] = "ARRIVING";
    ResourceStringsKey["RETURNING"] = "RETURNING";
    ResourceStringsKey["HC_READ_MORE"] = "HC_READ_MORE";
    ResourceStringsKey["HC_READ_LESS"] = "HC_READ_LESS";
    ResourceStringsKey["HC_SEARCH_SELECTED_HOTEL"] = "HC_SEARCH_SELECTED_HOTEL";
    ResourceStringsKey["HC_MAX"] = "HC_MAX";
    ResourceStringsKey["HC_MIN"] = "HC_MIN";
    ResourceStringsKey["HC_AVERAGE"] = "HC_AVERAGE";
    ResourceStringsKey["HC_MIN_MAX_LABEL"] = "HC_MIN_MAX_LABEL";
    ResourceStringsKey["HC_ANNUAL_TEMP"] = "HC_ANNUAL_TEMP";
    ResourceStringsKey["HC_HEADER_AND_FOOTER_CONTENT"] = "HC_HEADER_AND_FOOTER_CONTENT";
    ResourceStringsKey["HC_EMAIL"] = "HC_EMAIL";
    ResourceStringsKey["HC_VALIDATE_EMAIL"] = "HC_VALIDATE_EMAIL";
    ResourceStringsKey["NEXT"] = "NEXT";
    ResourceStringsKey["HC_XRESULTS"] = "HC_XRESULTS";
    ResourceStringsKey["HC_XRESULT"] = "HC_XRESULT";
    ResourceStringsKey["CLEARRECENTHOTELS"] = "CLEARRECENTHOTELS";
    ResourceStringsKey["HC_PACKAGE_TRAVEL_REGULATIONS_DISCLAIMER"] = "HC_PACKAGE_TRAVEL_REGULATIONS_DISCLAIMER";
    ResourceStringsKey["HC_RESIDENCY_DISCLAIMER"] = "HC_RESIDENCY_DISCLAIMER";
    ResourceStringsKey["HC_RETRIEVING_QUOTE"] = "HC_RETRIEVING_QUOTE";
    ResourceStringsKey["HC_QUOTE_UNAVAILABLE"] = "HC_QUOTE_UNAVAILABLE";
    ResourceStringsKey["PRICEPERPERSON"] = "PRICEPERPERSON";
    ResourceStringsKey["HC_DEAL"] = "HC_DEAL";
    ResourceStringsKey["HC_PRICING_CALENDAR_TITLE"] = "HC_PRICING_CALENDAR_TITLE";
    ResourceStringsKey["HC_PRICING_CALENDAR_HEADING_NOT_DEAL"] = "HC_PRICING_CALENDAR_HEADING_NOT_DEAL";
    ResourceStringsKey["HC_PRICING_CALENDAR_SUBHEADING_NOT_DEAL"] = "HC_PRICING_CALENDAR_SUBHEADING_NOT_DEAL";
    ResourceStringsKey["HC_PRICING_CALENDAR_HEADING_DEAL"] = "HC_PRICING_CALENDAR_HEADING_DEAL";
    ResourceStringsKey["HC_PRICING_CALENDAR_SUBHEADING_DEAL"] = "HC_PRICING_CALENDAR_SUBHEADING_DEAL";
    ResourceStringsKey["HC_PRICING_CALENDAR_DEPART_HEADING"] = "HC_PRICING_CALENDAR_DEPART_HEADING";
    ResourceStringsKey["HC_PRICING_CALENDAR_DURATION_HEADING"] = "HC_PRICING_CALENDAR_DURATION_HEADING";
    ResourceStringsKey["UPTO_X_PER_HOLIDAY"] = "UPTO_X_PER_HOLIDAY";
    ResourceStringsKey["UPTOX"] = "UPTOX";
    ResourceStringsKey["HC_INSURANCE_TITLE"] = "HC_INSURANCE_TITLE";
    ResourceStringsKey["HC_IMG_TRIPADVISORRATING_URL"] = "HC_IMG_TRIPADVISORRATING_URL";
    ResourceStringsKey["SEARCH_DESTINATION_FIELD_LABEL"] = "SEARCH_DESTINATION_FIELD_LABEL";
    ResourceStringsKey["SEARCH_DESTINATION_FIELD_PLACEHOLDER"] = "SEARCH_DESTINATION_FIELD_PLACEHOLDER";
    ResourceStringsKey["SEARCH_DEPARTURE_FIELD_LABEL"] = "SEARCH_DEPARTURE_FIELD_LABEL";
    ResourceStringsKey["SEARCH_ARRIVAL_FIELD_LABEL"] = "SEARCH_ARRIVAL_FIELD_LABEL";
    ResourceStringsKey["SEARCH_AIRPORTS_FIELD_PLACEHOLDER"] = "SEARCH_AIRPORTS_FIELD_PLACEHOLDER";
    ResourceStringsKey["SEARCH_DATES_FIELD_LABEL_HOLIDAY"] = "SEARCH_DATES_FIELD_LABEL_HOLIDAY";
    ResourceStringsKey["SEARCH_DATES_FIELD_LABEL_HOTELONLY"] = "SEARCH_DATES_FIELD_LABEL_HOTELONLY";
    ResourceStringsKey["COLLAPSED_SEARCH_CTA_LABEL"] = "COLLAPSED_SEARCH_CTA_LABEL";
    ResourceStringsKey["HC_CHOOSE_DATES"] = "HC_CHOOSE_DATES";
    ResourceStringsKey["HC_FLEXIBLE_DATES"] = "HC_FLEXIBLE_DATES";
    ResourceStringsKey["HC_FLEXIBLE_DAY_TEXT"] = "HC_FLEXIBLE_DAY_TEXT";
    ResourceStringsKey["HC_FLEXIBLE_DAYS_TEXT"] = "HC_FLEXIBLE_DAYS_TEXT";
    ResourceStringsKey["HC_FLEXIBLE_DAYS_EXACT_TEXT"] = "HC_FLEXIBLE_DAYS_EXACT_TEXT";
    ResourceStringsKey["RESULT_COUNT_MESSAGE"] = "RESULT_COUNT_MESSAGE";
    ResourceStringsKey["RESULTS_COUNT_MESSAGE"] = "RESULTS_COUNT_MESSAGE";
    ResourceStringsKey["SEARCH_DEPARTURE_OPTIONS_TITLE"] = "SEARCH_DEPARTURE_OPTIONS_TITLE";
    ResourceStringsKey["SEARCH_ARRIVAL_OPTIONS_TITLE"] = "SEARCH_ARRIVAL_OPTIONS_TITLE";
    ResourceStringsKey["SEARCH_USP_4"] = "SEARCH_USP_4";
    ResourceStringsKey["DEAL_CARD_ITEM_FROM"] = "DEAL_CARD_ITEM_FROM";
    ResourceStringsKey["PRICE_SEARCH_MORE"] = "PRICE_SEARCH_MORE";
    ResourceStringsKey["HC_PAYMENTMETHOD_PAYPAL_PAYLATER"] = "HC_PAYMENTMETHOD_PAYPAL_PAYLATER";
    ResourceStringsKey["HC_PAYMENTMETHOD_PAYPAL_PAYLATER_SUBTITLE"] = "HC_PAYMENTMETHOD_PAYPAL_PAYLATER_SUBTITLE";
    ResourceStringsKey["HC_NO_TRANSFER_EXTRAS_SELECTED_AIRPORT"] = "HC_NO_TRANSFER_EXTRAS_SELECTED_AIRPORT";
    ResourceStringsKey["HOTELSRPCARD_CTA_LABEL"] = "HOTELSRPCARD_CTA_LABEL";
    ResourceStringsKey["HOTELSRPCARD_PRICECAPTION"] = "HOTELSRPCARD_PRICECAPTION";
    ResourceStringsKey["HOTELSRPCARD_PRICECAPTION_PERPERSON"] = "HOTELSRPCARD_PRICECAPTION_PERPERSON";
    ResourceStringsKey["PAYMENTMETHOD_PAYPALV2"] = "PAYMENTMETHOD_PAYPALV2";
    ResourceStringsKey["HC_PAY_LATER"] = "HC_PAY_LATER";
    ResourceStringsKey["HC_PRODUCTINFOLINK_ESSENTIAL"] = "HC_PRODUCTINFOLINK_ESSENTIAL";
    ResourceStringsKey["HC_PRODUCTINFOLINK_SUPER"] = "HC_PRODUCTINFOLINK_SUPER";
    ResourceStringsKey["MYB_TRIPCARD_CTA_VIEWTRIP"] = "MYB_TRIPCARD_CTA_VIEWTRIP";
    ResourceStringsKey["MYB_TRIPCARD_CTA_MANAGETRIP"] = "MYB_TRIPCARD_CTA_MANAGETRIP";
    ResourceStringsKey["MYB_BOOKINGCARD_CTA_VIEWBOOKING"] = "MYB_BOOKINGCARD_CTA_VIEWBOOKING";
    ResourceStringsKey["MYB_BOOKINGCARD_CTA_MANAGEBOOKING"] = "MYB_BOOKINGCARD_CTA_MANAGEBOOKING";
    ResourceStringsKey["HC_GENERICPAYMENTASSISTENCE"] = "HC_GENERICPAYMENTASSISTENCE";
    ResourceStringsKey["MYB_BOOKING_DOCUMENT_HOTEL_CTA_TEXT"] = "MYB_BOOKING_DOCUMENT_HOTEL_CTA_TEXT";
    ResourceStringsKey["MYB_BOOKING_DOCUMENT_FLIGHT_CTA_TEXT"] = "MYB_BOOKING_DOCUMENT_FLIGHT_CTA_TEXT";
    ResourceStringsKey["MYB_BOOKING_DOCUMENT_TRANSFER_CTA_TEXT"] = "MYB_BOOKING_DOCUMENT_TRANSFER_CTA_TEXT";
    ResourceStringsKey["MYB_BOOKING_DOCUMENT_INSURANCE_CTA_TEXT"] = "MYB_BOOKING_DOCUMENT_INSURANCE_CTA_TEXT";
    ResourceStringsKey["MYB_BOOKING_DOCUMENT_DEFAULT_CTA_TEXT"] = "MYB_BOOKING_DOCUMENT_DEFAULT_CTA_TEXT";
    ResourceStringsKey["MYB_BOOKING_DOCUMENT_DEFAULT_SMALL_CTA_TEXT"] = "MYB_BOOKING_DOCUMENT_DEFAULT_SMALL_CTA_TEXT";
    ResourceStringsKey["MYB_BOOKING_ROOM_SUMMARY_TYPE_LABEL"] = "MYB_BOOKING_ROOM_SUMMARY_TYPE_LABEL";
    ResourceStringsKey["MYB_BOOKING_ROOM_SUMMARY_BOARD_LABEL"] = "MYB_BOOKING_ROOM_SUMMARY_BOARD_LABEL";
    ResourceStringsKey["MYB_BOOKING_ROOM_SUMMARY_OCCUPANCY_LABEL"] = "MYB_BOOKING_ROOM_SUMMARY_OCCUPANCY_LABEL";
    ResourceStringsKey["MYB_ALLTRIPS_FUTURETAB"] = "MYB_ALLTRIPS_FUTURETAB";
    ResourceStringsKey["MYB_ALLTRIPS_PASTTAB"] = "MYB_ALLTRIPS_PASTTAB";
    ResourceStringsKey["MYB_ALLTRIPS_CANCELLEDTAB"] = "MYB_ALLTRIPS_CANCELLEDTAB";
    ResourceStringsKey["MYB_BOOKINGCARD_ALERT_CSR_PENDING"] = "MYB_BOOKINGCARD_ALERT_CSR_PENDING";
    ResourceStringsKey["MYB_BOOKINGCARD_ALERT_CSR_INPROGRESS"] = "MYB_BOOKINGCARD_ALERT_CSR_INPROGRESS";
    ResourceStringsKey["MYB_BOOKINGCARD_ALERT_CSR_COMPLETED"] = "MYB_BOOKINGCARD_ALERT_CSR_COMPLETED";
    ResourceStringsKey["MYB_BOOKINGCARD_ALERT_CANCELLATION_PENDING"] = "MYB_BOOKINGCARD_ALERT_CANCELLATION_PENDING";
    ResourceStringsKey["MYB_BOOKINGCARD_ALERT_CANCELLATION_INPROGRESS"] = "MYB_BOOKINGCARD_ALERT_CANCELLATION_INPROGRESS";
    ResourceStringsKey["MYB_BOOKINGCARD_ALERT_CANCELLATION_COMPLETED"] = "MYB_BOOKINGCARD_ALERT_CANCELLATION_COMPLETED";
    ResourceStringsKey["MYB_BOOKINGCARD_ALERT_CSR_DEFAULT"] = "MYB_BOOKINGCARD_ALERT_CSR_DEFAULT";
    ResourceStringsKey["MYB_TRIP_OVERVIEW_CANCELLED_PILL"] = "MYB_TRIP_OVERVIEW_CANCELLED_PILL";
    ResourceStringsKey["MYB_MANAGETRIP_ADDTRANSFER_BUTTON"] = "MYB_MANAGETRIP_ADDTRANSFER_BUTTON";
    ResourceStringsKey["HC_MERCH_CARD_DEFAULT_BUTTON"] = "HC_MERCH_CARD_DEFAULT_BUTTON";
    ResourceStringsKey["NONCACHED_FIELD_ERROR_MESSAGE"] = "NONCACHED_FIELD_ERROR_MESSAGE";
    ResourceStringsKey["HC_SRPHEADING_MULTIHOLIDAY"] = "HC_SRPHEADING_MULTIHOLIDAY";
    ResourceStringsKey["HC_SRPHEADING_MULTIHOTEL"] = "HC_SRPHEADING_MULTIHOTEL";
    ResourceStringsKey["HC_SRPHEADING_MULTIHOLIDAY_LOADING"] = "HC_SRPHEADING_MULTIHOLIDAY_LOADING";
    ResourceStringsKey["HC_SRPHEADING_MULTIHOTEL_LOADING"] = "HC_SRPHEADING_MULTIHOTEL_LOADING";
    ResourceStringsKey["HC_HOLIDAYS_SECURETODAYFROM"] = "HC_HOLIDAYS_SECURETODAYFROM";
    ResourceStringsKey["HC_OUTBOUND_FLIGHT"] = "HC_OUTBOUND_FLIGHT";
    ResourceStringsKey["HC_RETURN_FLIGHT"] = "HC_RETURN_FLIGHT";
    ResourceStringsKey["HC_INBOUND_TRANSFER"] = "HC_INBOUND_TRANSFER";
    ResourceStringsKey["HC_OUTBOUND_TRANSFER"] = "HC_OUTBOUND_TRANSFER";
    ResourceStringsKey["HC_VIEW_TRANSFER_DETAILS"] = "HC_VIEW_TRANSFER_DETAILS";
    ResourceStringsKey["HC_TRANSFER_TOTAL"] = "HC_TRANSFER_TOTAL";
    ResourceStringsKey["HC_TRANSFER_PRICE"] = "HC_TRANSFER_PRICE";
    ResourceStringsKey["FLIGHT_ARRIVING_AT"] = "FLIGHT_ARRIVING_AT";
    ResourceStringsKey["FLIGHT_DEPARTING_AT"] = "FLIGHT_DEPARTING_AT";
    ResourceStringsKey["HC_MULTI_DEST_EXTENDEDFLIGHT_NOTIFICATION"] = "HC_MULTI_DEST_EXTENDEDFLIGHT_NOTIFICATION";
    ResourceStringsKey["HC_FLIGHT_RETRY_HEADING"] = "HC_FLIGHT_RETRY_HEADING";
    ResourceStringsKey["HC_FLIGHT_RETRY_BODY"] = "HC_FLIGHT_RETRY_BODY";
    ResourceStringsKey["HC_FLIGHT_RETRY_LINK"] = "HC_FLIGHT_RETRY_LINK";
    ResourceStringsKey["HC_PRICING_CALENDAR_HEADING_NONCACHED"] = "HC_PRICING_CALENDAR_HEADING_NONCACHED";
    ResourceStringsKey["HC_PRICING_CALENDAR_SUBHEADING_NONCACHED"] = "HC_PRICING_CALENDAR_SUBHEADING_NONCACHED";
    ResourceStringsKey["HC_NEWSLETTER_SOFT_OPT_IN"] = "HC_NEWSLETTER_SOFT_OPT_IN";
    ResourceStringsKey["HC_NEWSLETTER_HARD_OPT_IN"] = "HC_NEWSLETTER_HARD_OPT_IN";
    ResourceStringsKey["HOTEL_DETAILS_TAB_OVERVIEW"] = "HOTEL_DETAILS_TAB_OVERVIEW";
    ResourceStringsKey["HOTEL_DETAILS_TAB_ROOMS"] = "HOTEL_DETAILS_TAB_ROOMS";
    ResourceStringsKey["HOTEL_DETAILS_TAB_REVIEWS"] = "HOTEL_DETAILS_TAB_REVIEWS";
    ResourceStringsKey["HOTEL_DETAILS_REVIEWSNIPPET_LEGEND"] = "HOTEL_DETAILS_REVIEWSNIPPET_LEGEND";
    ResourceStringsKey["CONFIRM_ROOMS_CTA_HOTELS"] = "CONFIRM_ROOMS_CTA_HOTELS";
    ResourceStringsKey["CONFIRM_ROOMS_CTA_HOLIDAYS"] = "CONFIRM_ROOMS_CTA_HOLIDAYS";
    ResourceStringsKey["PRICESFROM"] = "PRICESFROM";
    ResourceStringsKey["HC_EXTRAS_FLIGHTS_DETAILS_HEADING"] = "HC_EXTRAS_FLIGHTS_DETAILS_HEADING";
    ResourceStringsKey["HC_SELECTED_TRANSFER"] = "HC_SELECTED_TRANSFER";
    ResourceStringsKey["HC_MAXIMUM_WEIGHT"] = "HC_MAXIMUM_WEIGHT";
    ResourceStringsKey["HC_MAXIMUM_SIZE"] = "HC_MAXIMUM_SIZE";
    ResourceStringsKey["HC_EXTRAS_MT_INBOUND_INFORMATION"] = "HC_EXTRAS_MT_INBOUND_INFORMATION";
    ResourceStringsKey["HC_EXTRAS_MT_OUTBOUND_INFORMATION"] = "HC_EXTRAS_MT_OUTBOUND_INFORMATION";
    ResourceStringsKey["HC_IMPORTANT_INFORMATION"] = "HC_IMPORTANT_INFORMATION";
    ResourceStringsKey["HC_MYBCANCELBUTTONTEXT"] = "HC_MYBCANCELBUTTONTEXT";
    ResourceStringsKey["HC_PAYBALANCEBUTTON"] = "HC_PAYBALANCEBUTTON";
    ResourceStringsKey["LOGIN_SIGN_IN_LABEL"] = "LOGIN_SIGN_IN_LABEL";
    ResourceStringsKey["EDIT_ADDRESS"] = "EDIT_ADDRESS";
    ResourceStringsKey["LOGIN_SIGNED_IN_LABEL"] = "LOGIN_SIGNED_IN_LABEL";
    ResourceStringsKey["HC_SUPPORTCENTRE_LABEL"] = "HC_SUPPORTCENTRE_LABEL";
    ResourceStringsKey["HOTELS_SRP_TITLE"] = "HOTELS_SRP_TITLE";
    ResourceStringsKey["HOTELS_SRP_TITLE_SINGULAR"] = "HOTELS_SRP_TITLE_SINGULAR";
    ResourceStringsKey["HOTELS_SRP_TITLE_LOADING"] = "HOTELS_SRP_TITLE_LOADING";
    ResourceStringsKey["HOLIDAY_HOTELS_SRP_TITLE"] = "HOLIDAY_HOTELS_SRP_TITLE";
    ResourceStringsKey["HOLIDAY_HOTELS_SRP_TITLE_SINGULAR"] = "HOLIDAY_HOTELS_SRP_TITLE_SINGULAR";
    ResourceStringsKey["HOLIDAY_HOTELS_SRP_TITLE_LOADING"] = "HOLIDAY_HOTELS_SRP_TITLE_LOADING";
    ResourceStringsKey["FLIGHTS_SRP_TITLE"] = "FLIGHTS_SRP_TITLE";
    ResourceStringsKey["FLIGHTS_SRP_TITLE_SINGULAR"] = "FLIGHTS_SRP_TITLE_SINGULAR";
    ResourceStringsKey["FLIGHTS_SRP_TITLE_LOADING"] = "FLIGHTS_SRP_TITLE_LOADING";
    ResourceStringsKey["HOLIDAYS_ATOL_PROTECTED_PROMPT"] = "HOLIDAYS_ATOL_PROTECTED_PROMPT";
    ResourceStringsKey["HC_SELECTED_SEATS"] = "HC_SELECTED_SEATS";
    ResourceStringsKey["HC_DEPARTURE_AIRPORT"] = "HC_DEPARTURE_AIRPORT";
    ResourceStringsKey["HC_MORE_INSURANCE_INFO"] = "HC_MORE_INSURANCE_INFO";
    ResourceStringsKey["FILTERS_DIALOG_BUTTON_LABEL"] = "FILTERS_DIALOG_BUTTON_LABEL";
    ResourceStringsKey["FILTERS_DIALOG_BUTTON_LABEL_SINGLUAR"] = "FILTERS_DIALOG_BUTTON_LABEL_SINGLUAR";
    ResourceStringsKey["FILTERS_DIALOG_BUTTON_LABEL_LOADING"] = "FILTERS_DIALOG_BUTTON_LABEL_LOADING";
    ResourceStringsKey["VOUCHER_CODE_FIELD_LABEL"] = "VOUCHER_CODE_FIELD_LABEL";
    ResourceStringsKey["HC_ADDED_HOLD_LUGGAGE"] = "HC_ADDED_HOLD_LUGGAGE";
    ResourceStringsKey["HC_ADDED_ATTRACTION"] = "HC_ADDED_ATTRACTION";
    ResourceStringsKey["HC_ADDED_SEATS"] = "HC_ADDED_SEATS";
    ResourceStringsKey["HC_ADDED_TRAVEL_INSURANCE"] = "HC_ADDED_TRAVEL_INSURANCE";
    ResourceStringsKey["HC_SELECTED_TRAVEL_INSURANCE"] = "HC_SELECTED_TRAVEL_INSURANCE";
    ResourceStringsKey["HOTELCLICKEDBYUSERHEADER"] = "HOTELCLICKEDBYUSERHEADER";
    ResourceStringsKey["HC_LOGIN_SIGN_IN_DESCRIPTION"] = "HC_LOGIN_SIGN_IN_DESCRIPTION";
    ResourceStringsKey["HC_LOGIN_SIGN_IN_CTA_LABEL"] = "HC_LOGIN_SIGN_IN_CTA_LABEL";
    ResourceStringsKey["HC_LOGIN_SIGN_IN_CTA_LABEL_BACKTOEMAIL"] = "HC_LOGIN_SIGN_IN_CTA_LABEL_BACKTOEMAIL";
    ResourceStringsKey["HC_LOGIN_SIGN_UP_CTA_LABEL_CHECKEMAIL"] = "HC_LOGIN_SIGN_UP_CTA_LABEL_CHECKEMAIL";
    ResourceStringsKey["HC_LOGIN_SIGN_UP_CHECKEMAIL_EMAILPARAGRAPH"] = "HC_LOGIN_SIGN_UP_CHECKEMAIL_EMAILPARAGRAPH";
    ResourceStringsKey["HC_LOGIN_SIGN_UP_CHECKEMAIL_TIMETOCHECK"] = "HC_LOGIN_SIGN_UP_CHECKEMAIL_TIMETOCHECK";
    ResourceStringsKey["HC_LOGIN_SIGN_UP_CHECKEMAIL_RESEND_LINK_LABEL"] = "HC_LOGIN_SIGN_UP_CHECKEMAIL_RESEND_LINK_LABEL";
    ResourceStringsKey["LOGIN_FORGOT_CHECK_INSTRUCTIONS"] = "LOGIN_FORGOT_CHECK_INSTRUCTIONS";
    ResourceStringsKey["LOGIN_FORGOT_CHECK_PARAGRAPH"] = "LOGIN_FORGOT_CHECK_PARAGRAPH";
    ResourceStringsKey["LOGIN_FORGOT_CHECK_VERIFY_LINK_TEXT"] = "LOGIN_FORGOT_CHECK_VERIFY_LINK_TEXT";
    ResourceStringsKey["FUNNEL_FOOTER_HOLIDAY_TOTAL_CAPTION"] = "FUNNEL_FOOTER_HOLIDAY_TOTAL_CAPTION";
    ResourceStringsKey["FUNNEL_FOOTER_HOTEL_TOTAL_CAPTION"] = "FUNNEL_FOOTER_HOTEL_TOTAL_CAPTION";
    ResourceStringsKey["HC_PASSENGERS_RETURN_JOURNEY"] = "HC_PASSENGERS_RETURN_JOURNEY";
    ResourceStringsKey["TOTALSEATPRICE"] = "TOTALSEATPRICE";
    ResourceStringsKey["CLEARUSERPROFILE"] = "CLEARUSERPROFILE";
    ResourceStringsKey["HC_CLEAR_ALL"] = "HC_CLEAR_ALL";
    ResourceStringsKey["HC_SEARCH_SELECT_DATE"] = "HC_SEARCH_SELECT_DATE";
    ResourceStringsKey["HC_SEARCH_ERROR_MISSING_MONTH"] = "HC_SEARCH_ERROR_MISSING_MONTH";
    ResourceStringsKey["HC_SEARCH_MONTH_VIEW_SELECT_DATE"] = "HC_SEARCH_MONTH_VIEW_SELECT_DATE";
    ResourceStringsKey["MYBNOTRIPSPLACEHOLDERTRASFERSCARDTITLE"] = "MYBNOTRIPSPLACEHOLDERTRASFERSCARDTITLE";
    ResourceStringsKey["MYBNOTRIPSPLACEHOLDERTRASFERSCARDSUBTITLE"] = "MYBNOTRIPSPLACEHOLDERTRASFERSCARDSUBTITLE";
    ResourceStringsKey["MYBNOTRIPSPLACEHOLDERTRASFERSCARDLINKURL"] = "MYBNOTRIPSPLACEHOLDERTRASFERSCARDLINKURL";
    ResourceStringsKey["MYBNOTRIPSPLACEHOLDERTRASFERSCARDLINK"] = "MYBNOTRIPSPLACEHOLDERTRASFERSCARDLINK";
    ResourceStringsKey["MYBNOTRIPSPLACEHOLDERHOTELCARDTITLE"] = "MYBNOTRIPSPLACEHOLDERHOTELCARDTITLE";
    ResourceStringsKey["MYBNOTRIPSPLACEHOLDERHOTELCARDSUBTITLE"] = "MYBNOTRIPSPLACEHOLDERHOTELCARDSUBTITLE";
    ResourceStringsKey["MYBNOTRIPSPLACEHOLDERHOTELCARDLINKURL"] = "MYBNOTRIPSPLACEHOLDERHOTELCARDLINKURL";
    ResourceStringsKey["MYBNOTRIPSPLACEHOLDERHOTELCARDLINK"] = "MYBNOTRIPSPLACEHOLDERHOTELCARDLINK";
    ResourceStringsKey["MYBNOTRIPSPLACEHOLDERHOLIDAYCARDTITLE"] = "MYBNOTRIPSPLACEHOLDERHOLIDAYCARDTITLE";
    ResourceStringsKey["MYBNOTRIPSPLACEHOLDERHOLIDAYCARDSUBTITLE"] = "MYBNOTRIPSPLACEHOLDERHOLIDAYCARDSUBTITLE";
    ResourceStringsKey["MYBNOTRIPSPLACEHOLDERHOLIDAYCARDLINKURL"] = "MYBNOTRIPSPLACEHOLDERHOLIDAYCARDLINKURL";
    ResourceStringsKey["MYBNOTRIPSPLACEHOLDERHOLIDAYCARDLINK"] = "MYBNOTRIPSPLACEHOLDERHOLIDAYCARDLINK";
    ResourceStringsKey["MYBNOTRIPSPLACEHOLDERSUBTITLE"] = "MYBNOTRIPSPLACEHOLDERSUBTITLE";
    ResourceStringsKey["MYBNOTRIPSPLACEHOLDERHEADER"] = "MYBNOTRIPSPLACEHOLDERHEADER";
    ResourceStringsKey["HC_ESTAB_LANDING_CALENDAR_HEADING_HOLIDAY"] = "HC_ESTAB_LANDING_CALENDAR_HEADING_HOLIDAY";
    ResourceStringsKey["HC_ESTAB_LANDING_CALENDAR_HEADING_HOTEL"] = "HC_ESTAB_LANDING_CALENDAR_HEADING_HOTEL";
    ResourceStringsKey["MYB_ALLTRIPS_PAGETITLE"] = "MYB_ALLTRIPS_PAGETITLE";
    ResourceStringsKey["MYB_ALLTRIPS_PAGESUBTITLE"] = "MYB_ALLTRIPS_PAGESUBTITLE";
    ResourceStringsKey["MYB_ALLTRIPS_SHOWTRIPS"] = "MYB_ALLTRIPS_SHOWTRIPS";
    ResourceStringsKey["MYB_ALLTRIPS_HIDETRIPS"] = "MYB_ALLTRIPS_HIDETRIPS";
    ResourceStringsKey["MYB_ALLTRIPS_NOCANCELLEDTRIPS"] = "MYB_ALLTRIPS_NOCANCELLEDTRIPS";
    ResourceStringsKey["MYB_ALLTRIPS_NOPASTTRIPS"] = "MYB_ALLTRIPS_NOPASTTRIPS";
    ResourceStringsKey["MYB_ALLTRIPS_TOTALPRICE"] = "MYB_ALLTRIPS_TOTALPRICE";
    ResourceStringsKey["MYB_TRIPCARD_CTA_SINGLEMESSAGE"] = "MYB_TRIPCARD_CTA_SINGLEMESSAGE";
    ResourceStringsKey["MYB_TRIPCARD_CTA_MULTIPLEMESSAGES"] = "MYB_TRIPCARD_CTA_MULTIPLEMESSAGES";
    ResourceStringsKey["MYB_ALLTRIPS_NEXTTRIPS_TITLE"] = "MYB_ALLTRIPS_NEXTTRIPS_TITLE";
    ResourceStringsKey["MYB_ALLTRIPS_NEXTTRIPS_SUBTITLE"] = "MYB_ALLTRIPS_NEXTTRIPS_SUBTITLE";
    ResourceStringsKey["HC_HOTELROOMS_NAVL_TITLE"] = "HC_HOTELROOMS_NAVL_TITLE";
    ResourceStringsKey["HC_HOTELROOMS_NAVL_BODY"] = "HC_HOTELROOMS_NAVL_BODY";
    ResourceStringsKey["HC_ESTAB_LANDING_PRICE_CALENDAR_HEADING"] = "HC_ESTAB_LANDING_PRICE_CALENDAR_HEADING";
    ResourceStringsKey["MYBCOMPLAINTFORMTOPDESCRIPTION"] = "MYBCOMPLAINTFORMTOPDESCRIPTION";
    ResourceStringsKey["MYBCOMPLAINTFORMHANDSDESCRIPTION"] = "MYBCOMPLAINTFORMHANDSDESCRIPTION";
    ResourceStringsKey["MYBCOMPLAINTFORMHANDSLABEL"] = "MYBCOMPLAINTFORMHANDSLABEL";
    ResourceStringsKey["MYBCOMPLAINTFORMCOMMMODELABEL"] = "MYBCOMPLAINTFORMCOMMMODELABEL";
    ResourceStringsKey["MYBCOMPLAINTFORMEMAILLABEL"] = "MYBCOMPLAINTFORMEMAILLABEL";
    ResourceStringsKey["MYBCOMPLAINTFORMOUTCOMELABEL"] = "MYBCOMPLAINTFORMOUTCOMELABEL";
    ResourceStringsKey["MYBCOMPLAINTFORMOUTCOMEERROR"] = "MYBCOMPLAINTFORMOUTCOMEERROR";
    ResourceStringsKey["MYBCOMPLAINTFORMDETAILSERROR"] = "MYBCOMPLAINTFORMDETAILSERROR";
    ResourceStringsKey["MYBCOMPLAINTFORMDETAILSLABEL"] = "MYBCOMPLAINTFORMDETAILSLABEL";
    ResourceStringsKey["MYBCOMPLAINTBUTTONLABEL"] = "MYBCOMPLAINTBUTTONLABEL";
    ResourceStringsKey["SEARCHUNITGUESTPICKERLABEL"] = "SEARCHUNITGUESTPICKERLABEL";
    ResourceStringsKey["SEARCH_PICKUP_POINT_ERROR_EMPTY"] = "SEARCH_PICKUP_POINT_ERROR_EMPTY";
    ResourceStringsKey["SEARCH_PICKUP_POINT_FIELD_LABEL"] = "SEARCH_PICKUP_POINT_FIELD_LABEL";
    ResourceStringsKey["SEARCH_PICKUP_POINT_FIELD_PLACEHOLDER"] = "SEARCH_PICKUP_POINT_FIELD_PLACEHOLDER";
    ResourceStringsKey["SEARCH_DROPOFF_POINT_ERROR_EMPTY"] = "SEARCH_DROPOFF_POINT_ERROR_EMPTY";
    ResourceStringsKey["SEARCH_DROPOFF_POINT_FIELD_LABEL"] = "SEARCH_DROPOFF_POINT_FIELD_LABEL";
    ResourceStringsKey["HC_NO_EXTRAS_HOTEL"] = "HC_NO_EXTRAS_HOTEL";
    ResourceStringsKey["MYB_ACCOUNTMENU_LOGOUT"] = "MYB_ACCOUNTMENU_LOGOUT";
    ResourceStringsKey["MYB_ACCOUNTMENU_SETTINGS"] = "MYB_ACCOUNTMENU_SETTINGS";
    ResourceStringsKey["MYB_ACCOUNTMENU_INBOX"] = "MYB_ACCOUNTMENU_INBOX";
    ResourceStringsKey["MYB_ACCOUNTMENU_MYTRIPS"] = "MYB_ACCOUNTMENU_MYTRIPS";
    ResourceStringsKey["HC_PLAY_VIDEO"] = "HC_PLAY_VIDEO";
    ResourceStringsKey["MYB_AMENDMENTS_SUBMIT_BUTTON_TEXT"] = "MYB_AMENDMENTS_SUBMIT_BUTTON_TEXT";
    ResourceStringsKey["MYB_AMENDMENTS_DIALOG_TITLE_PANEL_QUOTE"] = "MYB_AMENDMENTS_DIALOG_TITLE_PANEL_QUOTE";
    ResourceStringsKey["MYB_ACCOUNT_AMENDMENTS_PANEL_TITLE"] = "MYB_ACCOUNT_AMENDMENTS_PANEL_TITLE";
    ResourceStringsKey["MYB_ACCOUNT_AMENDMENTS_PANEL_TEXT"] = "MYB_ACCOUNT_AMENDMENTS_PANEL_TEXT";
    ResourceStringsKey["MYB_ACCOUNT_AMENDMENTS_PANEL_DATE_BTN"] = "MYB_ACCOUNT_AMENDMENTS_PANEL_DATE_BTN";
    ResourceStringsKey["MYB_ACCOUNT_AMENDMENTS_PANEL_CANCEL_BTN"] = "MYB_ACCOUNT_AMENDMENTS_PANEL_CANCEL_BTN";
    ResourceStringsKey["MYB_ACCOUNT_AMENDMENTS_PANEL_CHIP_TEXT"] = "MYB_ACCOUNT_AMENDMENTS_PANEL_CHIP_TEXT";
    ResourceStringsKey["MYB_ACCOUNT_AMENDMENTS_RESULTS_BOARD_FLEXIBLE"] = "MYB_ACCOUNT_AMENDMENTS_RESULTS_BOARD_FLEXIBLE";
    ResourceStringsKey["MYB_ACCOUNT_AMENDMENTS_RESULTS_BOARDS_AVAILABLE"] = "MYB_ACCOUNT_AMENDMENTS_RESULTS_BOARDS_AVAILABLE";
    ResourceStringsKey["MYB_ACCOUNT_AMENDMENTS_RESULTS_BOARD_PRICES_LABEL"] = "MYB_ACCOUNT_AMENDMENTS_RESULTS_BOARD_PRICES_LABEL";
    ResourceStringsKey["MYB_ACCOUNT_AMENDMENTS_RESULTS_PHONE_NUMBER"] = "MYB_ACCOUNT_AMENDMENTS_RESULTS_PHONE_NUMBER";
    ResourceStringsKey["MYB_ACCOUNT_AMENDMENTS_RESULTS_SUCCESS_TITLE"] = "MYB_ACCOUNT_AMENDMENTS_RESULTS_SUCCESS_TITLE";
    ResourceStringsKey["MYB_ACCOUNT_AMENDMENTS_RESULTS_FAIL_TITLE"] = "MYB_ACCOUNT_AMENDMENTS_RESULTS_FAIL_TITLE";
    ResourceStringsKey["MYB_ACCOUNT_AMENDMENTS_RESULTS_NORESULTS_TITLE"] = "MYB_ACCOUNT_AMENDMENTS_RESULTS_NORESULTS_TITLE";
    ResourceStringsKey["MYB_ACCOUNT_AMENDMENTS_RESULTS_SUCCESS_TEXT"] = "MYB_ACCOUNT_AMENDMENTS_RESULTS_SUCCESS_TEXT";
    ResourceStringsKey["MYB_ACCOUNT_AMENDMENTS_RESULTS_FAIL_TEXT"] = "MYB_ACCOUNT_AMENDMENTS_RESULTS_FAIL_TEXT";
    ResourceStringsKey["MYB_ACCOUNT_AMENDMENTS_RESULTS_NORESULTS_TEXT"] = "MYB_ACCOUNT_AMENDMENTS_RESULTS_NORESULTS_TEXT";
    ResourceStringsKey["MYB_ACCOUNT_AMENDMENTS_RESULTS_UPGRADE_TEXT"] = "MYB_ACCOUNT_AMENDMENTS_RESULTS_UPGRADE_TEXT";
    ResourceStringsKey["MYB_ACCOUNT_AMENDMENTS_RESULTS_UPGRADE_TITLE"] = "MYB_ACCOUNT_AMENDMENTS_RESULTS_UPGRADE_TITLE";
    ResourceStringsKey["MYB_ACCOUNT_AMENDMENTS_RESULTS_BREAKDOWN_TITLE"] = "MYB_ACCOUNT_AMENDMENTS_RESULTS_BREAKDOWN_TITLE";
    ResourceStringsKey["MYB_ACCOUNT_AMENDMENTS_RESULTS_BD_CANCEL_LABEL"] = "MYB_ACCOUNT_AMENDMENTS_RESULTS_BD_CANCEL_LABEL";
    ResourceStringsKey["MYB_ACCOUNT_AMENDMENTS_RESULTS_BD_HOTEL_LABEL"] = "MYB_ACCOUNT_AMENDMENTS_RESULTS_BD_HOTEL_LABEL";
    ResourceStringsKey["MYB_ACCOUNT_AMENDMENTS_RESULTS_BD_DIFF_LABEL"] = "MYB_ACCOUNT_AMENDMENTS_RESULTS_BD_DIFF_LABEL";
    ResourceStringsKey["MYB_ACCOUNT_AMENDMENTS_RESULTS_BACK_BUTTON_LABEL"] = "MYB_ACCOUNT_AMENDMENTS_RESULTS_BACK_BUTTON_LABEL";
    ResourceStringsKey["MYB_ACCOUNT_AMENDMENTS_RESULTS_TOTAL_COST"] = "MYB_ACCOUNT_AMENDMENTS_RESULTS_TOTAL_COST";
    ResourceStringsKey["MYB_ACCOUNT_AMENDMENTS_RESULTS_TOTAL_SAVINGS"] = "MYB_ACCOUNT_AMENDMENTS_RESULTS_TOTAL_SAVINGS";
    ResourceStringsKey["HC_BUDGET_FILTER_MIN_VAL_HIGHER_ERROR"] = "HC_BUDGET_FILTER_MIN_VAL_HIGHER_ERROR";
    ResourceStringsKey["HC_BUDGET_FILTER_MAX_VAL_LOWER_ERROR"] = "HC_BUDGET_FILTER_MAX_VAL_LOWER_ERROR";
    ResourceStringsKey["HC_BUDGET_FILTER_MIN_VAL_LOW_ERROR"] = "HC_BUDGET_FILTER_MIN_VAL_LOW_ERROR";
    ResourceStringsKey["HC_BUDGET_FILTER_MAX_VAL_HIGH_ERROR"] = "HC_BUDGET_FILTER_MAX_VAL_HIGH_ERROR";
    ResourceStringsKey["TRANSFERS_RESULTS_HEADING"] = "TRANSFERS_RESULTS_HEADING";
    ResourceStringsKey["TRANSFERS_RESULTS_HEADING_SELECT"] = "TRANSFERS_RESULTS_HEADING_SELECT";
    ResourceStringsKey["TRANSFERS_RESULTS_HEADING_LOADING"] = "TRANSFERS_RESULTS_HEADING_LOADING";
    ResourceStringsKey["TRANSFERS_NORESULTS_HEADING"] = "TRANSFERS_NORESULTS_HEADING";
    ResourceStringsKey["TRANSFERS_NORESULTS_MESSAGE"] = "TRANSFERS_NORESULTS_MESSAGE";
    ResourceStringsKey["HC_RANGE"] = "HC_RANGE";
    ResourceStringsKey["SEARCH_ARRIVALDATE_PROMPT"] = "SEARCH_ARRIVALDATE_PROMPT";
    ResourceStringsKey["SEARCH_DEPARTUREDATE_PROMPT"] = "SEARCH_DEPARTUREDATE_PROMPT";
    ResourceStringsKey["TRANSFERS_DETAILS_ERROR_TITLE"] = "TRANSFERS_DETAILS_ERROR_TITLE";
    ResourceStringsKey["TRANSFERS_FLIGHTDETAILS_OUTBOUND_ERROR_TEXT"] = "TRANSFERS_FLIGHTDETAILS_OUTBOUND_ERROR_TEXT";
    ResourceStringsKey["TRANSFERS_FLIGHTDETAILS_INBOUND_ERROR_TEXT"] = "TRANSFERS_FLIGHTDETAILS_INBOUND_ERROR_TEXT";
    ResourceStringsKey["TRANSFERS_HOTELDETAILS_ERROR_TEXT"] = "TRANSFERS_HOTELDETAILS_ERROR_TEXT";
    ResourceStringsKey["TRANSFERS_HOTELDETAILS_HEADING"] = "TRANSFERS_HOTELDETAILS_HEADING";
    ResourceStringsKey["TRANSFERS_HOTELDETAILS_INTRO"] = "TRANSFERS_HOTELDETAILS_INTRO";
    ResourceStringsKey["TRANSFERS_HOTELDETAILS_NAME_LABEL"] = "TRANSFERS_HOTELDETAILS_NAME_LABEL";
    ResourceStringsKey["TRANSFERS_HOTELDETAILS_ADDRESS_LABEL"] = "TRANSFERS_HOTELDETAILS_ADDRESS_LABEL";
    ResourceStringsKey["TRANSFERS_HOTELDETAILS_NAME_EMPTY"] = "TRANSFERS_HOTELDETAILS_NAME_EMPTY";
    ResourceStringsKey["TRANSFERS_HOTELDETAILS_ADDRESS_EMPTY"] = "TRANSFERS_HOTELDETAILS_ADDRESS_EMPTY";
    ResourceStringsKey["TRANSFERS_FLIGHTDETAILS_HEADING"] = "TRANSFERS_FLIGHTDETAILS_HEADING";
    ResourceStringsKey["TRANSFERS_FLIGHTDETAILS_INTRO"] = "TRANSFERS_FLIGHTDETAILS_INTRO";
    ResourceStringsKey["TRANSFERS_FLIGHTDETAILS_INBOUND_FLIGHT_PROMPT"] = "TRANSFERS_FLIGHTDETAILS_INBOUND_FLIGHT_PROMPT";
    ResourceStringsKey["TRANSFERS_FLIGHTDETAILS_OUTBOUND_FLIGHT_PROMPT"] = "TRANSFERS_FLIGHTDETAILS_OUTBOUND_FLIGHT_PROMPT";
    ResourceStringsKey["TRANSFERS_FLIGHTDETAILS_INBOUNDFLIGHT_HEADING"] = "TRANSFERS_FLIGHTDETAILS_INBOUNDFLIGHT_HEADING";
    ResourceStringsKey["TRANSFERS_FLIGHTDETAILS_OUTBOUNDFLIGHT_HEADING"] = "TRANSFERS_FLIGHTDETAILS_OUTBOUNDFLIGHT_HEADING";
    ResourceStringsKey["TRANSFERS_FLIGHTDETAILS_DEPARTUREAIRPORT_LABEL"] = "TRANSFERS_FLIGHTDETAILS_DEPARTUREAIRPORT_LABEL";
    ResourceStringsKey["TRANSFERS_FLIGHTDETAILS_DEPARTUREDATETIME_LABEL"] = "TRANSFERS_FLIGHTDETAILS_DEPARTUREDATETIME_LABEL";
    ResourceStringsKey["TRANSFERS_FLIGHTDETAILS_ARRIVALDATETIME_LABEL"] = "TRANSFERS_FLIGHTDETAILS_ARRIVALDATETIME_LABEL";
    ResourceStringsKey["TRANSFERS_FLIGHTDETAILS_FLIGHTNUMBER_LABEL"] = "TRANSFERS_FLIGHTDETAILS_FLIGHTNUMBER_LABEL";
    ResourceStringsKey["TRANSFERS_FLIGHTDETAILS_ARRIVALAIRPORT_LABEL"] = "TRANSFERS_FLIGHTDETAILS_ARRIVALAIRPORT_LABEL";
    ResourceStringsKey["TRANSFERS_FLIGHTDETAILS_ARRIVALDATE_LABEL"] = "TRANSFERS_FLIGHTDETAILS_ARRIVALDATE_LABEL";
    ResourceStringsKey["TRANSFERS_FLIGHTDETAILS_ARRIVALTIME_LABEL"] = "TRANSFERS_FLIGHTDETAILS_ARRIVALTIME_LABEL";
    ResourceStringsKey["TRANSFERS_FLIGHTDETAILS_FLIGHTNUMBER_INVALID"] = "TRANSFERS_FLIGHTDETAILS_FLIGHTNUMBER_INVALID";
    ResourceStringsKey["TRANSFERS_FLIGHTDETAILS_FLIGHTNUMBER_EMPTY"] = "TRANSFERS_FLIGHTDETAILS_FLIGHTNUMBER_EMPTY";
    ResourceStringsKey["TRANSFERS_FLIGHTDETAILS_DEPARTUREAIRPORT_EMPTY"] = "TRANSFERS_FLIGHTDETAILS_DEPARTUREAIRPORT_EMPTY";
    ResourceStringsKey["TRANSFERS_FLIGHTDETAILS_ARRIVALAIRPORT_EMPTY"] = "TRANSFERS_FLIGHTDETAILS_ARRIVALAIRPORT_EMPTY";
    ResourceStringsKey["TRANSFERS_FLIGHTDETAILS_DEPARTUREDATE_EMPTY"] = "TRANSFERS_FLIGHTDETAILS_DEPARTUREDATE_EMPTY";
    ResourceStringsKey["TRANSFERS_FLIGHTDETAILS_DEPARTUREDATE_INVALID"] = "TRANSFERS_FLIGHTDETAILS_DEPARTUREDATE_INVALID";
    ResourceStringsKey["TRANSFERS_FLIGHTDETAILS_ARRIVALDATE_EMPTY"] = "TRANSFERS_FLIGHTDETAILS_ARRIVALDATE_EMPTY";
    ResourceStringsKey["TRANSFERS_FLIGHTDETAILS_ARRIVALDATE_INVALID"] = "TRANSFERS_FLIGHTDETAILS_ARRIVALDATE_INVALID";
    ResourceStringsKey["SEARCH_RESORT_FIELD_PLACEHOLDER"] = "SEARCH_RESORT_FIELD_PLACEHOLDER";
    ResourceStringsKey["BOOKINGSUMMARY_PAIDTODAY"] = "BOOKINGSUMMARY_PAIDTODAY";
    ResourceStringsKey["BOOKINGSUMMARY_ATTRACTIONS_REFERENCE"] = "BOOKINGSUMMARY_ATTRACTIONS_REFERENCE";
    ResourceStringsKey["BOOKINGSUMMARY_CHECKIN_LABEL"] = "BOOKINGSUMMARY_CHECKIN_LABEL";
    ResourceStringsKey["BOOKINGSUMMARY_CHECKOUT_LABEL"] = "BOOKINGSUMMARY_CHECKOUT_LABEL";
    ResourceStringsKey["BOOKINGCOMPLETE_PRICE_SUMMARY"] = "BOOKINGCOMPLETE_PRICE_SUMMARY";
    ResourceStringsKey["BOOKINGCOMPLETE_HOTEL_FEE_LABEL"] = "BOOKINGCOMPLETE_HOTEL_FEE_LABEL";
    ResourceStringsKey["BOOKINGCOMPLETE_AMOUNT_DUE_HOTEL"] = "BOOKINGCOMPLETE_AMOUNT_DUE_HOTEL";
    ResourceStringsKey["NAVL_SEARCHAGAIN"] = "NAVL_SEARCHAGAIN";
    ResourceStringsKey["HC_FLIGHTS_SAMEAIRPORTMSG"] = "HC_FLIGHTS_SAMEAIRPORTMSG";
    ResourceStringsKey["TRIP_RESEND_DOCUMENTS_BUTTON_LABEL"] = "TRIP_RESEND_DOCUMENTS_BUTTON_LABEL";
    ResourceStringsKey["TRIP_CUSTOMER_SUPPORT_BUTTON_LABEL"] = "TRIP_CUSTOMER_SUPPORT_BUTTON_LABEL";
    ResourceStringsKey["TRIP_PAGE_TITLE"] = "TRIP_PAGE_TITLE";
    ResourceStringsKey["TRIPCOMPLETED"] = "TRIPCOMPLETED";
    ResourceStringsKey["TRIP_DAYSTOGO"] = "TRIP_DAYSTOGO";
    ResourceStringsKey["TRIP_BREADCRUMB_HOME"] = "TRIP_BREADCRUMB_HOME";
    ResourceStringsKey["TRIP_BREADCRUMB_TRIPS"] = "TRIP_BREADCRUMB_TRIPS";
    ResourceStringsKey["TRIP_HOTEL_OVERVIEW"] = "TRIP_HOTEL_OVERVIEW";
    ResourceStringsKey["TRIP_HOTEL_FACILITIES"] = "TRIP_HOTEL_FACILITIES";
    ResourceStringsKey["TRIPCANCELLED"] = "TRIPCANCELLED";
    ResourceStringsKey["TRIP_SIDEBAR_OVERVIEW"] = "TRIP_SIDEBAR_OVERVIEW";
    ResourceStringsKey["TRIP_SIDEBAR_COSTBREAKDOWN"] = "TRIP_SIDEBAR_COSTBREAKDOWN";
    ResourceStringsKey["TRIP_SIDEBAR_ADMINFEE"] = "TRIP_SIDEBAR_ADMINFEE";
    ResourceStringsKey["TRIP_SIDEBAR_TOTAL_COST_LABEL"] = "TRIP_SIDEBAR_TOTAL_COST_LABEL";
    ResourceStringsKey["TRIP_SIDEBAR_BALANCE_PAID_LABEL"] = "TRIP_SIDEBAR_BALANCE_PAID_LABEL";
    ResourceStringsKey["TRIP_SIDEBAR_BALANCE_REMAIN_LABEL"] = "TRIP_SIDEBAR_BALANCE_REMAIN_LABEL";
    ResourceStringsKey["TRIP_SIDEBAR_TRAVELLERS"] = "TRIP_SIDEBAR_TRAVELLERS";
    ResourceStringsKey["TRIP_SIDEBAR_LEAD_CONTACT"] = "TRIP_SIDEBAR_LEAD_CONTACT";
    ResourceStringsKey["TRIP_FLIGHT_OUTBOUND_LABEL"] = "TRIP_FLIGHT_OUTBOUND_LABEL";
    ResourceStringsKey["TRIP_FLIGHT_INBOUND_LABEL"] = "TRIP_FLIGHT_INBOUND_LABEL";
    ResourceStringsKey["TRIP_TRANSFER_OUTBOUND_LABEL"] = "TRIP_TRANSFER_OUTBOUND_LABEL";
    ResourceStringsKey["TRIP_TRANSFER_INBOUND_LABEL"] = "TRIP_TRANSFER_INBOUND_LABEL";
    ResourceStringsKey["TRIP_TRANSFER_RESORT_LABEL"] = "TRIP_TRANSFER_RESORT_LABEL";
    ResourceStringsKey["TRIP_TRANSFER_AIRPORT_LABEL"] = "TRIP_TRANSFER_AIRPORT_LABEL";
    ResourceStringsKey["TRIP_TRANSFER_PICKUP_TIME_TEXT"] = "TRIP_TRANSFER_PICKUP_TIME_TEXT";
    ResourceStringsKey["MYB_CANCEL_BUTTON_LABEL"] = "MYB_CANCEL_BUTTON_LABEL";
    ResourceStringsKey["SEARCH_DATES_FIELD_LABEL_FLIGHTS_RETURN"] = "SEARCH_DATES_FIELD_LABEL_FLIGHTS_RETURN";
    ResourceStringsKey["SEARCH_DATES_FIELD_LABEL_FLIGHTS_ONE_WAY"] = "SEARCH_DATES_FIELD_LABEL_FLIGHTS_ONE_WAY";
    ResourceStringsKey["SEARCH_FROMDATENAME_FLIGHTS"] = "SEARCH_FROMDATENAME_FLIGHTS";
    ResourceStringsKey["SEARCH_TODATENAME_FLIGHTS"] = "SEARCH_TODATENAME_FLIGHTS";
    ResourceStringsKey["BOOKING_TRIPDETAILS_CAPTION_VOUCHER"] = "BOOKING_TRIPDETAILS_CAPTION_VOUCHER";
    ResourceStringsKey["MASTHEAD_PHONENUMBER_LABEL"] = "MASTHEAD_PHONENUMBER_LABEL";
    ResourceStringsKey["HC_HOTEL_CHECKIN_VALIDATION_ERROR"] = "HC_HOTEL_CHECKIN_VALIDATION_ERROR";
    ResourceStringsKey["HC_HOLIDAY_DEPARTURE_VALIDATION_ERROR"] = "HC_HOLIDAY_DEPARTURE_VALIDATION_ERROR";
    ResourceStringsKey["NO_SEARCH_RESULTS_FLIGHTS"] = "NO_SEARCH_RESULTS_FLIGHTS";
    ResourceStringsKey["NO_SEARCH_RESULTS_FLIGHTS_SUB"] = "NO_SEARCH_RESULTS_FLIGHTS_SUB";
    ResourceStringsKey["DT_FOOTER_CONFIDENCE_MESSAGE"] = "DT_FOOTER_CONFIDENCE_MESSAGE";
    ResourceStringsKey["PRICEHIGHESTFIRST"] = "PRICEHIGHESTFIRST";
    ResourceStringsKey["DEPARTURETIMEEARLIESTFIRST"] = "DEPARTURETIMEEARLIESTFIRST";
    ResourceStringsKey["DEPARTURETIMENOBRACKETS"] = "DEPARTURETIMENOBRACKETS";
    ResourceStringsKey["DEPARTURETIMELATESTFIRST"] = "DEPARTURETIMELATESTFIRST";
    ResourceStringsKey["RETURNTIMEEARLIESTFIRST"] = "RETURNTIMEEARLIESTFIRST";
    ResourceStringsKey["RETURNTIMENOBRACKETS"] = "RETURNTIMENOBRACKETS";
    ResourceStringsKey["RETURNTIMELATESTFIRST"] = "RETURNTIMELATESTFIRST";
    ResourceStringsKey["DURATIONLONGESTFIRST"] = "DURATIONLONGESTFIRST";
    ResourceStringsKey["CUSTOMER_RATING_SUMMARY_PLURAL"] = "CUSTOMER_RATING_SUMMARY_PLURAL";
    ResourceStringsKey["CUSTOMER_RATING_SUMMARY_SINGULAR"] = "CUSTOMER_RATING_SUMMARY_SINGULAR";
    ResourceStringsKey["REVIEW_COUNT_PLURAL"] = "REVIEW_COUNT_PLURAL";
    ResourceStringsKey["REVIEW_COUNT_SINGULAR"] = "REVIEW_COUNT_SINGULAR";
    ResourceStringsKey["POPULAR_AIRPORTS"] = "POPULAR_AIRPORTS";
    ResourceStringsKey["POPULAR_RESORTS"] = "POPULAR_RESORTS";
    ResourceStringsKey["SEARCH_TABS_TRANSFER_LABEL"] = "SEARCH_TABS_TRANSFER_LABEL";
    ResourceStringsKey["HC_ENBD_DESCRIPTION"] = "HC_ENBD_DESCRIPTION";
    ResourceStringsKey["ENBD_ONE_TIME_PASSWORD"] = "ENBD_ONE_TIME_PASSWORD";
    ResourceStringsKey["ENBD_CHECK_MY_POINTS_BUTTON"] = "ENBD_CHECK_MY_POINTS_BUTTON";
    ResourceStringsKey["ENBD_RESEND_SMS_MESSAGE"] = "ENBD_RESEND_SMS_MESSAGE";
    ResourceStringsKey["HC_ENBD_OTP_CHECK_DESC"] = "HC_ENBD_OTP_CHECK_DESC";
    ResourceStringsKey["HC_ENBD_OTP_VALID_FORMAT_ERROR"] = "HC_ENBD_OTP_VALID_FORMAT_ERROR";
    ResourceStringsKey["HC_ENBD_POINTS"] = "HC_ENBD_POINTS";
    ResourceStringsKey["HC_ENBD_POINTS_VALUE"] = "HC_ENBD_POINTS_VALUE";
    ResourceStringsKey["HC_ENBD_POINTS_TO_USE"] = "HC_ENBD_POINTS_TO_USE";
    ResourceStringsKey["HC_ENBD_NO_POINTS_MESSAGE"] = "HC_ENBD_NO_POINTS_MESSAGE";
    ResourceStringsKey["HC_ENBD_PARTIAL_REDEMPTION_MESSAGE"] = "HC_ENBD_PARTIAL_REDEMPTION_MESSAGE";
    ResourceStringsKey["HC_ENBD_FULL_REDEMPTION_MESSAGE"] = "HC_ENBD_FULL_REDEMPTION_MESSAGE";
    ResourceStringsKey["HC_ENBD_OTP_VALIDATION_ERROR_MESSAGE"] = "HC_ENBD_OTP_VALIDATION_ERROR_MESSAGE";
    ResourceStringsKey["ENBD_VALIDATING_CARD_API_ERROR_MESSAGE"] = "ENBD_VALIDATING_CARD_API_ERROR_MESSAGE";
    ResourceStringsKey["ENBD_VERIFYING_OTP_API_ERROR_MESSAGE"] = "ENBD_VERIFYING_OTP_API_ERROR_MESSAGE";
    ResourceStringsKey["ENBD_RESEND_OTP_API_ERROR_MESSAGE"] = "ENBD_RESEND_OTP_API_ERROR_MESSAGE";
    ResourceStringsKey["HC_PRICE_RANGE_FILTER_SUBHEADER"] = "HC_PRICE_RANGE_FILTER_SUBHEADER";
    ResourceStringsKey["BOOKING_ADULTAGEPROMPT_XYEARS"] = "BOOKING_ADULTAGEPROMPT_XYEARS";
    ResourceStringsKey["HC_ENBD_POINT_FIELD_VALDATION_ERROR"] = "HC_ENBD_POINT_FIELD_VALDATION_ERROR";
    ResourceStringsKey["HC_ENBD_VERIFY_OTP"] = "HC_ENBD_VERIFY_OTP";
    ResourceStringsKey["CPG_3DSFAILEDERROR"] = "CPG_3DSFAILEDERROR";
    ResourceStringsKey["CPG_3DSRETRYLIMITERROR"] = "CPG_3DSRETRYLIMITERROR";
    ResourceStringsKey["FLIGHT_OPTION_RETURN"] = "FLIGHT_OPTION_RETURN";
    ResourceStringsKey["FLIGHT_OPTION_ONE_WAY"] = "FLIGHT_OPTION_ONE_WAY";
    ResourceStringsKey["HC_ONEWAY_FLIGHT_LABEL"] = "HC_ONEWAY_FLIGHT_LABEL";
    ResourceStringsKey["SEARCH_DATES_ERROR_EMPTY_ONE_WAY"] = "SEARCH_DATES_ERROR_EMPTY_ONE_WAY";
    ResourceStringsKey["SEARCH_DATES_ERROR_MISSINGDATES_ONE_WAY"] = "SEARCH_DATES_ERROR_MISSINGDATES_ONE_WAY";
    ResourceStringsKey["MYBCOMPLAINTFORMPOSTADDRERROR"] = "MYBCOMPLAINTFORMPOSTADDRERROR";
    ResourceStringsKey["MYBCOMPLAINTPOSTADDRLABEL"] = "MYBCOMPLAINTPOSTADDRLABEL";
    ResourceStringsKey["MYBCOMPLAINTSUCCESSRESPONSE"] = "MYBCOMPLAINTSUCCESSRESPONSE";
    ResourceStringsKey["MYBCOMPLAINTSUBMITBUTTONLABEL"] = "MYBCOMPLAINTSUBMITBUTTONLABEL";
    ResourceStringsKey["MYBCOMPLAINTSUCCESSLABEL"] = "MYBCOMPLAINTSUCCESSLABEL";
    ResourceStringsKey["MYBCOMPLAINTCONTINUELABEL"] = "MYBCOMPLAINTCONTINUELABEL";
    ResourceStringsKey["NEWSLETTER_SIGNUP_REASON"] = "NEWSLETTER_SIGNUP_REASON";
    ResourceStringsKey["ARRIVALTIMEEARLIESTFIRST"] = "ARRIVALTIMEEARLIESTFIRST";
    ResourceStringsKey["ARRIVALTIMENOBRACKETS"] = "ARRIVALTIMENOBRACKETS";
    ResourceStringsKey["ARRIVALTIMELATESTFIRST"] = "ARRIVALTIMELATESTFIRST";
    ResourceStringsKey["HC_ENBD_PAY_BUTTON_LABEL"] = "HC_ENBD_PAY_BUTTON_LABEL";
    ResourceStringsKey["WEACCEPT"] = "WEACCEPT";
    ResourceStringsKey["BOOKINGCOMPLETE_COSTBREAKDOWN"] = "BOOKINGCOMPLETE_COSTBREAKDOWN";
    ResourceStringsKey["BOOKINGCOMPLETE_PACKAGE_CAPTION"] = "BOOKINGCOMPLETE_PACKAGE_CAPTION";
    ResourceStringsKey["WHATSAPP_NUMBER"] = "WHATSAPP_NUMBER";
    ResourceStringsKey["MASTHEAD_WHATSAPP_LABEL"] = "MASTHEAD_WHATSAPP_LABEL";
    ResourceStringsKey["MASTHEAD_WHATSAPP_ARIALABEL"] = "MASTHEAD_WHATSAPP_ARIALABEL";
    ResourceStringsKey["SEARCH_PASSENGERS_FIELD_TITLE"] = "SEARCH_PASSENGERS_FIELD_TITLE";
    ResourceStringsKey["HC_WISHLIST_TITLE"] = "HC_WISHLIST_TITLE";
    ResourceStringsKey["HC_WISHLIST_DESCRIPTION"] = "HC_WISHLIST_DESCRIPTION";
    ResourceStringsKey["HC_EMPTY_WISHLIST_TITLE"] = "HC_EMPTY_WISHLIST_TITLE";
    ResourceStringsKey["HC_EMPTY_WISHLIST_DESCRIPTION"] = "HC_EMPTY_WISHLIST_DESCRIPTION";
    ResourceStringsKey["HC_EMPTY_WISHLIST_SEARCH_TITLE"] = "HC_EMPTY_WISHLIST_SEARCH_TITLE";
    ResourceStringsKey["HC_EMPTY_WISHLIST_SEARCH_DESC"] = "HC_EMPTY_WISHLIST_SEARCH_DESC";
    ResourceStringsKey["HC_EMPTY_WISHLIST_CARD_TITLE"] = "HC_EMPTY_WISHLIST_CARD_TITLE";
    ResourceStringsKey["HC_EMPTY_WISHLIST_CARD_DESC"] = "HC_EMPTY_WISHLIST_CARD_DESC";
    ResourceStringsKey["GROUPBOOKING_FLIGHTSHEADING"] = "GROUPBOOKING_FLIGHTSHEADING";
    ResourceStringsKey["MOREINFO"] = "MOREINFO";
    ResourceStringsKey["FLIGHTCOSTBREAKDOWN"] = "FLIGHTCOSTBREAKDOWN";
    ResourceStringsKey["FLIGHTTOTALCOSTS"] = "FLIGHTTOTALCOSTS";
    ResourceStringsKey["HC_MARKETING_OVERLAY_TEXT_FLIGHTS"] = "HC_MARKETING_OVERLAY_TEXT_FLIGHTS";
    ResourceStringsKey["HC_WISHLIST_ERROR_POPUP"] = "HC_WISHLIST_ERROR_POPUP";
    ResourceStringsKey["HC_WISHLIST_ADDED_TO_LIST_POPUP"] = "HC_WISHLIST_ADDED_TO_LIST_POPUP";
    ResourceStringsKey["HC_WISHLIST_VIEW"] = "HC_WISHLIST_VIEW";
    ResourceStringsKey["HC_WISHLIST_UNDO"] = "HC_WISHLIST_UNDO";
    ResourceStringsKey["HC_WISHLIST_REMOVED_FROM_LIST_POPUP"] = "HC_WISHLIST_REMOVED_FROM_LIST_POPUP";
    ResourceStringsKey["HC_WISHLIST_LABEL"] = "HC_WISHLIST_LABEL";
    ResourceStringsKey["HC_WISHLIST_CARD_CTA_LABEL"] = "HC_WISHLIST_CARD_CTA_LABEL";
    ResourceStringsKey["HC_WISHLIST_CLEAR_BUTTON_LABEL"] = "HC_WISHLIST_CLEAR_BUTTON_LABEL";
    ResourceStringsKey["APISUNSUBSCRIBEFAILHEADING"] = "APISUNSUBSCRIBEFAILHEADING";
    ResourceStringsKey["MYB_BOOKING_REF_LABEL"] = "MYB_BOOKING_REF_LABEL";
    ResourceStringsKey["BOOKING_DOBFIELD_ERROR_CHILD_ONEWAY"] = "BOOKING_DOBFIELD_ERROR_CHILD_ONEWAY";
    ResourceStringsKey["FARERULESMODALHEADING"] = "FARERULESMODALHEADING";
    ResourceStringsKey["ROOMSHOWMORE"] = "ROOMSHOWMORE";
    ResourceStringsKey["HOTEL_ROOM_HEADING"] = "HOTEL_ROOM_HEADING";
    ResourceStringsKey["HOTEL_ROOM_SUBHEADING"] = "HOTEL_ROOM_SUBHEADING";
    ResourceStringsKey["HOTEL_ROOM_TOTAL"] = "HOTEL_ROOM_TOTAL";
    ResourceStringsKey["HOTEL_ROOM_PER_PERSON"] = "HOTEL_ROOM_PER_PERSON";
    ResourceStringsKey["HOTEL_ROOM"] = "HOTEL_ROOM";
    ResourceStringsKey["HOTEL_ROOM_FROM"] = "HOTEL_ROOM_FROM";
    ResourceStringsKey["HOTEL_ROOM_WHATS_INCLUDED"] = "HOTEL_ROOM_WHATS_INCLUDED";
    ResourceStringsKey["HOTEL_ROOM_MORE_DETAILS"] = "HOTEL_ROOM_MORE_DETAILS";
    ResourceStringsKey["HC_SEAT_SELECTION_MANDATORY"] = "HC_SEAT_SELECTION_MANDATORY";
    ResourceStringsKey["HC_SEAT_NO_AVAILABILITY"] = "HC_SEAT_NO_AVAILABILITY";
    ResourceStringsKey["FROM_LABEL"] = "FROM_LABEL";
    ResourceStringsKey["SEARCH_OFFER_LABEL"] = "SEARCH_OFFER_LABEL";
    ResourceStringsKey["ESTAB_OFFER_LABEL"] = "ESTAB_OFFER_LABEL";
    ResourceStringsKey["CHANGE_FLIGHT_TITLE"] = "CHANGE_FLIGHT_TITLE";
    ResourceStringsKey["CHANGE_FLIGHT_SUB_TITLE"] = "CHANGE_FLIGHT_SUB_TITLE";
    ResourceStringsKey["XMATCHES"] = "XMATCHES";
    ResourceStringsKey["HC_CHANGE_FLIGHT"] = "HC_CHANGE_FLIGHT";
    ResourceStringsKey["HOTEL_BASKET_NOT_AVAILABLE_NOTIFICATION_HEADING"] = "HOTEL_BASKET_NOT_AVAILABLE_NOTIFICATION_HEADING";
    ResourceStringsKey["HOTEL_BASKET_NOT_AVAILABLE_NOTIFICATION_SUBHEADING"] = "HOTEL_BASKET_NOT_AVAILABLE_NOTIFICATION_SUBHEADING";
    ResourceStringsKey["HOTEL_BASKET_NOT_AVAILABLE_VIEW_DATES"] = "HOTEL_BASKET_NOT_AVAILABLE_VIEW_DATES";
    ResourceStringsKey["HOTEL_BASKET_NOT_AVAILABLE_VIEW_HOTELS"] = "HOTEL_BASKET_NOT_AVAILABLE_VIEW_HOTELS";
    ResourceStringsKey["HOLIDAY_FLIGHTS_HEADING"] = "HOLIDAY_FLIGHTS_HEADING";
    ResourceStringsKey["HOLIDAY_FLIGHTS_SUBHEADING"] = "HOLIDAY_FLIGHTS_SUBHEADING";
    ResourceStringsKey["HOLIDAY_FLIGHTS_UNAVAILABILITY_HEADING"] = "HOLIDAY_FLIGHTS_UNAVAILABILITY_HEADING";
    ResourceStringsKey["HOLIDAY_FLIGHTS_UNAVAILABILITY_SUB_HEADING"] = "HOLIDAY_FLIGHTS_UNAVAILABILITY_SUB_HEADING";
    ResourceStringsKey["HOTEL_ROOM_NOT_AVAILABLE_NOTIFICATION_HEADING"] = "HOTEL_ROOM_NOT_AVAILABLE_NOTIFICATION_HEADING";
    ResourceStringsKey["HOTEL_ROOM_NOT_AVAILABLE_NOTIFICATION_SUB_HEADING"] = "HOTEL_ROOM_NOT_AVAILABLE_NOTIFICATION_SUB_HEADING";
    ResourceStringsKey["HOTELMAP"] = "HOTELMAP";
    ResourceStringsKey["VIEWDEAL"] = "VIEWDEAL";
    ResourceStringsKey["TOTALHOLIDAYPRICE"] = "TOTALHOLIDAYPRICE";
    ResourceStringsKey["HC_HOLIDAY_CONTINUE_INTERSTITIAL_DYNAMIC_MESSAGES"] = "HC_HOLIDAY_CONTINUE_INTERSTITIAL_DYNAMIC_MESSAGES";
    ResourceStringsKey["HC_HOTEL_CONTINUE_INTERSTITIAL_DYNAMIC_MESSAGES"] = "HC_HOTEL_CONTINUE_INTERSTITIAL_DYNAMIC_MESSAGES";
    ResourceStringsKey["HC_CONTINUE_INTERSTITIAL_STATIC_MESSAGE"] = "HC_CONTINUE_INTERSTITIAL_STATIC_MESSAGE";
    ResourceStringsKey["HOTEL_ROOM_ERROR_HEADING"] = "HOTEL_ROOM_ERROR_HEADING";
    ResourceStringsKey["HOTEL_ROOM_ERROR_SUB_HEADING"] = "HOTEL_ROOM_ERROR_SUB_HEADING";
    ResourceStringsKey["HOTEL_BASKET_ERROR_HEADING"] = "HOTEL_BASKET_ERROR_HEADING";
    ResourceStringsKey["HOTEL_BASKET_ERROR_SUB_HEADING"] = "HOTEL_BASKET_ERROR_SUB_HEADING";
    ResourceStringsKey["TRIP_PAYMENT_BREAKDOWN_LABEL"] = "TRIP_PAYMENT_BREAKDOWN_LABEL";
    ResourceStringsKey["TRIP_PAY_REMAINING_BALANCE_LABEL"] = "TRIP_PAY_REMAINING_BALANCE_LABEL";
    ResourceStringsKey["TRIP_PAYMENT_PAID"] = "TRIP_PAYMENT_PAID";
    ResourceStringsKey["TRIP_PAYMENT_SCHEDULED"] = "TRIP_PAYMENT_SCHEDULED";
    ResourceStringsKey["TRIP_REMAINING_BALANCE_NOTIFICATION_TEXT"] = "TRIP_REMAINING_BALANCE_NOTIFICATION_TEXT";
    ResourceStringsKey["WEATHER_ROUTE_LABEL"] = "WEATHER_ROUTE_LABEL";
    ResourceStringsKey["NEXT_STEP"] = "NEXT_STEP";
    ResourceStringsKey["HOTEL_BASKET_FLIGHTS_UNAVAILABLE_MEESAGE"] = "HOTEL_BASKET_FLIGHTS_UNAVAILABLE_MEESAGE";
    ResourceStringsKey["HOTEL_OVERVIEW_HEADING"] = "HOTEL_OVERVIEW_HEADING";
    ResourceStringsKey["HOTEL_FACILITIES_HEADING"] = "HOTEL_FACILITIES_HEADING";
    ResourceStringsKey["SHOW_ALL"] = "SHOW_ALL";
    ResourceStringsKey["SHOW_FEWER"] = "SHOW_FEWER";
    ResourceStringsKey["CHANGE_FLIGHT_ERROR_HEADING"] = "CHANGE_FLIGHT_ERROR_HEADING";
    ResourceStringsKey["CHANGE_FLIGHT_ERROR_BODY"] = "CHANGE_FLIGHT_ERROR_BODY";
    ResourceStringsKey["UNIVERSALTOLABEL"] = "UNIVERSALTOLABEL";
    ResourceStringsKey["FLIGHTDETAILS_BUTTON_LABEL"] = "FLIGHTDETAILS_BUTTON_LABEL";
    ResourceStringsKey["HOTEL_FOOTER_SUMMARYBUTTON_LABEL"] = "HOTEL_FOOTER_SUMMARYBUTTON_LABEL";
    ResourceStringsKey["HOTEL_BASKET_TOTALCAPTION"] = "HOTEL_BASKET_TOTALCAPTION";
    ResourceStringsKey["HOTEL_BASKET_TOTALCAPTION_HOLIDAY"] = "HOTEL_BASKET_TOTALCAPTION_HOLIDAY";
    ResourceStringsKey["HOTEL_BASKET_DRAWERHEADING"] = "HOTEL_BASKET_DRAWERHEADING";
    ResourceStringsKey["HOTEL_BASKET_DRAWERHEADING_SINGULAR"] = "HOTEL_BASKET_DRAWERHEADING_SINGULAR";
    ResourceStringsKey["HC_FLIGHT_FETCH_ERROR_HEADING"] = "HC_FLIGHT_FETCH_ERROR_HEADING";
    ResourceStringsKey["HC_FLIGHT_FETCH_ERROR_BODY"] = "HC_FLIGHT_FETCH_ERROR_BODY";
    ResourceStringsKey["HOTEL_BASKET_DRAWERHEADING_HOLIDAY"] = "HOTEL_BASKET_DRAWERHEADING_HOLIDAY";
    ResourceStringsKey["HOTEL_BASKET_PRICEMESSAGE"] = "HOTEL_BASKET_PRICEMESSAGE";
    ResourceStringsKey["HC_CALENDAR_NOT_AVAILABLE_HEADING"] = "HC_CALENDAR_NOT_AVAILABLE_HEADING";
    ResourceStringsKey["HC_CALENDAR_NOT_AVAILABLE_SUBHEADING"] = "HC_CALENDAR_NOT_AVAILABLE_SUBHEADING";
    ResourceStringsKey["FUNNEL_BREADCRUMB_FLIGHTS_EXTRAS"] = "FUNNEL_BREADCRUMB_FLIGHTS_EXTRAS";
    ResourceStringsKey["EDIT_LINK_LABEL"] = "EDIT_LINK_LABEL";
    ResourceStringsKey["HOTEL_PAGE_REVIEWS_TITLE"] = "HOTEL_PAGE_REVIEWS_TITLE";
    ResourceStringsKey["HOTEL_DETAILS_SCROLLSPY_SUMMARY"] = "HOTEL_DETAILS_SCROLLSPY_SUMMARY";
    ResourceStringsKey["HOTEL_DETAILS_SCROLLSPY_PACKAGE"] = "HOTEL_DETAILS_SCROLLSPY_PACKAGE";
    ResourceStringsKey["HOTEL_DETAILS_SCROLLSPY_FLIGHTS"] = "HOTEL_DETAILS_SCROLLSPY_FLIGHTS";
    ResourceStringsKey["HOTEL_DETAILS_SCROLLSPY_ROOMS"] = "HOTEL_DETAILS_SCROLLSPY_ROOMS";
    ResourceStringsKey["HOTEL_DETAILS_SCROLLSPY_OVERVIEW"] = "HOTEL_DETAILS_SCROLLSPY_OVERVIEW";
    ResourceStringsKey["HOTEL_DETAILS_SCROLLSPY_REVIEWS"] = "HOTEL_DETAILS_SCROLLSPY_REVIEWS";
    ResourceStringsKey["HOTEL_DETAILS_SCROLLSPY_MAP"] = "HOTEL_DETAILS_SCROLLSPY_MAP";
    ResourceStringsKey["HC_NO_EXTRAS_FLIGHTS"] = "HC_NO_EXTRAS_FLIGHTS";
    ResourceStringsKey["PRICEMODETOGGLE_TOTAL"] = "PRICEMODETOGGLE_TOTAL";
    ResourceStringsKey["PRICEMODETOGGLE_PERPERSON"] = "PRICEMODETOGGLE_PERPERSON";
    ResourceStringsKey["FLIGHT_CHILD_SEAT_MESSAGING"] = "FLIGHT_CHILD_SEAT_MESSAGING";
    ResourceStringsKey["FLIGHT_CHILD_SEAT_MESSAGING_MIX_AND_MATCH"] = "FLIGHT_CHILD_SEAT_MESSAGING_MIX_AND_MATCH";
    ResourceStringsKey["FLYING_FROM"] = "FLYING_FROM";
    ResourceStringsKey["PAYMENTMETHOD_PAYWITHBANK"] = "PAYMENTMETHOD_PAYWITHBANK";
    ResourceStringsKey["OPENBANKING_BOOKING_PENDING_TEXT"] = "OPENBANKING_BOOKING_PENDING_TEXT";
    ResourceStringsKey["OPENBANKING_PAYMENT_PENDING_TEXT"] = "OPENBANKING_PAYMENT_PENDING_TEXT";
    ResourceStringsKey["OPENBANKING_BUTTON_LABEL"] = "OPENBANKING_BUTTON_LABEL";
    ResourceStringsKey["CSR_SUPPRESSED_HEADING"] = "CSR_SUPPRESSED_HEADING";
    ResourceStringsKey["CSR_SUPPRESSED_TEXT"] = "CSR_SUPPRESSED_TEXT";
    ResourceStringsKey["MYB_CANCEL_BOOKING_ADVISORY_TEXT"] = "MYB_CANCEL_BOOKING_ADVISORY_TEXT";
    ResourceStringsKey["MYB_CANCEL_BOOKING_FOOTER_TEXT1"] = "MYB_CANCEL_BOOKING_FOOTER_TEXT1";
    ResourceStringsKey["MYB_CANCEL_BOOKING_FOOTER_TEXT2"] = "MYB_CANCEL_BOOKING_FOOTER_TEXT2";
    ResourceStringsKey["MYB_CANCEL_BOOKING_TEXT_LINK_LABEL"] = "MYB_CANCEL_BOOKING_TEXT_LINK_LABEL";
    ResourceStringsKey["MYB_CANCEL_BOOKING_FOOTER_TEXT3"] = "MYB_CANCEL_BOOKING_FOOTER_TEXT3";
    ResourceStringsKey["MYB_CANCEL_BOOKING_HEADING"] = "MYB_CANCEL_BOOKING_HEADING";
    ResourceStringsKey["HC_CUSTOMER_CONFIRMATION"] = "HC_CUSTOMER_CONFIRMATION";
    ResourceStringsKey["HC_CUSTOMER_CONFIRMATION_BTN_LABEL"] = "HC_CUSTOMER_CONFIRMATION_BTN_LABEL";
    ResourceStringsKey["HC_CUSTOMER_CONFIRMATION_AIRLINE"] = "HC_CUSTOMER_CONFIRMATION_AIRLINE";
    ResourceStringsKey["INVALID_REQUEST_HEADING"] = "INVALID_REQUEST_HEADING";
    ResourceStringsKey["INVALID_REQUEST_SUBHEADING"] = "INVALID_REQUEST_SUBHEADING";
    ResourceStringsKey["AWAITING_IMAGE_CAPTION"] = "AWAITING_IMAGE_CAPTION";
})(ResourceStringsKey || (ResourceStringsKey = {}));
export const resourceStringsRegistry = new Set(Object.values(ResourceStringsKey));
